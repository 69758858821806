// local url
// export const url = "http://192.168.0.38:4065";
// export const url = "http://192.168.0.8:4065";
// export const url = "http://localhost:4065";
// export const url = "http://192.168.0.17:4065";
// export const url = "http://localhost:4065";
// export const url = "http://192.168.0.15:4065"; 

// live url
export const url = "/api";
