import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

///////////async functions starts here
import {
  addUserWallet,
  addAmountToWalletCallback,
  getUserWallet,
} from "../services/usersWallet.service";

import { toastError, toastSuccess } from "../utils/toastUtils";

// import
export const fetchUserWallet = createAsyncThunk(
  "user/getUserWallet",
  async (id) => {
    // console.log(id, "of324234");
    if (id == null) {
      return  null;
    } else {
      const { data: response } = await getUserWallet(id);
      // const { data: response } = await getAddress(query);
      // console.log(response, "getuser_redux");
      return response.data;
    }
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const userWalletSlice = createSlice({
  name: "UserWallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserWallet.fulfilled, (state, action) => {
      state.itemsObj = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default userWalletSlice.reducer;
