import axios from "axios";
import jwtDecode from "jwt-decode";
import { getToken, removeLoggedInUser, removeToken } from "./users.service";

export const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.request.use(
  async (config) => {
    // console.log(token)

    let token = await getToken();

    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    // console.log(error);
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  async (err) => {
    // console.log(err,"INterceptor error");
    return Promise.reject(err)
    // let { config, response } = err;
    // if (response && response.status == 401) {
    //     removeToken();
    //     removeLoggedInUser();
    //     window.location.href = "/";
    //     return Promise.reject(err);

    // }
  }
);
