import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGallery } from "../services/Gallery1.service";

///////////async functions starts here
export const fetchGallery = createAsyncThunk(
  "gallery/getGallary",
  async (query) => {
    const { data: response } = await getGallery(query);
    // console.log(response, "getProducts-redux")
    return response.data;
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const gallerySlice = createSlice({
  name: "Gallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGallery.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default gallerySlice.reducer;
