import axios from "axios";
// import { axiosApiInstance } from "./axios.service";
import { axiosApiInstance } from "./axios.service";

import { url } from "./url.service";

const serverUrl = url + "/userWallet";

export const addUserWallet = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};
export const addAmountToWalletCallback = (formData, id) => {
    return axiosApiInstance.post(`${serverUrl}/addAmountToWalletCallback/${id}?${formData}`);
};

export const getUserWallet = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getUserWallet/?${query}`);
};

// export const deleteUserWallet = (id) => {
//     return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
//    };

// export const updateUserWallet = (formData, id) => {
//     return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
// };

