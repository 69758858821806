import Accordion from "react-bootstrap/Accordion";
import { getFaqCategory } from "../services/FaqCategory.service";
import { getFaq } from "../services/Faq.service";

import React, { useState, useEffect } from "react";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastError } from "../utils/toastUtils";
import { fetchFaq } from "../redux/faq.slice";
import { fetchFaqCategory } from "../redux/faqCategory.slice";

const Faqs = () => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [faqArr, setFaqArr] = useState([]);
  const [faqArrAll, setFaqArrAll] = useState([]);

  const dispatch = useDispatch();

  const faqCategoryData = useSelector((state) => state.faqCategory.items);
  const faqData = useSelector((state) => state.faq.items);

  const handleInitCategory = async () => {
    // let { data: res } = await getFaqCategory();
    // if (res.data) {
    //   let index = 1;
    //   for (let el of res.data) {
    //     if (index == 1) {
    //       el.active = true;
    //     } else {
    //       el.active = false;
    //     }
    //     el.tab = index;
    //     index = index + 1;
    //   }
    //   console.log(res, "faqcategroy-=-");
    //   setFaqCategory(res?.data);
    // }
  };

  const handleInit = async () => {
    dispatch(fetchFaq());
    dispatch(fetchFaqCategory());
    // let { data: res } = await getFaq();

    // if (res.data) {
    //   let index = 1;
    //   for (let el of res.data) {
    //     if (index == 1) {
    //       el.active = true;
    //     } else {
    //       el.active = false;
    //     }
    //     el.tab = index;
    //     index = index + 1;
    //   }
    //   console.log(res, "faqcategroy=---");
    //   setFaqArr(res?.data);
    // }
  };

  useEffect(() => {
    if (faqCategoryData) {
      let arr = JSON.parse(JSON.stringify(faqCategoryData));
      const faqArr = [];
      let index = 1;
      let obj = { name: "All" };
      for (let el of arr) {
        if (index == 1) {
          el.active = true;
        } else {
          el.active = false;
        }
        el.tab = index;
        index = index + 1;
      }
      setFaqCategory([obj, ...arr]);
    }
  }, [faqCategoryData]);


  useEffect(() => {
       setFaqArr(faqArrAll);
  }, [faqArrAll]);

  
  useEffect(() => {
    if (faqData) {
      let arr = JSON.parse(JSON.stringify(faqData));
      setFaqArrAll(arr);
      
    }
  }, [faqData]);


  useEffect(() => {
    handleInit();
  }, []);


  const tabClick = (i, name) => {
    try{

      if (name == "All") {
        setFaqArr(faqArrAll);
      } else {
        const temp = faqArrAll.filter((el) => el?.categoryId == i);
        setFaqArr(temp);
      }
    }catch(err){
      toastError(err)
    }
  };

  return (
    <>
      <section>
        <div className="term-policy py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <h3>FAQs</h3>

                <div className="li_style">
                  <ul>
                    {faqCategory?.length > 0 &&
                      faqCategory?.map((x, index) => {
                        return (
                          <button key={index}>
                            <li
                              className="l-style"
                              onClick={(e) => {
                                tabClick(x?._id, x?.name);
                              }}
                            >
                              {x?.name}
                            </li>
                          </button>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="row justify-content-center py-4">
              <div className="col-lg-9">
                {faqArr?.length > 0 &&
                  faqArr?.map((el, index) => {
                    return (
                      <div key={index} className="accord-style">
                        <Accordion>
                          <Accordion.Item eventKey={`${index}`}>
                            <Accordion.Header> {el?.heading} </Accordion.Header>
                            <Accordion.Body>
                              <p> {el?.description} </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    );
                  })}
                {/* <div className='accord-style'>
        <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</Accordion.Header>
        <Accordion.Body>
       <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam feugiat vehicula feugiat. Aenean eu dui nisi. Nunc dui urna, efficitur in commodo sed, congue vitae enim. Nullam eleifend enim eu lectus consequat, in vulputate dolor malesuada. Nulla imperdiet finibus ante id rhoncus.</p> 
        </Accordion.Body>
      </Accordion.Item>

      
      <Accordion.Item eventKey="1">
        <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit?</Accordion.Header>
        <Accordion.Body>
       <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam feugiat vehicula feugiat. Aenean eu dui nisi. Nunc dui urna, efficitur in commodo sed, congue vitae enim. Nullam eleifend enim eu lectus consequat, in vulputate dolor malesuada. Nulla imperdiet finibus ante id rhoncus.</p>

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
