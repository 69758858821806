import { GoLocation } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";

import React, { useState, useEffect } from "react";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Link, useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../utils/toastUtils";
import {
  addContact,
  getContact,
  deleteContact,
  updateContact,
} from "../services/contactUs.service";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const clearFunc = () => {
    setName("")
    setEmail("")
    setSubject("")
    setMessage("")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log("first", message, "1wwww");

    if (`${name}` == "") {
      toastError("Name is mandatory");
      return;
    } else if (email == "") {
      toastError("Email is mandatory");
      return;
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      toastError("Not Valid email");
      return;
    } else if (subject == "") {
      toastError("Subject is mandatory");
      return;
    } else if (message == "") {
      toastError("Message is mandatory");
      return;
    }

    // console.log("12321312");

    let obj = {
      name,
      email,
      subject,
      message,
    };
    let { data: res } = await addContact(obj);
    if (res.success) {
      toastSuccess(res.message);
      clearFunc()
    }
  };


  useEffect(()=>{
    window.scrollTo(0,0)
  },[  ])
  return (
    <main className="border-top">

      <section className="getin_touch ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ">
              <div className="contact-style">
                <span>Contact with us</span>
                <h5>Get In Touch</h5>
                <p>
                  In hac habitasse platea dictumst. Pellentesque viverra sem
                  nec orci lacinia, in bibendum urna mollis. Quisque nunc
                  lacus, varius vel leo a, pretium lobortis metus. Vivamus
                  consectetur consequat justo.
                </p>

                <form>
                  <div className="row">
                    <div className="col">
                      <label for="inputAddress">
                        Your Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col">
                      <label for="inputAddress">
                        Your Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="inputAddress">
                      Your Subject<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlTextarea1">
                      Your Message
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <center>
                    {/* 
                      <button
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Send Me222222ssage
                      </button> */}

                    <button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Send Message
                    </button>
                  </center>
                </form>
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="address-style">
                <div className="address">
                  <div className="addresinofo d-flex">
                    <div className="iconaddres">
                      <GoLocation />
                    </div>
                    <div className="addmain">
                      <h6>Address</h6>
                      <p>
                        236 5th SE Avenue, New York NY10000, United States
                      </p>
                    </div>
                  </div>
                </div>

                <div className="email">
                  <div className="emailinofo d-flex">
                    <div className="iconaddres">
                      <MdEmail />
                    </div>
                    <div className="emailmain">
                      <h6>Email</h6>
                      <p>info@samagri.com</p>
                    </div>
                  </div>
                </div>

                <div className="email">
                  <div className="emailinofo d-flex">
                    <div className="iconaddres">
                      <BsFillTelephoneFill />
                    </div>
                    <div className="emailmain">
                      <h6>Phone</h6>
                      <p>Mobile: +(84) 546-6789</p>
                      <p>Hotline: +(84) 456-6789</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </main>
  );
};
export default Contact;
