import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { registerUser } from "../services/users.service";
import { img } from "../Components/Image";

const Register = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const clearFunc = () => {
    setUserName("");
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setConfirmPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (firstName == "") {
        toastError("First Name is mandatory");
        return;
      }

      if (lastName == "") {
        toastError("Last Name is mandatory");
        return;
      }
      if (phone == "") {
        toastError("Mobile number is mandatory");
        return;
      } else if (email == "") {
        toastError("Email is mandatory");
        return;
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        toastError("Email is not valid ");
        return;
      } else if (password == "") {
        toastError("Password is mandatory");
        return;
      } else if (confirmPassword == "") {
        toastError("Confirm  Password is mandatory");
        return;
      } else if (password != confirmPassword) {
        toastError("Password and confirm password are not same ");
        return;
      } else if (password.trim().length < 4) {
        toastError("Password length is minimum four");
        return;
      }

      if (!/^\(?([1-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)) {
        toastError("Not valid Phone Number");
        return;
      }

      // console.log("23123");
      let obj = {
        userName,
        firstName,
        lastName,
        email,
        phone,
        password,
        confirmPassword,
      };
      // console.log(obj, "object124");
      let { data: res } = await registerUser(obj);
      // console.log(res, "data123");
      if (res.success) {
        toastSuccess(res.message);
        clearFunc();
        navigate("/Login");
      }
    } catch (err) {
      // console.log(err, "errrrrr");
      toastError(err);
    }
    // password is not a function
  };

  return (
    <main
      style={{ backgroundImage: `url(${img.astrology_bg})` }}
      className="main-bg border-top"
    >
      <section className="register_page ">
        <div className="loing_fullimages bgyellowlight">
          <div className="left_lgoing">
            <img src={img?.loginimg} alt="" className="img-fluid" />
            <div className="overlay2"></div>
          </div>
          <div className="right_lgoing">
            <div className="shad">
              <h3>Create an Account</h3>
              <form>
                <label className="form-label" for="form1Example1">
                  First Name*
                </label>
                <div className="form-outline mb-4">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      const input = e.target.value;
                      // console.log(input, "onpitu23423");
                      if (/[0-9]/.test(input)) {
                        toastError("First name must not include numbers.");
                        return;
                      } else {
                        setFirstName(input);
                      }
                    }}
                    value={firstName}
                  />
                </div>

                <label className="form-label" for="form1Example1">
                  Last Name*
                </label>
                <div className="form-outline mb-4">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/[0-9]/.test(input)) {
                        toastError("Last name must not include numbers.");
                        return;
                      } else {
                        setLastName(input);
                      }
                    }}
                    value={lastName}
                  />
                </div>

                <label className="form-label" for="form1Example1">
                  Mobile Number*
                </label>
                <div className="form-outline mb-4">
                  <input
                    maxLength={10}
                    type="tel"
                    className="form-control"
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                      } else {
                        setPhone(e.target.value);
                      }
                    }}
                    value={phone}
                  />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" for="form1Example2">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" for="form1Example2">
                    Password*
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" for="form1Example2">
                    Confirm Password*
                  </label>
                  <input
                    type="password"
                    id="form1Example2"
                    className="form-control"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                </div>

                <center>
                  <button onClick={(e) => handleSubmit(e)}>Register</button>

                  <div className="form-outline mb-4">
                    <p>
                      Already have an Account?
                      <Link to="/Login"> Login</Link>
                    </p>
                  </div>
                </center>
              </form>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="shad">
                <h3>Create an Account</h3>
                <form>
                  <label className="form-label" for="form1Example1">
                    Username*
                  </label>
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName}
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form1Example2">
                      Email*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form1Example2">
                      Password*
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form1Example2">
                      Confirm Password*
                    </label>
                    <input
                      type="password"
                      id="form1Example2"
                      className="form-control"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                    />
                  </div>

                  <center>
                    <button onClick={(e) => handleSubmit(e)}>Register</button>

                    <div className="form-outline mb-4">
                      <p>
                        Already have an account?
                        <Link to="/Login"> login</Link>
                      </p>
                    </div>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </main>
  );
};
export default Register;
