import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../services/users.service";
import { getAddress } from "../services/UserAddress.service";
///////////async functions starts here

import { getUser, updateUser,changePassword } from "../services/users.service";
import { toastError, toastSuccess } from "../utils/toastUtils";

export const fetchUserData = createAsyncThunk("user/getUser", async (query) => {
  // get("status=true");
  const { data: response } = await getUser(query);
  // const { data: response } = await getAddress(query);
  // console.log(response, "getuser_redux");
  return response.data;
});


export const updateUserBasicInfoData = createAsyncThunk(
  "user/updateUser",
  async (obj) => {
    // console.log(obj, "obbject");
    try {
      const { data: response } = await updateUser(obj, obj?.userId);
      // const response = await updateUser(obj, obj.userId);
      // console.log(response, "getuser_redux");
      if (response?.success) {
        toastSuccess(response?.message);
      }
      return response?.data;
    } catch (err) {
      // console.log(err, "error213234");
      toastError(err);
      throw err;
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "user/updateUserPassword",
  async (obj) => {
    // console.log(obj, "obbject");
    try {
      const { data: response } = await changePassword(obj, obj?.userId);
      // const response = await updateUser(obj, obj.userId);
      // console.log(response, "getuser_redux");
      if (response?.success) {
        toastSuccess(response?.message);
      }
      return response?.data;
    } catch (err) {
      // console.log(err, "error213234");
      toastError(err);
      throw err;
    }
  }
);
// 
// getAddress


///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},

};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.itemsObj = action.payload;
    });
  
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default userSlice.reducer;
