// addLocalCart

import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";
import { getToken } from "./users.service";

const serverUrl = url + "/userCart";

export const getCart = async () => {
  return axiosApiInstance.get(`${serverUrl}/`);
};
export const removeProductFromCart = async (id) => {
  return axiosApiInstance.patch(`${serverUrl}/removeProduct/${id}`);
};
export const addProductToCart = async (id, obj) => {
  return axiosApiInstance.patch(`${serverUrl}/increaseQuantity/${id}`, obj);
};

export const addLocalCart = async (obj) => {
  return axiosApiInstance.post(`${serverUrl}/addLocalCart/`, obj);
};

export const addProductToCarts = async (id ,formdata) => {
    // console.log(id,"idiiddidid=cartservices",formdata,"=====formdata")
  return axiosApiInstance.post(`${serverUrl}/addToCart/${id}`,formdata);
};
