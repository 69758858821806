import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCompanyAddress } from '../services/CompanyAddress.service'



///////////async functions starts here

export const fetchcompanyAddress = createAsyncThunk(
    'companyAddress/getCompnayAddress',
    async (query) => {
        const { data: response } = await getCompanyAddress(query)

        // console.log(response, "response-categtoy-redux")
        return response.data
    }
)



///////////async functions ends here

const initialState = {
    items: [],
}

export const companyAddressSlice = createSlice({
    name: 'CompanyAddress',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchcompanyAddress.fulfilled, (state, action) => {
            state.items = action.payload
        })
    }
})

// Action creators are generated for each case reducer function
export const { } = companyAddressSlice.actions

export default companyAddressSlice.reducer