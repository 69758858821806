import React, { useLayoutEffect, useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import _ from "lodash";
import { toastError } from "../utils/toastUtils";
import Offcanvas from "react-bootstrap/Offcanvas";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { fetchProduct } from "../redux/Product.slice";
import { getAttributeData } from "../redux/Attribute.slice";
import { productCategoryObjectContext } from "../App";
import { currencyContext } from "../App";

function ShopFilter({
  categoryArrData,
  subcategoryArrData,
  setProductArr,
  materialArrData,
  sortByData,
  selectedCategoryIdData,
  showFilterMobile,
  setShowFilterMobile,
  filterBreakPoint,
  setPage,
  setTotalPages,
  setPerPage,
  setTotalCount,
  page,
  totalPages,
  perPage,
  totalCount,
}) {
  const params = useParams();
  const [categoryArr, setCategoryArr] = useState([]);
  const [subCategroyArr, setSubCategroyArr] = useState([]);
  const [materialArr, setMaterialArr] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [sortBy1, setSortBy] = useState("");

  const [topSeller, setTopSeller] = useState(false);
  const [newArrival, setNewArrival] = useState(false);
  const [rangevalue, setRangevalue] = useState("");
  const [filtersArr, setFiltersArr] = useState([]);

  const productData = useSelector((state) => state.product.items);
  const attributeData = useSelector((state) => state.attribute.items);
  const dispatch = useDispatch();

  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  const [productCategoryObj, setproductCategoryObj] = useContext(
    productCategoryObjectContext
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [attributeArr, setAttributeArr] = useState([]);
  const [attributeArray, setAttributeArray] = useState([]);
  // const [checkboxValue, setCheckboxValue] = useState(false);
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    dispatch(getAttributeData());
  }, []);

  useEffect(() => {
    setAttributeArr(attributeData);
  }, [attributeData]);

  const handleGetPaginatedProducts = async (
    categoryArr,
    subCategroyArr,
    materialArr,
    sortByData
  ) => {
    try {
      // console.log(page,"pagepage")
      let query = `page=${page ? page : 1}&perPage=${perPage ? perPage : 10}`;
      let selectedCategoryArr = returnSelectedCategories(categoryArr)?.map(
        (el) => {
          return { categoryId: el._id };
        }
      );

      if (sortBy1 && sortBy1 != "") {
        query = `${query}&sortBySellerOrArrival=${sortBy1}`;
      }
      if (newArrival) {
        query = `${query}&newArrival=${newArrival}`;
      }
      if (topSeller) {
        query = `${query}&bestSellers=${topSeller}`;
      }
      if (sortByData && sortByData != "") {
        query = `${query}&sortBy=${sortByData}`;
      }
      let tempArr = [];
      if (selectedCategoryArr && selectedCategoryArr?.length >= 0) {
        tempArr = [...selectedCategoryArr];
      }
      // console.log(tempArr, "tempArr234");
      if (tempArr && tempArr.length > 0) {
        query = `${query}&categoryArr=${JSON.stringify([...tempArr])}`;
      }
      if (rangevalue) {
        query = `${query}&minimumPriceRange=${rangevalue}`;
      }
      if (currencyDetails.convertRate) {
        query = `${query}&currencyConvertRate=${currencyDetails.convertRate}`;
      }
      if (params.slug != "All") {
        query = `${query}&categoryIds=${
          productCategoryObj._id ? productCategoryObj._id : "All"
        }`;
      } else {
        query = `${query}&categoryIds=All`;
      }
      if (attributeArray?.length) {
        query = `${query}&attributeIdArr=${JSON.stringify([
          ...attributeArray,
        ])}`;
      }
      // console.log(productCategoryObj, "-=productCategoryObj");
      //
      // og(query, "-=--=query");
      dispatch(fetchProduct(query));
    } catch (err) {
      toastError(err);
    }
  };

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Shuffle the array multiple times to ensure all elements are shuffled
  function shuffleArray(array, numShuffles = 3) {
    let shuffledArray = [...array];
    for (let i = 0; i < numShuffles; i++) {
      shuffledArray = shuffle(shuffledArray);
    }
    return shuffledArray;
  }

  useEffect(() => {
    console.log(productData, "productArr234= get get ");
    if (productData) {
      let filterPRoductArr = productData?.data?.filter(
        (x) => x.status == "Publish"
      );
      // console.log(filterPRoductArr,"====filterPRoductArr===")
      // let data = [...filterPRoductArr];
      let data = [];
      if (filterPRoductArr&&filterPRoductArr?.length) {
        data = [...filterPRoductArr];
      }
      let temp = [];
      if (sortByData || rangevalue) {
        temp = data;
      } else {
        temp = shuffleArray(data);
      }
      // console.log(temp,"t====temp")
      setProductArr(temp);
      setPage(productData?.page);
      setTotalPages(productData?.totalPages);
      setPerPage(productData?.perPage);
      setTotalCount(productData?.totalCount);
    } else {
      setProductArr([]);
    }
  }, [productData]);

  useEffect(() => {
    // console.log(sortByData, "sortByData");
    handleGetPaginatedProducts(
      categoryArr,
      subCategroyArr,
      materialArr,
      sortByData
    );
  }, [
    sortByData,
    page,
    rangevalue,
    params.slug,
    sortBy1,
    currencyDetails.convertRate,
    attributeArray,
  ]);

  const handleFilterSelection = (index, indexX, e) => {
    let tempArr = filtersArr;
    if (indexX != null) {
      tempArr[index].values[indexX].checked = e;
    } else {
      tempArr[index].value1 = e;
    }
    setFiltersArr([...tempArr]);
  };

  const handleReturnAcresAndHectares = (value) => {
    let acresValue = value;
    setRangevalue(acresValue.toFixed());
  };

  useEffect(() => {
    // console.log(categoryArrData, "eget categoryArrData");
    let filterDataCategory = categoryArrData?.filter(
      (x) => x.status == "APPROVED"
    );
    setCategoryArr([...filterDataCategory]);
  }, [categoryArrData]);

  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr?.filter((el) => el.checked);
    return new_selected_arr;
  };

  const handleRenderNestedCategory = (arr, id, value) => {
    try {
      let tempArr = arr.map((el) => {
        if (el._id == id) {
          el.checked = value;
          return el;
        } else {
          if (el.subCategoryArr && el.subCategoryArr.length > 0) {
            handleRenderNestedCategory(el.subCategoryArr, id, value);
          } else {
            return el;
          }
        }
      });
      return tempArr;
    } catch (err) {
      toastError(err);
    }
  };

  const handleCategoryCheckBoxEvent = (id, value) => {
    // console.log(id, "ididid", value, "value111");
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        // el.checked = !el.checked;
        el.checked = value;
      }
      // else {
      //   el.checked = false;
      // }
      return el;
    });
    handleGetPaginatedProducts(
      tempCategoryArr,
      subCategroyArr,
      materialArr,
      sortBy1
    );
    setCategoryArr([...tempCategoryArr]);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () => {
      window.addEventListener("resize", function () {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  const handleChangePRoductDetails = (e, value) => {
    try {
      // console.log(e.target.name,"e.target.name",value,"value---=-=")
      if (e.target.name == "arrival") {
        setNewArrival(value);
        setTopSeller(false);
        if (value) {
          setSortBy("New Arrival");
        } else {
          setSortBy("");
        }
      } else {
        setTopSeller(value);
        setNewArrival(false);
        if (value) {
          setSortBy("Top seller");
        } else {
          setSortBy("");
        }
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleChangeDetails = (event, value) => {
    const { name } = event.target;
    setProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    // console.log(id, value, "====id, value===");

    let tempArr2 = attributeArr.map((el) => {
      let temp2 = el?.attributeValueArr.map((le, ind) => {
        if (le.attributeId == id) {
          // Create a new object with the checked property set to the new value
          let updatedLe = Object.assign({}, le, {
            checked: el.checked ? !le.checked : value,
          });
          return updatedLe;
        } else {
          return le;
        }
      });
      // Create a new object with the updated attributeValueArr property
      let updatedEl = Object.assign({}, el, { attributeValueArr: temp2 });
      return updatedEl;
    });

    // console.log(tempArr2,"tempArr2tempArr2")
    // handleGetPaginatedProducts(
    //   tempCategoryArr,
    //   subCategroyArr,
    //   materialArr,
    //   sortBy1
    // );
    setAttributeArr([...tempArr2]);
  };

  useEffect(() => {
    let checkedIds = [];
    attributeArr.forEach((el) => {
      el.attributeValueArr.forEach((le) => {
        if (le.checked) {
          checkedIds.push(le.attributeId);
        }
      });
    });
    setAttributeArray(checkedIds);
    // if (checkedIds.length) {
    //   handleGetPaginatedProducts();
    // }
  }, [attributeArr]);

  const handleRenderCheckboxCategory = (obj, i) => {
    // console.log(obj,"====obj===")
    return (
      <li key={`${obj._id}_${i}`} className="shopfilters-list-title">
        <div
        // data-bs-toggle="collapse"
        // data-bs-target={`#Category${obj.level}${obj._id}`}
        // aria-expanded="false"
        // aria-controls={`$Category${obj.level}${obj._id}`}
        // className="shopfilters-list-title"
        >
          <input
            type="checkbox"
            onChange={(event) =>
              handleNestedCategoryCheckBoxEvent(
                obj?.obj?._id,
                event.target.checked
              )
            }
            checked={obj.checked}
            id={`${obj._id}`}
          />

          <label htmlFor={`${obj?.obj?._id}`}>
            {" "}
            &nbsp;&nbsp;{obj?.obj?.name}
          </label>
        </div>
      </li>
    );
  };

  const Filter = () => {
    return (
      <div className="shop-filter sidebar_filter bg3 sticky-top" style={{}}>
        <div className="box pb-0">
          <h5 className="title" style={{ marginTop: "20px" }}>
            Filter
          </h5>
        </div>
        <div className="box" style={{ marginTop: "20px" }}>
          <h5 className="title">Categories</h5>
          <ul className="list comm-list">
            {categoryArr &&
              categoryArr.length > 0 &&
              categoryArr.map((el, indexX) => {
                return (
                  <li key={indexX}>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    > */}
                    <input
                      className="form-check-input"
                      checked={el?.checked}
                      id={`${el?.name}${indexX}`}
                      onChange={(event) => {
                        handleCategoryCheckBoxEvent(
                          el?._id,
                          event.target.checked
                        );
                      }}
                      type="checkbox"
                    />
                    <label
                      htmlFor={`${el?.name}${indexX}`}
                      style={{ paddingLeft: 5, paddingTop: 5 }}
                    >
                      {el?.name}
                    </label>
                    {/* </div> */}
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="box" style={{ marginTop: "20px" }}>
          <h5 className="title">Seller</h5>
          <ul className="list comm-list">
            <li>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  className="form-check-input pointer"
                  checked={topSeller}
                  name="topSeller"
                  onChange={(event) =>
                    handleChangePRoductDetails(event, !topSeller)
                  }
                  type="checkbox"
                />
                <label style={{ paddingLeft: 5 }}>
                  Top Seller
                  {/* {el?.name} */}
                </label>
              </div>
            </li>

            <li>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  className="form-check-input pointer"
                  checked={newArrival}
                  name="arrival"
                  onChange={(event) =>
                    handleChangePRoductDetails(event, !newArrival)
                  }
                  type="checkbox"
                />
                <label style={{ paddingLeft: 5 }}>
                  New Arrival
                  {/* {el?.name} */}
                </label>
              </div>
            </li>
          </ul>
        </div>

        {attributeArr.map((obj, index) => {
          return (
            <div className="box" style={{ marginTop: "20px" }} key={index}>
              <h5>{obj?.name}</h5>

              {obj?.attributeValueArr &&
                obj?.attributeValueArr.length &&
                obj?.attributeValueArr.map((el, i) => {
                  return handleRenderCheckboxCategory(el, i);
                  // <ul className="list comm-list" key={indx}>
                  //   <li>
                  //     <div
                  //       style={{
                  //         display: "flex",
                  //         flexDirection: "row",
                  //         alignItems: "center",
                  //       }}
                  //     >
                  //       <input
                  //         className="form-check-input pointer"
                  //         checked={el.checked} // use the checked property of the attribute value object
                  //         name={el?.obj?.name}
                  //         onChange={(event) => {
                  //           // update the checked property of the clicked attribute value object
                  //           const newValue = !el.checked;
                  //           const newAttributeValues = [
                  //             ...obj.attributeValueArr,
                  //           ];
                  //           newAttributeValues[indx] = {
                  //             ...el,
                  //             checked: newValue,
                  //           };
                  //           handleChangeDetails(
                  //             event,
                  //             topSeller,
                  //             newArrival,
                  //             newAttributeValues
                  //           );
                  //         }}
                  //         // onChange={handleChange}
                  //         type="checkbox"
                  //       />
                  //       <label style={{ paddingLeft: 5 }}>
                  //         {el?.obj?.name}
                  //       </label>
                  //     </div>
                  //   </li>
                  // </ul>
                })}
            </div>
          );
        })}

        {/* 
        <div className="box" style={{ marginTop: "20px" }}>
          <h5 className="title">Seller</h5>
          <ul className="list comm-list">
            <li>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  className="form-check-input pointer"
                  checked={topSeller}
                  name="topSeller"
                  onChange={(event) =>
                    handleChangePRoductDetails(event, !topSeller)
                  }
                  type="checkbox"
                />
                <label style={{ paddingLeft: 5 }}>
                  Top Seller
              
                </label>
              </div>
            </li>

            <li>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  className="form-check-input pointer"
                  checked={newArrival}
                  name="arrival"
                  onChange={(event) =>
                    handleChangePRoductDetails(event, !newArrival)
                  }
                  type="checkbox"
                />
                <label style={{ paddingLeft: 5 }}>
                  New Arrival
               
                </label>
              </div>
            </li>
          </ul>
        </div> */}

        <div className="box" style={{ marginTop: "20px" }}>
          <h5 className="title" style={{ fontSize: "25px" }}>
            Select Range Price
          </h5>

          {/* <h3>{categoryPageConversionObj?.farmSize}</h3> */}
          <p style={{ fontWeight: "300", fontSize: 17 }}>
            {/* {acres}  */}
            Minimum {currencyDetails?.symbol}{" "}
            {parseInt(rangevalue ? rangevalue : 0) *
              parseInt(currencyDetails?.convertRate)}
          </p>
          <Slider
            defaultValue={30}
            value={rangevalue}
            valueLabelDisplay="auto"
            step={50}
            sx={{
              color: "#CE9763",
            }}
            onChange={(e, value) => {
              setRangevalue(value);
            }}
            onChangeCommitted={(e, value) => {
              handleFilterSelection(null, value);
            }}
            min={0}
            max={10000}
            style={{
              "border-radius": "12px",
              display: "flex",
              cursor: "pointer",
              color: "#CE9763",
              "-webkit-tap-highlight-color": "transparent",

              flex: 1,
              padding: "13px 0",
              color: "#CE9763",
            }}
          />
          {/* <Slider
  defaultValue={30}
  value={rangevalue}
  valueLabelDisplay="auto"
  step={50}
  sx={{
    color: "#CE9763",
  }}
  onChange={(e, value) => {
    setRangevalue(value);
    handleFilterSelection(null, value);
  }}
  onMouseMove={(e) => {
    const value = parseInt(e.target.getAttribute('aria-valuenow'));
    setRangevalue(value);
    handleFilterSelection(null, value);
  }}
  min={0}
  max={10000}
  style={{
    borderRadius: "12px",
    display: "flex",
    cursor: "pointer",
    color: "#CE9763",
    WebkitTapHighlightColor: "transparent",
    flex: 1,
    padding: "13px 0",
    color: "#CE9763",
  }}
/>  */}
        </div>
      </div>
    );
  };

  return (
    <>
      {windowWidth < filterBreakPoint && (
        <Offcanvas
          show={showFilterMobile}
          onHide={() => setShowFilterMobile(false)}
        >
          <Offcanvas.Header closeButton className="p-0"></Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <Filter />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {windowWidth > filterBreakPoint && <Filter />}
    </>
  );
}

export default ShopFilter;
