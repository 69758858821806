import { img } from "../Components/Image";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  userAddressAdd,
  getUserAddress,
  DeleteUserAddress,
  updateUserAddress,
  setUserData,
} from "../redux/userAddress.slice";

import { fetchcompanyAddress } from "../redux/CompanyAddress.slice";
import { generateOrder } from "../redux/Order.slice";
import { currencyContext } from "../App";

import { toastError, toastSuccess } from "../utils/toastUtils";
import { createOrder, orderCallback } from "../services/order.service";
import { useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  addUserWallet,
  addAmountToWalletCallback,
  fetchUserWallet,
} from "../redux/userWallet.slice";

const Shiping = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);

  const [firstName, setFirstName] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [stateOf, setStateOf] = useState("");

  const [other, setOther] = useState("");

  const [addressArray, setAddressArray] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [cartArray, setCartArray] = useState([]);

  const [userAdddressId, setUserAdddressId] = useState("");

  const userObj = useSelector((state) => state.auth.user);
  const userAddressArr = useSelector((state) => state.userAddress.items);
  const userAddressObject = useSelector((state) => state.userAddress.itemsObj);
  const companyAddressArr = useSelector((state) => state.companyAddress.items);
  const OrderObject = useSelector((state) => state.Order.itemsObj);

  const [paymentMethod, setPaymentMethod] = useState("Wallet");
  const [isPaymentOptionShow, setIsPaymentOptionShow] = useState(false);
  const [userWalletObject, setUserWalletObject] = useState({});
  // userWallet
  const userWalletObj = useSelector((state) => state.userWallet.itemsObj);
  const [isPaymentModeWallet, setIsPaymentModeWallet] = useState(false);

  const [showConfirmPaymentStatus, setShowConfirmPaymentStatus] =
    useState(false);

  useEffect(() => {
    if (userObj && userObj?._id) {
      dispatch(getUserAddress(userObj?._id));
      dispatch(fetchcompanyAddress());
      dispatch(fetchUserWallet(null));
    }
  }, []);

  useEffect(() => {
    let companyState = companyAddressArr[0]?.stateObj?.name;
    let tax = 0;
    if (
      stateOf?.trim().toLocaleLowerCase() ==
      companyState?.trim().toLocaleLowerCase()
    ) {
      tax = cartArray.reduce(
        (axx, el) =>
          axx +
          ((el?.price * (el?.hsnObj?.cgst + el?.hsnObj?.sgst)) / 100) *
            currencyDetails?.convertRate *
            el?.quantity,
        0
      );
    } else {
      tax = cartArray.reduce(
        (axx, el) =>
          axx +
          el?.price *
            (el?.hsnObj?.igst / 100) *
            currencyDetails?.convertRate *
            el?.quantity,
        0
      );
    }
    tax = parseFloat(tax).toFixed(2);

    // setTax(tax);
    setTax(0);
  }, [stateOf]);

  useEffect(() => {
    // console.log(userAddressArr, "userAddressArr234");
    if (userAddressArr) {
      setAddressArray(userAddressArr);
    }
  }, [userAddressArr]);

  useEffect(() => {
    // console.log(userAddressObject, "userAddressObject23");
    if (userAddressObject) {
      setSubTotal(userAddressObject?.subTotal);
      setCartArray(userAddressObject?.cartArr);
      // setTax(userAddressObject?.taxes);
      setShipping(userAddressObject?.shipping);
      setSymbol(userAddressObject?.symbol);
    }
  }, [userAddressObject]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(obj, orderId) {
    try {
      // console.log(obj, "obj, =-=", orderId, "-=-= orderId");
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        key: "rzp_test_jOl57g4TNamtFW", // Enter the Key ID generated from the Dashboard//???????????????
        amount: obj?.amount,
        currency: obj?.currency,
        name: " Pooja Samagri",
        description: "Order",
        // image: { logo },
        order_id: obj?.id,
        handler: async function (response) {
          const data = {
            orderCreationId: obj?.id,
            razorpayPaymentId: response?.razorpay_payment_id,
            razorpayOrderId: response?.razorpay_order_id,
            razorpaySignature: response?.razorpay_signature,
          };

          const serialize = function (obj) {
            var str = [];
            for (var p in obj)
              if (obj?.hasOwnProperty(p)) {
                str?.push(
                  encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                );
              }
            return str?.join("&");
          };

          // let { data: res } = await orderCallback(serialize(obj), orderId);

          // if (res) {
          //   // navigate("/account/order");
          //   toastSuccess(res.message);
          //   //  clearlocalStorage();
          //   navigate("/Shiping");
          // }
        },

        theme: {
          color: "#61dafb",
        },
      };
      // console.log(options, "opt2ions----");
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      toastError(err);
    }
  }

  const handleProceedToCheckout = async (e) => {
    try {
      if (firstName?.trim() == "") {
        toastError("First name is mandatory");
        return;
      } else if (address?.trim() == "") {
        toastError("Address is mandatory");
        return;
      } else if (stateOf?.trim() == "") {
        toastError("State is mandatory");
        return;
      } else if (pinCode?.trim() == "") {
        toastError("PinCode is mandatory");
        return;
      } else if (email?.trim() == "") {
        toastError("Email is mandatory");
        return;
      } else if (lastName?.trim() == "") {
        toastError("Last Name is mandatory");
        return;
      } else if (country?.trim() == "") {
        toastError("Country is mandatory");
        return;
      } else if (city?.trim() == "") {
        toastError("City is mandatory");
        return;
      } else if (phone?.trim() == "") {
        toastError("Phone is mandatory");
        return;
      }

      // console.log( cartArray," ----==cartArray===")
      let cartArr = cartArray.map((el) => {
        return {
          productId: el?.productId,
          quantity: el?.quantity,
          price: el?.price,
        };
      });
      // let cartArr = cartArray.map((el) => {
      //   return { price: el?.price, quantity: el?.quantity, hsnObj: el?.hsnObj };
      // });
      let userObject = {
        userId: userObj?._id,
        firstName,
        companyName,
        address,
        pinCode,
        email,
        lastName,
        country,
        city,
        phone,
        other,
        // subTotal,
        // tax,
        state: stateOf,
        shipping,
        symbol,
        country: country,
        companyState: companyAddressArr[0]?.stateObj?.name,
        OrderTotal: subTotal + shipping + tax,
      };
      let obj = {
        shipping,
        cartArr,
        isPaymentModeWallet,
        // taxes,
        userObject,
        userAddressId: userAdddressId,
        currencyId: currencyDetails?._id,
      };

      // console.log(obj, "obj-shoppingcart");

      const { data: response } = await createOrder(obj);
      if (response && response?.data && response.orderId) {
        if (showConfirmPaymentStatus) {
          // console.log(response,"response234-1")
          toastSuccess(response?.message);
          setShowConfirmPaymentStatus(false);
          setIsPaymentModeWallet(false);
        } else {
          // console.log(response, "displayRazorpay,-===");
          displayRazorpay(response.data, response.orderId);
        }
      }
      // dispatch(generateOrder(obj));
    } catch (err) {
      toastError(err);
    }
  };

  const handleCheckAddressAndProceedToCheckout = (e) => {
    try {
      if (firstName?.trim() == "") {
        toastError("First name is mandatory");
        return;
      } else if (address?.trim() == "") {
        toastError("Address is mandatory");
        return;
      } else if (stateOf?.trim() == "") {
        toastError("State is mandatory");
        return;
      } else if (pinCode?.trim() == "") {
        toastError("PinCode is mandatory");
        return;
      } else if (email?.trim() == "") {
        toastError("Email is mandatory");
        return;
      } else if (lastName?.trim() == "") {
        toastError("Last Name is mandatory");
        return;
      } else if (country?.trim() == "") {
        toastError("Country is mandatory");
        return;
      } else if (city?.trim() == "") {
        toastError("City is mandatory");
        return;
      } else if (phone?.trim() == "") {
        toastError("Phone is mandatory");
        return;
      }

      // if (
      //   firstName?.trim() == "" ||
      //   address?.trim() == "" ||
      //   stateOf?.trim() == "" ||
      //   pinCode?.trim() == "" ||
      //   email?.trim() == "" ||
      //   lastName?.trim() == ""||
      //   country?.trim() == "" ||
      //   city?.trim() == "" ||
      //   phone?.trim() == ""
      // ) {
      //   toastError("Address is mandatory, kindly select one");
      //   return;
      // }
      setIsPaymentOptionShow(true);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOrder = () => {
    try {
      if (paymentMethod == "Razorpay") {
        handleProceedToCheckout();
        setIsPaymentOptionShow(false);
      } else {
        setIsPaymentModeWallet(true);
        // console.log(userObj?._id, "userObj?._id23423");
        dispatch(fetchUserWallet(userObj?._id));
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(userWalletObj, " userWalletObj234-");
    if (userWalletObj && userWalletObj?._id) {
      // console.log(userWalletObj?.walletAmount < +subTotal + +shipping + +tax," userWalletObj234-2")
      if (userWalletObj?.walletAmount < +subTotal + +shipping + +tax) {
        // console.log(userWalletObj?.walletAmount, " userWalletObj234-1");
        setIsPaymentModeWallet(false);
        toastError(
          `In-Sufficient amount wallet have Rs ${userWalletObj?.walletAmount}, Kindly use other payment mode or add money to wallet`
        );
        return;
      } else {
        setIsPaymentOptionShow(false);
        setShowConfirmPaymentStatus(true);
      }
      // handleProceedToCheckout();

      setUserWalletObject(userWalletObj);
    }
  }, [userWalletObj]);

  const handleSetAddress = (e) => {
    let value = e.target.value;
    let findAddressObj = addressArray.find((el) => el.address == value);
    // console.log(findAddressObj, "findAddressObj234");
    setUserAdddressId(findAddressObj?._id);
    if (findAddressObj && findAddressObj._id) {
      setFirstName(findAddressObj?.firstName);
      setAddress(findAddressObj?.address);
      setPinCode(findAddressObj?.pincode);
      setStateOf(findAddressObj?.state);
      setEmail(findAddressObj?.userEmail);
      setLastName(findAddressObj?.lastName);
      setCountry(findAddressObj?.country);
      setCity(findAddressObj?.city);
      setPhone(findAddressObj?.userPhone);
      // setOther(findAddressObj?.)
    }
  };

  return (
    <>
      <section className="bgyellowlight">
        <div className="banner_category">
          <div className="overlay1"></div>
          <h4>Shipping Details</h4>
        </div>

        <div className="add_newsectiondcart">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="bar_cart active">
                  <span class="num active">1</span> Shopping Cart{" "}
                  <i className="fa fa-long-arrow-right"> </i>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="bar_cart">
                  <span class="num">2</span> Payment & Delivery Options{" "}
                  <i className="fa fa-long-arrow-right"> </i>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="bar_cart">
                  <span class="num">3</span> Order Received
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shipping_details">
          <div className="container">
            <div className="row py-4">
              <div className="col-lg-8 from_style ">
                {/* <div className="col-lg-6 shipi-style">
                  <h3>Shiping Details</h3>
                </div> */}
                <div className="row from_style ">
                  <div className="col-lg-6 form-heading">
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        First Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fname"
                        aria-describedby="nameHelp"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {/* {name} */}
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Email address <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        aria-describedby="countryHelp"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Company Name(Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        aria-describedby="nameHelp"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Address <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="stress address"
                        aria-describedby="addressHelp"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        State <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="stateName"
                        aria-describedby="countryHelp"
                        value={stateOf}
                        onChange={(e) => setStateOf(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Pin code <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        aria-describedby="countryHelp"
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                      />
                    </div>
                 
                  </div>

                  <div className="col-lg-6 form-heading">
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Last Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lname"
                        aria-describedby="lastHelp"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Country/Region <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        aria-describedby="countryHelp"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Town/City <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        aria-describedby="countryHelp"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Phone number <span className="red">*</span>
                      </label>
                      <input
                        maxLength={10}
                        type="tel"
                        className="form-control"
                        id="companyname"
                        aria-describedby="countryHelp"
                        value={phone}
                        onChange={(e) => {
                          if (isNaN(e.target.value)) {
                          } else {
                            setPhone(e.target.value);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="name" className="form-label">
                        Other
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyname"
                        aria-describedby="countryHelp"
                        value={other}
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <h3>Select address </h3>
                    <select
                      className="form-control nc_select"
                      name="country"
                      onChange={(e) => handleSetAddress(e)}
                    >
                      <option value="">select from option</option>
                      {addressArray &&
                        addressArray.length &&
                        addressArray.map((el, index) => {
                          return (
                            <option key={index} value={`${el.address}`}>
                              {el?.address}{" "}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>

              <Modal
                show={isPaymentOptionShow}
                onHide={() => setIsPaymentOptionShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <p className="title mb-0">Payment</p>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="mb-2">
                    All transactions are secure and encrypted.
                  </p>

                  <form className="form">
                    <ul className="info">
                      <li>
                        <div className="form-check">
                          <label className="pointer">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payment-method"
                              onClick={() => setPaymentMethod("Wallet")}
                              checked={paymentMethod == "Wallet"}
                            />
                            Wallet
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payment-method"
                              onClick={() => setPaymentMethod("Razorpay")}
                              checked={paymentMethod == "Razorpay"}
                            />
                            Razorpay
                          </label>
                        </div>
                      </li>
                    </ul>
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setIsPaymentOptionShow(false)}
                  >
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={()=>setIsPaymentOptionShow(false)}> */}
                  <Button variant="primary" onClick={(e) => handleOrder(e)}>
                    Continue to Payment
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showConfirmPaymentStatus}
                onHide={() => setShowConfirmPaymentStatus(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Confirm Payment
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4> All transactions are secure and encrypted.</h4>

                  <div className="form-check">
                    <label className="form-check-label">
                      Wallet Amount : {currencyDetails.symbol}{" "}
                      {userWalletObj?.walletAmount}
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label">
                      Order Amount :
                      {currencyDetails.symbol +
                        " " +
                        parseFloat(+subTotal + +shipping + +tax).toFixed(2)}
                    </label>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setIsPaymentOptionShow(true);
                      setShowConfirmPaymentStatus(false);
                    }}
                  >
                    Back
                  </Button>
                  {/* <Button variant="primary" onClick={()=>setIsPaymentOptionShow(false)}> */}
                  <Button
                    variant="primary"
                    onClick={(e) => handleProceedToCheckout(e)}
                  >
                    Continue to Payment
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className="col-lg-4 summary_style">
                <div className="order-style">
                  <h6 className="order-t">Order Summary</h6>
                  <div className="cart_su">
                    Subtotal<span> {symbol + " " + subTotal} </span>
                  </div>
                  <div className="cart_su">
                    Shipping <span> {symbol + " " + shipping} </span>
                  </div>
                  <div className="cart_su">
                    Tax <span> {symbol + " " + tax} </span>
                  </div>
                  <hr />
                  <div className="cart_su">
                    Order Total
                    <b>
                      {" "}
                      {symbol +
                        " " +
                        parseFloat(+subTotal + +shipping + +tax).toFixed(2)}
                    </b>
                  </div>
                </div>

                <div className="form-check py-3 link-style">
                  <h6>Direct Bank Transfer</h6>

                  <p>
                    Make your payment directly into our bank account. Please use
                    your Order ID as the payment reference. Your order will not
                    be shipped until the funds have cleared in our account.
                  </p>

                  <p>
                    Your personal data will be used to support your experience
                    throughout this website, to manage access to your account,
                    and for other purposes described in our privacy policy.
                  </p>

                  <button
                    type="button"
                    // onClick={(e) => handleProceedToCheckout(e)}
                    // onClick={(e) => setIsPaymentOptionShow(true)}
                    onClick={(e) => handleCheckAddressAndProceedToCheckout(e)}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Shiping;
