import Index from "../pages/Index";
import About from "../pages/About";
import Blog from "../pages/Blog";
import Category from "../pages/Category";
import Product from "../pages/Product";
import Shopingcart from "../pages/Shopingcart";
import Shiping from "../pages/Shiping";
import Contact from "../pages/Contact";
import Register from "../pages/Register";
import Login from "../pages/Login";

import Faqs from "../pages/Faqs";
import TremsCon from "../pages/TremsCon";
import Privacy from "../pages/Privacy";
import Wishlist from "../pages/Wishlist";
import SingleBlog from "../pages/SingleBlog";
import Addcart from "../pages/Addcart";
import MyProfile from "../pages/MyProfile";
import OrderDetail from "../pages/OrderDetail";
import Wallet from "../pages/Wallet";
import Checkout from "../pages/Checkout";
import Shiping2 from "../pages/Shiping2";
import NotFound from "../Components/NotFound";
import Index1 from "../pages/index1";
import Orderdetails from "../pages/Orderdetails";

export const routes = [
    {
        name: "/",
        component: <Index1 />,
        isAuthorised: false,
        authIndependent: true,
    },
    // {
    //     name: "/index1",
    //     component: <Index1 />,
    //     isAuthorised: false,
    //     authIndependent: true,
    // },
    {
        name: "/About",
        component: <About />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Orderdetails",
        component: <Orderdetails />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Blog",
        component: <Blog />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Blog/:slug",
        component: <SingleBlog />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Category/:slug",
        component: <Category />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Product/:slug",
        component: <Product />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Shopingcart",
        component: <Shopingcart />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Shiping",
        component: <Shiping />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/Shiping2",
        component: <Shiping2 />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/Contact",
        component: <Contact />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Faqs",
        component: <Faqs />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/TermsAndCondition",
        component: <TremsCon />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Privacy",
        component: <Privacy />,
        isAuthorised: false,
        authIndependent: true,
    },
    {
        name: "/Addcart",
        component: <Addcart />,
        isAuthorised: true,
        authIndependent: true,
    },
    {
        name: "/MyProfile",
        component: <MyProfile />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/OrderDetail",
        component: <OrderDetail />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/Wallet",
        component: <Wallet />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/checkout",
        component: <Checkout />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/Wishlist",
        component: <Wishlist />,
        isAuthorised: true,
        authIndependent: false,
    },
    {
        name: "/Register",
        component: <Register />,
        isAuthorised: false,
        authIndependent: false,
    },
    {
        name: "/Login",
        component: <Login />,
        isAuthorised: false,
        authIndependent: false,
    },
    {
        name: "*",
        component: <NotFound />,
        isAuthorised: false,
        authIndependent: true,
    },

]























