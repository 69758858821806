import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/blogCategory";

export const addBlogCategory = (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getBlogCategory = (query) => {
  return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const deleteBlogCategory = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateBlogCategory = (id, formData) => {
  // console.log(id, "odi axios");
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
