// 

import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/flashSales";


export const getTodaysDeals = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getForHomepage?${query}`);
};

