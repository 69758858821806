import React, { useEffect, useState,useContext } from "react";
import Select from "react-select";
import { FaGooglePay } from "react-icons/fa";
import { SiVisa } from "react-icons/si";
import { addAmountToWalletCallback, addUserWallet, getUserWallet } from "../services/usersWallet.service";
import { toastError } from "../utils/toastUtils";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { currencyContext } from "../App";

const Wallet = () => {
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);




  const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [transactionArr, setTransactionArr] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleClose = () => { setShow(false); setAmount(0) };
  const handleShow = () => setShow(true);


  const [userWalletAmount, setUserWalletAmount] = useState(0);
  ////////razorpay react
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleWalletRecharge = async () => {
    try {

      if (!amount || parseInt(amount) <= 0) {
        toastError("Please add a valid amount");
        return
      }

      setShow(false)

      let obj = {
        amount
      }
      let { data: res } = await addUserWallet(obj)
      if (res) {
        // console.log(res, "ress")
        handleRedirect(res.data, res.transactionHistoryId)

      }
    }
    catch (err) {
      toastError(err)
    }
  }

  useEffect(() => {
    getWalletAmountFromDb()
  }, [])

  const handleRedirect = async (obj, orderId) => {
    try {
      // console.log(obj, "obj, =-=", orderId, "-=-= orderId");
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      let tempObj = obj;
      // console.log(tempObj.amount, "tempObj.amount");
      let OrderId = orderId;
      var options = {
        description: "Order",
        image: "https://i.imgur.com/3g7nmJC.png",
        currency: tempObj.currency,
        key: "rzp_test_jOl57g4TNamtFW",
        amount: tempObj.amount,
        name: "Wallet",
        order_id: tempObj.id, //Replace this with an order_id created using Orders API.
        theme: { color: "#F84B4B" },
        handler: async function (response) {
          let Obj = {
            ...response,
            amount: tempObj.amount,
          };
          await handlePaymentCallBack(Obj, OrderId);
        },
      };
      let paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const handlePaymentCallBack = async (obj, id) => {
    try {
      // console.log(id, "id")
      const serialize = function (obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      };
      let { data: res, status: statusCode } = await addAmountToWalletCallback(
        serialize(obj),
        id
      );
      if (statusCode == 200 || statusCode == 304) {
        alert(res.message);
        getWalletAmountFromDb();
      }
    } catch (error) {
      toastError(error);
    }
  };

  const getWalletAmountFromDb = async () => {
    try {
      let query = ""

      if (startDate && endDate) {

        query = `startDate=${startDate}&endDate=${endDate}`
      }

      let { data: res } = await getUserWallet(query);
      if (res.data) {
        setUserWalletAmount(res.data.walletAmount);
        setTransactionArr(res.data.transactionHistoryArr);

      }
      // console.log(
      //   res.data,
      //   "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! wallet !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
      // );
    } catch (err) {
      if (err?.response?.data?.message) {
        // console.log(err?.response?.data?.message);
        alert(err?.response?.data?.message);
      } else {
        // console.log(err);
        toastError(err);
        // alert(err);
      }
    }
  };



  return (
    <main className="border-top">
    <div className="banner_category">
        <div className="overlay1"></div>
        <h4>Wallet</h4>
      </div>

      <section className="ptb-80 bgyellowlight">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wallet_outer pt-0 px-0">

                <h3>Transcation History ({userWalletAmount})</h3>
                <Button className="btn btn-wallet" variant="primary" onClick={handleShow}>
                  Recharge Wallet Now
                </Button>
              </div>
              <div className="row my-5">
                <div className="col-4">
                  <label htmlFor="">Start Date</label>
                  <input
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={moment(startDate).format("YYYY-MM-DD")}
                    className="form-control"
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="">End Date</label>
                  <input
                    type="date"
                    value={moment(endDate).format("YYYY-MM-DD")}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-2">
                  <label>&nbsp;</label> <p className="mb-0"></p>
                  <Button className="btn btn-wallet" variant="primary" onClick={() => getWalletAmountFromDb()}>
                    Search
                  </Button>
                </div>

              </div>
              <div className="wallet_table wallet_sectionchanges">
                <table className="table">
                  <thead className="thead-style">
                    <tr>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Message</th>
                      <th scope="col">Transaction type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      transactionArr && transactionArr.length > 0 && transactionArr.map((el, index) => {
                        return (
                          <tr key={index} className="text-color">
                            <td>{moment(el.createdAt).format("DD/MM/yyyy")}</td>
                            <td>
                            {/* {  currencyDetails?.symbol} {parseFloat(1000 * currencyDetails?.convertRate
                                  ).toFixed(2)} */}
                              <span style={{ color: el.transactionType == "Credit" ? "green" : "red" }}>  
                              
                              {currencyDetails?.symbol}

                              {el.transactionType == "Credit" ? 
                              ` + ${  currencyDetails?.convertRate*el.amount}` : 
                              `- ${el.amount}` }   </span>
                            </td>
                            <td>{el.message}</td>
                            <td>{el.transactionType}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <Modal show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Recharge Wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div>
                    Enter Amount to be added {amount}
                  </div>

                  <input
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    type="number"
                    placeholder="Please Enter amount here"
                    className="form-control" />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button  variant="danger" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="btn-wallet" variant="primary" onClick={() => handleWalletRecharge()}>
              Add  Money
            </Button>
          </Modal.Footer>
        </Modal>





        {/* <div className='wallet-page py-5 mb-5'>
                    <div className='container'>

                        <div className='row '>
                            <div className='col-lg-10 main-style'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <h3>Transcation History</h3>

                                    </div>

                                    <div className='col-lg-6  wallet-info'>

                                        <select className="wallet_select">
                                            <option>Weekly</option>
                                            <option>Monthly</option>
                                            <option>Yearly</option>

                                        </select>
                                    </div>

                                    <div className='col-lg-12'>
                                        <div className='main-style'>
                                            <table className="table">
                                                <thead className='thead-style'>
                                                    <tr>
                                                        <th scope="col"></th>

                                                        <th scope="col">Account</th>
                                                        <th scope="col">Date & Time</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='text-color'>
                                                        <th scope="row" ><FaGooglePay className='icon-wallet' /></th>
                                                        <th scope="row" className='color-style'>Payment from #2345</th>
                                                        <td>Jan 12,2022,2:20 pm</td>
                                                        <td>$10,000</td>
                                                        <td>Income</td>
                                                        <td>....</td>
                                                    </tr>
                                                    <tr className='text-color'>
                                                        <th scope="row"><SiVisa className='icon-wallet' /></th>
                                                        <th scope="row" className='color-style'>Payment from #2345</th>
                                                        <td>Jan 12,2022,2:20 pm</td>
                                                        <td>$10,000</td>
                                                        <td>Income</td>
                                                        <td>....</td>
                                                    </tr>
                                                    <tr className='text-color'>
                                                        <th scope="row"><FaGooglePay className='icon-wallet' /></th>
                                                        <th scope="row" className='color-style'>Payment from #2345</th>
                                                        <td>Jan 12,2022,2:20 pm</td>
                                                        <td>$10,000</td>
                                                        <td>Income</td>
                                                        <td>....</td>
                                                    </tr>
                                                    <tr className='text-color'>
                                                        <th scope="row"><SiVisa className='icon-wallet' /></th>
                                                        <th scope="row" className='color-style'>Payment from #2345</th>
                                                        <td>Jan 12,2022,2:20 pm</td>
                                                        <td>$10,000</td>
                                                        <td>Income</td>
                                                        <td>....</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div> */}
      </section>
    </main>
  );
};

export default Wallet;
