import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { img } from "../Components/Image";
import { loginRedux } from "../redux/Auth.slice";
import { replaceCart } from "../redux/Cart.slice";
import { loginUser } from "../services/users.service";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { getUserOrder } from "../redux/Order.slice";

import { generateFilePath } from "../utils/filepath";

const OrderDetail = () => {
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth);
  const userOrderArray = useSelector((state) => state.Order?.items);
  const [orderArr, setOrderArr] = useState([]);
  const [sortBy, setSortBy] = useState("");



  useEffect(() => {
    // console.log(isAuth, "isAuthisAuthisAuthisAuthisAuth");
    // console.log(sortBy, "sortBysortBysortBy");
    if (isAuth?.isAuthorised) {
      let query = {
        userId: isAuth?.user?._id,sort: sortBy
      };

      dispatch(getUserOrder( query));
    }
  }, [isAuth,sortBy]);

  useEffect(() => {
    // console.log(userOrderArray, "=====userOrderArray===");
    setOrderArr(userOrderArray);
  }, [userOrderArray]);




  return (
    <main className="border-top">
      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>Order Details</h4>
      </div>
      <section className="bgyellowlight ptb-80">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <div className="heading_order">
                <h2>Order</h2>
              </div>
            </div>
            <div className="col-lg-6 ">
              <form className="form form-style text-end">
                <select
                  onChange={(e) => setSortBy(e.target.value)}
                  value={sortBy}
                  aria-label="Sort By"
                  className="form-select"
                  aria-level="Sort By"
                >
                  <option value="Past 3 months">Past 3 months </option>
                  <option value="Past 6 months">Past 6 months </option>
                  <option value="Past 12 months">Past 12 months </option>
                  <option value="All Time">All Time</option>
                </select>
              </form>
            </div>
          </div>

          {orderArr &&
            orderArr.length > 0 ?
            orderArr.map((el, indx) => {
              return (
                <div
                  className="row justify-content-between mt-5 orderhistrcardnewtab"
                  key={indx}
                >
                  {el.productsArr &&
                    el.productsArr.length > 0 &&
                    el.productsArr.map((product, index) => {
                      return (
                        <div className="col-lg-5" key={index}>
                          <div className="orderdetial_cardsek">
                            <img
                              src={generateFilePath(product?.image)}
                              alt=""
                              className="img-fluid img-thumbnail"
                            />
                            <div className="cont-odr">
                              <h4>
                                {new Date(el?.createdAt)?.toDateString()}{" "}
                              </h4>
                              <h5>{product?.name}</h5>
                              <p className="qtyb">
                                Quantity: {product?.quantity}
                              </p>
                              <p className="price">{el?.currencyObj?.symbol} {product?.convertedPrice}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="col-lg-5">
                    <div className="btnoutlinegroup text-end">
                      <div>
                        <Link to="#" className="btn btn-vieworder">
                          View order
                        </Link>
                      </div>
                      <div className="mt-2">
                        <Link to="#" className="btn btn-vieworder">
                          Download invoice
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }):
            (
              <div>No Order found</div>
            )}

          {/* <div className="row justify-content-between mt-5 orderhistrcardnewtab">
            <div className="col-lg-5">
              <div className="orderdetial_cardsek">
                <img
                  src={img?.blo_gal}
                  alt=""
                  className="img-fluid img-thumbnail "
                />
                <div className="cont-odr">
                  <h4>Delivered on 17 Jan 2022</h4>
                  <h5>Pooja Thali Set for Home Temple, Festival</h5>
                  <p className="qtyb">Quantity: 2</p>
                  <p className="price">Rs. 4,898.00</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="btnoutlinegroup text-end">
                <div>
                  <Link to="#" className="btn btn-vieworder">
                    View order
                  </Link>
                </div>
                <div className="mt-2">
                  <Link to="#" className="btn btn-vieworder">
                    Download invoice
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <section className="oder-page bgyellowlight ptb-80">
        <div className='container'>
                    <div className='row order_boxfilterform'>
                        <div className='col-lg-3'>
                           <div className="heading_order">
                            <h2>Order</h2>
                           </div>
                        </div>
                        <div className='col-lg-3 text'>
                            <form className='form form-style'>
                                <select className='form-select' aria-level='Sort By'>
                                    <option value='Sort By'>Part 3 months </option>
                                    <option value='Top Seller'>Top Seller</option>
                                    <option value='New Arrival'>New Arrival</option>
                                    <option value='Alphabetically, A-Z'>Alphabetically, A-Z</option>
                                    <option value='Alphabetically, Z-A'>Alphabetically, Z-A</option>
                                    <option value='Price, low to high'>Price, low to high</option>
                                    <option value='Price, high to low'>Price, high to low</option>
                                    <option value='Date, New to old'>Date, New to old</option>
                                    <option value='Date, Old to new'>Date, Old to new</option>
                                </select>
                            </form>
                        </div>
                    </div>
                </div>

        <div className="container ">
          <div className="order_details">
            <div className="row order_box">
              <div className="col-lg-8 p-3 border-right">
                <div className="row">
                  <div className="col-lg-6 oder_detail_status">
                    <ul>
                      <li>
                        <p>
                          Order Id :<span> 3276778</span>
                        </p>
                        <p>
                          Order Date : <span> 2023-02-13 10:56:59 </span>
                        </p>
                        <p>
                          Tracking Id (Waybill No) : <span> 58127144515</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 oder_detail_status">
                    <ul>
                      <li>
                        <p>
                          Status : <span> placed</span>
                        </p>
                        <p>
                          Order Amount : <span>₹ 345</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row order_item">
                  <div className="col-2">
                    <img src={img.card1} className="img-fluid" alt="#" />
                  </div>
                  <div className="col-7">
                    <p> Product Without Disco ..</p>
                  </div>
                  <div className="col-3">
                    <p>
                      <span>2 X 16500</span>
                    </p>
                  </div>
                </div>
                <div className="row order_item">
                  <div className="col-2">
                    <img src={img.card1} className="img-fluid" alt="#" />
                  </div>
                  <div className="col-7">
                    <p> Product Without Disco ..</p>
                  </div>
                  <div className="col-3">
                    <p>
                      <span>2 X 16500</span>
                    </p>
                  </div>
                </div>
                <div className="row order_item">
                  <div className="col-2">
                    <img src={img.card1} className="img-fluid" alt="#" />
                  </div>
                  <div className="col-7">
                    <p> Product Without Disco ..</p>
                  </div>
                  <div className="col-3">
                    <p>
                      <span>2 X 16500</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="order_details_btn ">
                  <div className="order_btns">
                    <button className="download_btn">Download Invoice</button>
                  </div>

                  <div className="order_btns">
                    <button className="download_btn1">Track Order</button>

                    <button className="download_btn1">Order Details</button>

                    <button className="download_btn1">Cancel Order</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
};

export default OrderDetail;
