import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createOrder, getAllOrderByUserId } from "../services/order.service";

///////////async functions starts here

export const generateOrder = createAsyncThunk(
  "order/createOrder",
  async (obj) => {
    // console.log(obj,"obj--order - redux")
    const { data: response } = await createOrder(obj);
    // console.log(response, "response-order-redux---")
    return response;
  }
);

export const getUserOrder = createAsyncThunk("order/get", async (query) => {
  const { data: response } = await getAllOrderByUserId(query);
  // console.log(response,"======res")
  return response;
});

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const OrderSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateOrder.fulfilled, (state, action) => {
      state.itemsObj = action.payload;
    });

    builder.addCase(getUserOrder.fulfilled, (state, action) => {
        // console.log(action?.payload?.data ,"=====action")
      state.items = action?.payload?.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = OrderSlice.actions;

export default OrderSlice.reducer;
