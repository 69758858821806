import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFaqCategory } from "../services/FaqCategory.service";

// import {  getTestimonials,} from "../services/testimonial.service";

///////////async functions starts here

export const fetchFaqCategory = createAsyncThunk(
  "faqCategory/fetchFaqCategory",
  async (query) => {

    const { data: response } = await getFaqCategory(query);
    return response.data;
  }
);



///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const faqCategorySlice = createSlice({
  name: "faqCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFaqCategory.fulfilled, (state, action) => {
      state.items = action.payload;
    });

  },
});

export default faqCategorySlice.reducer;
