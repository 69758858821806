import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchAttributeData } from "../services/Attribute.service";

// import { getTestimonials, } from "../services/testimonial.service";

///////////async functions starts here
export const getAttributeData = createAsyncThunk(
  "attribute/fetchAttribute",
  async (query) => {
    const { data: response } = await fetchAttributeData();
    // console.log(response.data,"====getAttributeData =")
    return response.data;
  }
);
// export const getTestimonialsData = createAsyncThunk(
//   "testimonials/getTestimonials",
//   async (query) => {
//     // const { data: response } = await getTestimonials(query);
//     // return response.data;
//   }
// );


///////////async functions ends here

const initialState = {
  items: [],
  // items: [],
  itemsObj: {},
};

export const attributeSlice = createSlice({
  name: "Attribute",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAttributeData.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    // builder.addCase(getTestimonialsData.fulfilled, (state, action) => {
    //   // console.log(action.payload,"action getbyeproduct slug inreduc")
    //   state.itemsTestimonials = action.payload;
    // });


  },
});

export default attributeSlice.reducer;
