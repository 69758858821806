import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  get } from "../services/currency.service";

///////////async functions starts here
export const fetchCurrency = createAsyncThunk(
  "currency/getCurrency",
  async (query) => {
    // get("status=true");
    const { data: response } = await get(query);
    // console.log(response, "getcurrecny-redux")
    return response.data;
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const currencySlice = createSlice({
  name: "Currency",
  initialState,
  reducers: {},
  extraReducers: (builder)=>{
    builder.addCase(fetchCurrency.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default currencySlice.reducer;
