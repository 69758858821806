import { createSlice } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { removeLoggedInUser, removeToken, saveLoggedInUser, saveToken } from "../services/users.service"

const initialState = {
    token: "",
    user: undefined,
    isAuthorised: false,
    value: 0
}

export const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        loginRedux: (state, action) => {
            saveToken(action.payload.token);
            saveLoggedInUser(JSON.stringify(action.payload.user));
            state.token = action.payload.token
            state.user = action.payload.user
            state.isAuthorised = true;
        },
        logoutRedux: (state, action) => {
            removeToken()
            removeLoggedInUser()
            state.token = ""
            state.user = undefined
            state.isAuthorised = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { loginRedux, logoutRedux } = authSlice.actions

export default authSlice.reducer