import React, { useContext, useEffect, useState, useRef } from "react";
import { BsHeart } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { addOrRemoveWishlist } from "../redux/wishlist.slice";
import { generateFilePath } from "../utils/filepath";
import { toastError } from "../utils/toastUtils";

import { useDispatch, useSelector } from "react-redux";
import { cartContext, currencyContext } from "../App";
import { addItem, addProductToCart } from "../redux/Cart.slice";
import { getProductObjBySlug } from "../redux/Product.slice";
import { setUserData } from "../redux/userAddress.slice";
import { Autoplay } from "swiper";





const Product = () => {
  const [cartNumberOfProduct, setcartNumberOfProduct] = useContext(cartContext);
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  const isAuth = useSelector((state) => state.auth.isAuthorised);

  const cartArr = useSelector((state) => state.cart.items);
  const productData = useSelector((state) => state.product.itemsObj);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const [samagriProducts, setSamagriProducts] = useState([]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [productObject, setProductObject] = useState({});
  const [numberOfItem, setNumberOfItem] = useState(1);
  const userAuthorise = useSelector((state) => state.auth);
  const [arrivalProducts, setArrivalProducts] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageStr, setImageStr] = useState("");

  const swiper = useRef(null);

  const handleMinusNumberItems = () => {
    if (numberOfItem <= 1) {
      // toastError("Quanntity atleast be one")
    } else {
      setNumberOfItem(numberOfItem - 1);
    }
  };

  const percentageCalculation = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };

  useEffect(() => {
    if (productData && productData._id) {
      // console.log(productData, "productData get ");

      const minPrice = Math.min(
        ...productData.attributesArr?.map((obj) => {
          // console.log(obj.dealsObj?.productAr,"object234 get")
          if (obj.dealsObj && obj.dealsObj?.productArr?.length) {
            let discountPrice = obj.dealsObj?.productArr[0]?.discountValue;
            if (obj.dealsObj?.productArr[0]?.discountType != "AMOUNT_OFF") {
              const discountAmount = percentageCalculation(
                obj?.dealsObj?.productArr[0]?.discountValue,
                obj?.dealsObj?.productArr[0]?.sellingPrice
              );
              // console.log(discountAmount,"discountAmount get =-=-")

              discountPrice =
                obj.dealsObj?.productArr[0]?.sellingPrice - discountAmount;
              // console. log(discountPrice ,"discountPrice  get =-=-")
            } else {
              discountPrice =
                obj.dealsObj?.productArr[0]?.sellingPrice -
                obj.dealsObj?.productArr[0]?.discountValue;
              // console. log(discountPrice ,"discountPrice ===-=213 get =-=-")
            }
            return discountPrice;
          } else {
            return obj.price;
          }
        })
      );

      const index = productData.attributesArr?.findIndex((obj) => {
        if (obj.dealsObj && obj.dealsObj?.productArr?.length) {
          let discountPrice = obj.dealsObj?.productArr[0]?.discountValue;
          if (obj.dealsObj.productArr[0]?.discountType != "AMOUNT_OFF") {
            const discountAmount = percentageCalculation(
              obj.dealsObj.productArr[0]?.discountValue,
              obj.dealsObj.productArr[0]?.sellingPrice
            );
            discountPrice =
              obj.dealsObj?.productArr[0]?.sellingPrice - discountAmount;
          } else {
            discountPrice =
              obj.dealsObj?.productArr[0]?.sellingPrice -
              obj.dealsObj?.productArr[0]?.discountValue;
          }
          return discountPrice == minPrice;
        } else {
          return obj.price == minPrice;
        }
      });
      // console.log(index,"index get index =")
      // const minPrice = Math.min(
      //   ...productData.attributesArr.map((obj) => obj.price)
      // );
      // const index = productData.attributesArr.findIndex(
      //   (obj) => obj.price === minPrice
      // );
      setSelectedVariantIndex(index);
      setImageStr(productData?.imageArr[0].image)
      // productObject?.imageArr
      setProductObject(productData);
      setBestSellerProducts(productData?.relatedProductArr);
    }
  }, [productData]);

  const handleGetPrduct = async () => {
    try {
      dispatch(getProductObjBySlug(params.slug));
    } catch (err) {
      toastError(err);
    }
  };

  const activeBestSellerProductData = useSelector(
    (state) => state.product.itemsBestSeller
  );

  useEffect(() => {
    handleGetPrduct();
    window.scrollTo(0, 0);
  }, [params.slug]);

  

  const handleAddProductToCart = async (
    e,
    productObjects,
    similarProduct = false
  ) => {
    try {
      e.preventDefault();
      if (numberOfItem <= 0) {
        toastError("Please add atleast one product");
        return;
      }
      let trempObj = productObjects?.attributesArr?.find(
        (el, index) => index == selectedVariantIndex
      );
      // console.log(trempObj,"====trempObj=====")
      let obj={}
      if(similarProduct){
        obj = {
          id: productObjects._id,
          productId: productObjects._id,
          // productVariantId: trempObj?._id,
          // productVariantStock: trempObj?.stock,
          // productVariantName: trempObj?.name,
          // productVariantSellingPrice: productPrice,
          image:
            productObject?.imageArr &&
            productObject?.imageArr.length > 0 &&
            productObject?.imageArr[0]?.image,
          ...productObjects,
          quantity: numberOfItem,
          currencyObj: currencyDetails,
          variantObj: {
            variantId: trempObj?._id,
            currentStock: trempObj?.currentStock,
            name: trempObj?.name,
            price: trempObj?.price,///////////////////////////////////////////
          },
        };
      }else{
      // console.log(trempObj, "====trempObj....");
      // console.log(selectedVariantIndex, "====selectedVariantIndex....");
       obj = {
        id: productObjects._id,
        productId: productObjects._id,
        image:
          productObject?.imageArr &&
          productObject?.imageArr.length > 0 &&
          productObject?.imageArr[0]?.image,
        ...productObjects,
        quantity: numberOfItem,
        currencyObj: currencyDetails,
        variantObj: {
          variantId: trempObj?._id,
          currentStock: trempObj?.currentStock,
          name: trempObj?.name,
          price: productPrice,///////////////////////////////////////////
        },
      };
    }
  


    if (isAuth) {
      dispatch(addProductToCart(obj));
    } else {
      dispatch(addItem(obj));
    }
    handleGetPrduct();
      setNumberOfItem(1);
    } catch (err) {
      toastError(err);
    }
  };

  const activeSamagriProductData = useSelector(
    (state) => state.product.itemsSamagri
  );

  useEffect(() => {
    if (cartArr) {
      if (cartArr.length > 0) {
        // console.log(cartArr, "v")
        let tempValue = cartArr.find(
          (el) => el.productId == productObject?._id
        );
        // console.log(tempValue, "tempValue1")
        if (tempValue) {
          // console.log("tempValue")
          // setNumberOfItem(tempValue?.quantity);
        } else {
          setNumberOfItem(1);
        }
      }
      // addCartToLocalStorage(cartArr)
    }

    if (
      Array.isArray(productObject?.attributesArr) &&
      productObject?.attributesArr.length > 0
    ) {
      let amount = 0;

      //  amount = Math.min(
      //     ...productObject.attributesArr.map((obj) => obj.price)
      //   );

      amount = Math.min(
        ...productObject.attributesArr.map((obj) => {
          // console.log(obj.dealsObj?.productArr[0],"object234 get")
          if (obj.dealsObj && obj.dealsObj?.productArr?.length) {
            let discountPrice = obj.dealsObj?.productArr[0]?.discountValue;
            if (obj.dealsObj?.productArr[0]?.discountType != "AMOUNT_OFF") {
              const discountAmount = percentageCalculation(
                obj?.dealsObj?.productArr[0]?.discountValue,
                obj?.dealsObj?.productArr[0]?.sellingPrice
              );

              discountPrice =
                obj.dealsObj?.productArr[0]?.sellingPrice - discountAmount;
            } else {
              discountPrice =
                obj.dealsObj?.productArr[0]?.sellingPrice -
                obj.dealsObj?.productArr[0]?.discountValue;
            }
            return discountPrice;
          } else {
            return obj.price;
          }
        })
      );
      // console.log(amount, "amount get =-=-");
      setProductPrice(amount);
    }
  }, [cartArr, productObject]);

  const handleAddProductToWishlist = (e, object, similarProduct = false) => {
    try {
      e.preventDefault();
      if (similarProduct) {
        let obj = {
          productId: object?._id,
          productVariantId: object?.attributesArr[0]?._id,
          productVariantName: object?.attributesArr[0]?.name,
          productVariantSellingPrice: object?.attributesArr[0]?.price,
          userId: userAuthorise?.user?._id,
          productVariantStock: object?.attributesArr[0]?.currentStock,
        };
        dispatch(addOrRemoveWishlist(obj));
      } else {
        let trempObj = object?.attributesArr?.find(
          (el, index) => index == selectedVariantIndex
        );
        let obj = {
          productId: object?._id,
          productVariantId: trempObj?._id,
          productVariantName: trempObj?.name,
          productVariantSellingPrice: productPrice,
          userId: userAuthorise?.user?._id,
          productVariantStock: trempObj?.currentStock,
        };
        // console.log(obj, "--===obj");
        dispatch(addOrRemoveWishlist(obj));
        handleGetPrduct(); ////////////////////////////////////////
      }
    } catch (err) {
      toastError(err);
    }
  };

  const activeItemsArrivalProductData = useSelector(
    (state) => state.product.itemsArrival
  );

  useEffect(() => {
    if (activeItemsArrivalProductData && activeItemsArrivalProductData.length) {
      setArrivalProducts(activeItemsArrivalProductData);
    }
    if (activeSamagriProductData && activeSamagriProductData.length) {
      setSamagriProducts(activeSamagriProductData);
    }
    // if (activeBestSellerProductData) {
    //   setBestSellerProducts(activeBestSellerProductData);
    // }
  }, [
    activeBestSellerProductData,
    activeSamagriProductData,
    activeItemsArrivalProductData,
  ]);

  const handlePurchaseProduct = (obj) => {
    try {
      if (!userAuthorise.isAuthorised && userAuthorise.token == "") {
        toastError("Kindly Login to Proceed");
        return;
      }

      if (numberOfItem == 0) {
        toastError("Kindly Select quantity");
        return;
      }
      // console.log(productObject?.stock, "productObject?.stock234");
      if (numberOfItem > productObject?.stock) {
        toastError("Insufficient Stock");
        return;
      }

      let subTotal =
        // obj?.sellingPrice * numberOfItem * currencyDetails?.convertRate;
        productPrice * numberOfItem * currencyDetails?.convertRate;
      // let cartArr = cartArray.map((el) => {
      //   return { price: el?.price, quantity: el?.quantity, hsnObj: el?.hsnObj };
      // });

      let cartArr = [
        {
          // price: obj?.sellingPrice,
          price: productPrice,
          quantity: numberOfItem,
          hsnObj: obj?.hsnObj,
        },
      ];

      let object = {
        subTotal,
        shipping: 0,
        cartArr,
        // taxes,
        symbol: currencyDetails?.symbol,
      };
      // console.log(object,"object214321")
      navigate("/Shiping");
      dispatch(setUserData(object));
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeVariant = (e, el, index) => {
    try {
      // console.log(el, "get el ");
      e.preventDefault();
      setSelectedVariantIndex(index);
      let result = 0;
      if (el && el?.dealsObj && el?.dealsObj?.productArr?.length) {
        if (el?.dealsObj?.productArr[0]?.discountType == "AMOUNT_OFF") {
          result =
            el?.dealsObj?.productArr[0]?.sellingPrice -
            parseInt(el?.dealsObj?.productArr[0]?.discountValue);
        } else {
          result =
            el?.dealsObj?.productArr[0]?.sellingPrice -
            percentageCalculation(
              parseInt(el?.dealsObj?.productArr[0]?.discountValue),
              el?.dealsObj?.productArr[0]?.sellingPrice
            );
        }
        // console.log(result, " get resultresult");
        setProductPrice(result);
      } else {
        setProductPrice(el?.price);
      }
      // console.log(e.preventDefault(), "eeee", el, "elelele", index, "odex");
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    swiper?.current?.slideTo(selectedImageIndex);
  }, [selectedImageIndex]);

  return (
    <main className="border-top bgyellowlight">
      <section className="product_sec mt-5">
        <div className="container product">
          <div className="row ">
            <div className="row">
              <div className="col-lg-1">
                <div className="swiper_left">
                  {/* <Swiper
  direction="vertical"
  onSwiper={setThumbsSwiper}
  loop={false}
  spaceBetween={30}
  slidesPerView={5}
  freeMode={true}
  watchSlidesProgress={true}
  modules={[FreeMode, Navigation, Thumbs]}
  className="mySwiper"
>
  {productObject &&
    productObject._id &&
    productObject?.imageArr
      ?.slice(0)
      .reverse()
      .map((el, index) => {
        return (
          <SwiperSlide key={index}>
            <img
              src={generateFilePath(el?.image)}
              className="img-thumbnail"
              alt="..."
            />
          </SwiperSlide>
        );
      })}
</Swiper> */}

                  {/* <Swiper
                    direction="vertical"
                    onSwiper={setThumbsSwiper}
                    loop={false}
                    spaceBetween={30}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                  >
                    {productObject &&
                      productObject._id &&
                      productObject?.imageArr?.map((el, inde) => {
                        return (
                          <SwiperSlide key={inde}>
                            <img
                              src={generateFilePath(el?.image)}
                              className="img-thumbnail"
                              alt="..."
                            />
                          </SwiperSlide>
                        );
                      })}
                  </Swiper> */}

                  {/* <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    {productObject &&
                      productObject._id &&
                      productObject?.imageArr?.map((el, inde) => {
                        return (
                          <SwiperSlide key={inde}>
                            <img
                              src={generateFilePath(el?.image)}
                              className="img-thumbnail"
                              alt="..."
                            />
                          </SwiperSlide>
                        );
                      })}                      
                  </Swiper> */}

                  <div className="image-container">
                    {productObject &&
                      productObject._id &&
                      productObject?.imageArr?.map((el, index) => {
                        return (
                          <img
                            key={index}
                            src={generateFilePath(el?.image)}
                            className={`img-thumbnail ${
                              selectedImageIndex == index ? "selected" : ""
                            }`}
                            alt="..."
                            // onClick={() => setSelectedImageIndex(index)}
                            onClick={() => setImageStr(el?.image)}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="big_img_right">
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                    initialSlide={selectedImageIndex}
                  >
                    {/* {productObject &&
                      productObject._id &&
                      productObject?.imageArr
                        ?.slice(0)
                        ?.reverse()
                        .map((el, index) => {
                          return ( */}
                            {/* // <SwiperSlide key={index}> */}
                            <>
                              <img
                                // src={generateFilePath(el?.image)}
                                src={generateFilePath(imageStr)}
                                alt="product"
                                />
                              <div className="icon_heart">
                                <BsHeart
                                  className={`${
                                    productObject?.isWishlisted
                                      ? "colorOfWishlist"
                                      : ""
                                  }  `}
                                  onClick={(e) =>
                                    handleAddProductToWishlist(e, productObject)
                                  }
                                />
                              </div>
                            {/* </SwiperSlide> */}
                                 </>
                          {/* );
                        })} */}

                    {/* {productObject &&
                      productObject._id &&
                      productObject?.imageArr?.map((el, inde) => {
                        return (
                          <SwiperSlide key={inde}>
                            <img
                              src={generateFilePath(el?.image)}
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="icon_heart">
                              <BsHeart
                                className={`${
                                  productObject?.isWishlisted
                                    ? "colorOfWishlist"
                                    : ""
                                }  `}
                                onClick={(e) =>
                                  handleAddProductToWishlist(
                                    e,
                                    productObject._id
                                  )
                                }
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}  */}

                    {/* 
                    <SwiperSlide>
                      <img src={img.Rectangle103} className="img-fluid" />
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                      <img src={img.Rectangle105} className="img-fluid" />
                    </SwiperSlide>
                    */}
                  </Swiper>
                </div>
              </div>
              <div className="col-md-6 product-hh">
                <h3> {productObject?.name} </h3>
                <h6>
                  {currencyDetails?.symbol + " "}

                  {parseFloat(
                    productPrice * currencyDetails?.convertRate
                  ).toFixed(2)}

                  {/* {" "+ parseFloat(
                     prouctObject?.sellingPrice * currencyDetails?.convertRate
                  ).toFixed(2)} */}
                </h6>
                {/* <p>
                  {productObject?.description?.replace(/<\/?p>/gi, "")}
                
                </p> */}
                <div className="product_desc">
                  <span className="product_h7">Material:</span>
                  <span className="product_p7">
                    {/* {productObject?.material
                      ? productObject?.material?.replace(/<\/?p>/gi, "")
                      : "simply product material"} */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: productObject?.material,
                      }}
                    ></p>
                  </span>
                </div>
                <div className="product_desc">
                  <span className="product_h7">Breadth:</span>
                  <span className="product_p7">
                    {productObject?.product_dimension_width}{" "}
                    {productObject?.dimensionHeightType
                      ? productObject?.dimensionHeightType
                      : "cm"}
                  </span>
                </div>
                <div className="product_desc">
                  <span className="product_h7">Height:</span>
                  <span className="product_p7">
                    {productObject?.product_dimension_height}{" "}
                    {productObject?.dimensionHeightType
                      ? productObject?.dimensionHeightType
                      : "cm"}
                  </span>
                </div>
                {/* {console.log(productObject, "productObject get")} */}
                <div className="product-variants-container">
                  {/* <div className="size-products">SIZE</div> */}
                  {productObject &&
                    productObject.attributesArr &&
                    productObject.attributesArr.length > 0 &&
                    productObject.attributesArr.map((el, index) => {
                      return (
                        <div
                          className={`product-variant ${
                            index === selectedVariantIndex ? "selected" : ""
                          }`}
                          key={index}
                        >
                          <button
                            onClick={(e) => handleChangeVariant(e, el, index)}
                          >
                            {el?.name?.replace("-", " ")}
                          </button>
                        </div>
                      );
                    })}
                </div>

                {/* <div className="">
                  {productObject &&
                    productObject?.attributesArr &&
                    productObject?.attributesArr?.length > 0 &&
                    productObject?.attributesArr?.map((el, index) => {
                      return (
                        <div className="product_buy">
                          <button
                            key={index}
                            //  onClick={(e) => handleMinusNumberItems()}
                          >
                            {el?.name}
                          </button>
                        </div>
                      );
                    })}
                </div> */}
                {/* <div className="product_desc"><span className="product_h7">Diameter:</span><span className="product_p7">8,5 cm</span></div> */}
                <div className="product_button">
                  <div className="product_quantity">
                    <div className="product_btn1">
                      <button onClick={(e) => handleMinusNumberItems()}>
                        -
                      </button>
                    </div>
                    <div className="product_q">{numberOfItem} </div>
                    <div className="product_btn2">
                      <button
                        onClick={(e) => setNumberOfItem(numberOfItem + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <button
                    className="product_buy"
                    onClick={(e) => {
                      handlePurchaseProduct(productObject);
                      // if(numberOfItem==0){
                      //   toastError("Kindly Select quantity")
                      //   return
                      // }
                    }}
                  >
                    Buy Now
                  </button>

                  {/* <Link to="/Shopingcart" className="product_cart"> */}
                  <button
                    className="product_cart first"
                    onClick={(e) => {
                      handleAddProductToCart(e, productObject);
                    }}
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-categ">
            <div style={{ display: "inline", width: 700, padding: 30 }}>
              <Tabs defaultActiveKey="first" className="product_row2">
                <Tab eventKey="first" title="Description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productObject?.description,
                    }}
                  ></p>
                </Tab>

                <Tab eventKey="second" title="Specification">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productObject?.specification,
                    }}
                  ></p>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      <section className="containerpadding fontjost">
        <div className="category_section pt-50 pb-50">
          <div className="container-fluid">
            <div className="row headingbootm align-items-center">
              <div className="col-lg-6">
                <div className="heading_sedtion">
                  <h3>Similar Products</h3>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
                  </p> */}
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="viewall">
                  <Link to="/Category/All">View All</Link>
                </div>
              </div> */}
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={5}
                  loop
                  speed={2000}
                  modules={[Autoplay, Navigation]}
                  // navigation
                  autoplay
                >
                  {bestSellerProducts &&
                    bestSellerProducts.length > 0 &&
                    bestSellerProducts.map((el, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="product_category_box">
                            <div className="procutcate_img">
                              <Link to={`/Product/${el?.slug}`}>
                                <img
                                  src={generateFilePath(el?.imageArr[0]?.image)}
                                  className="img-fluid"
                                />
                              </Link>
                            </div>
                            <div className="info_caption">
                              <ul className="socal_icon">
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToWishlist(e, el, true)
                                    }
                                  >
                                    <i className="fa fa-heart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToCart(e, el,true)
                                    }
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </a>
                                </li>
                              </ul>
                              <h5>{el?.name}</h5>
                              {/* {console.log(el, "elelelel")}
                              <p className="price_product">
                                <span className="cutprice">
                                  {currencyDetails?.symbol}
                                  {parseFloat(
                                    (el?.mrp ? el?.mrp : 3) *
                                      currencyDetails?.convertRate
                                  ).toFixed(2)}{" "}
                                </span>{" "}
                                {currencyDetails?.symbol}
                                {parseFloat(
                                  el?.sellingPrice *
                                    currencyDetails?.convertRate
                                ).toFixed(2)}{" "}
                              </p> */}

                              <p className="price_product">
                                {el?.mrp && el?.sellingPrice ? (
                                  <>
                                    <span className="cutprice">
                                      {currencyDetails?.symbol + " "}
                                      {parseFloat(
                                        el?.mrp * currencyDetails?.convertRate
                                      ).toFixed(2) + " "}
                                    </span>
                                    {currencyDetails?.symbol + " "}
                                    {parseFloat(
                                      el?.sellingPrice *
                                        currencyDetails?.convertRate
                                    ).toFixed(2)}
                                  </>
                                ) : (
                                  el?.attributesArr &&
                                  el?.attributesArr.length > 0 && (
                                    <>
                                      {currencyDetails?.symbol}
                                      {parseFloat(
                                        Math.min(
                                          ...el.attributesArr.map(
                                            (obj) => obj.price
                                          )
                                        ) * currencyDetails?.convertRate
                                      ).toFixed(2)}{" "}
                                      --
                                      <br />
                                      {currencyDetails?.symbol + " "}
                                      {parseFloat(
                                        Math.max(
                                          ...el.attributesArr.map(
                                            (obj) => obj.price
                                          )
                                        ) * currencyDetails?.convertRate
                                      ).toFixed(2)}
                                    </>
                                  )
                                )}
                              </p>
                              {el?.rating && (
                                <p className="star_iocn">
                                  {[...Array(parseInt(el.rating))].map(
                                    (_, i) => (
                                      <i
                                        key={i}
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                  <span> &nbsp;{el?.rating} Reviews</span>
                                </p>
                              )}

                              {/* <p>
                                {" "}
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>{" "}
                             
                                <i
                                  className="fa fa-star graycolor"
                                  aria-hidden="true"
                                ></i>
                              </p> */}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="similarproduct_section pt-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title_sec text-center">
                <h3>Similar Product</h3>
              </div>
            </div>
            <div className="row py-5">
              <div className="col-lg-12 slider-sect">
                {productObject &&
                  productObject?.relatedProductArr?.length > 0 && (
                    <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                      spaceBetween={30}
                      loop={true}
                      speed={4000}
                      slidesPerView={4}
                    >
                      {productObject &&
                        productObject?.relatedProductArr?.length > 0 &&
                        productObject?.relatedProductArr?.map((el, inde) => {
                          return (
                            <SwiperSlide key={inde}>
                              <Link to={`/Product/${el?.slug}`}>
                                <div className="categor_slide">
                                  <img
                                    src={generateFilePath(
                                      el?.imageArr[0]?.image
                                    )}
                                    className="img-fluid new-style"
                                  />
                                
                                  <div className="info_slid">
                                    <h6> {el?.name} </h6>
                                    <p>
                                      {currencyDetails?.symbol}{" "}
                                      {el?.sellingPrice *
                                        (currencyDetails?.convertRate > 1
                                          ? currencyDetails?.convertRate
                                          : 1)}{" "}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
};
export default Product;
