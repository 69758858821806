import React,{useState} from 'react'
import { img } from '../Components/Image'
import { AiOutlineSearch } from "react-icons/ai";

const Checkout = () => {
   
    return (
        <>
            <section >

                <div className='search-style'>
                        <input  type='text'  />
                        </div>  
                        
                  
            </section>
        </>
    )
}

export default Checkout