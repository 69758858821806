import { RxCross2 } from "react-icons/rx";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";

import {
  fetchWishlist,
  removePRoductFromWishlist,
} from "../redux/wishlist.slice";

import React, { useState, useEffect, useContext } from "react";
import { img } from "../Components/Image";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsHeart } from "react-icons/bs";

import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Link, useParams } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { toastError, toastSuccess } from "../utils/toastUtils";
import { generateFilePath } from "../utils/filepath";

import {
  // getProductBySlug,
  getAllRelatedProductsBySlug,
  getActiveProducts,
} from "../services/Product1.service";

import { addToCart, addProductToCart, fetchcart } from "../redux/Cart.slice";

import { useDispatch, useSelector } from "react-redux";

import { addCartToLocalStorage } from "../utils/LocalStorageForProducts";
import { getProductObjBySlug } from "../redux/Product.slice";
import { cartContext, currencyContext } from "../App";

const Wishlist = () => {
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  const [userWishlistArr, setUserWishlistArr] = useState([]);
  const wishlistArr = useSelector((state) => state.wishlist.items);
  // const productData = useSelector(state => state.product.itemsObj)
  const params = useParams();
  const dispatch = useDispatch();

  const userAuthorise = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchWishlist(`userId=${userAuthorise?.user?._id}`));
  }, []);

  useEffect(() => {
    if (wishlistArr) {
      setUserWishlistArr(wishlistArr);
    }
  }, [wishlistArr]);

  const handleRemoveItemFromWishlist = (e, el) => {
    try {
      dispatch(removePRoductFromWishlist(el._id));
    } catch (err) {
      toastError(err);
    }
  };

  const handleAddProductsToCart = (e, productObj) => {
    try {
      // og(productObj, "idddddddd");
      e.preventDefault();
      let obj = {
        id: productObj?.productId,
        productId: productObj?.productId,
        productVariantId: productObj?.productVariantId,
        variantObj: {
          variantId: productObj?.productVariantId,
          currentStock: productObj?.productVariantStock,
          name: productObj?.productVariantName,
          price: productObj?.productVariantSellingPrice,
        },
        userId: userAuthorise?.user?._id,
        quantity: 1,
        currencyObj: currencyDetails,
        // userId:
      };

      dispatch(addProductToCart(obj));

      // setTimeout(() => {
      //   dispatch(fetchcart())
      // }, 1000)
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <>
      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>
          Wishlist <br />
          <Link
            className="btn btn-hover btn-custom"
            to={`/Category/All`}
            style={{ color: "#fff" }}
          >
            Continue Shopping
          </Link>
        </h4>
      </div>
      <main
        style={{ backgroundImage: `url(${img.astrology_bg})` }}
        className="main-bg border-top"
      >
        {/* <section className="wishlist-page h-auto my-5 shadow-none">
        <div className="container">
          <div className="col-lg-12  mx-auto text-center">
            <div className="title-sec">
              <h1 className="heading pb-0">Wishlist</h1>
            </div>
           
          </div>
        </div>
      </section> */}

        <section className="wishlist  bgyellowlight pt-50 pb-50">
          <div className="container">
            <div className="cart-page">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <thead className="t-style bg-white">
                    <tr className="times-roman">
                      <th scope="col">Product</th>
                      <th scope="col">Product Variant Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Add To Cart</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {console.log(userWishlistArr, "userWishlistArr")}
                  <tbody className="cart-page-product">
                    {userWishlistArr &&
                      userWishlistArr.length > 0 &&
                      userWishlistArr.map((el, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="cart-page-product-title">
                                <img
                                  // src={img.card1}
                                  src={generateFilePath(
                                    el?.image ? el?.image : img.card1
                                  )}
                                  className="img-thumbnail"
                                  alt="..."
                                />
                                <p className=" mb-0 flex-1">
                                  {el?.productName}
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className=" mb-0 flex-1">
                                {el?.productVariantName}
                              </p>
                            </td>

                            <td>
                              <p className="mb-0">
                                <h6>
                                  {currencyDetails?.symbol}
                                  {" " +
                                    parseFloat(
                                      el?.productVariantSellingPrice *
                                        currencyDetails?.convertRate
                                    ).toFixed(2)}{" "}
                                </h6>{" "}
                              </p>
                            </td>
                            <td>
                              <Link
                                to="/Wishlist"
                                className="btn btn-primary btn-bg"
                                onClick={(e) => {
                                  // handleAddProductsToCart(e, el?.productId);
                                  handleAddProductsToCart(e, el);
                                  handleRemoveItemFromWishlist(e, el);
                                }}
                              >
                                Add To Cart
                              </Link>
                            </td>
                            <td>
                              <div
                                className="icon"
                                onClick={(e) =>
                                  handleRemoveItemFromWishlist(e, el)
                                }
                              >
                                <RxCross2 />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {/* <tr>
                    <td>
                      <div className="cart-page-product-title">
                        <img
                          src={img.card2}
                          className="img-fluid"
                          alt="Daffadil - Wooden Printing Block"
                        />
                        <p className=" mb-0 flex-1">
                          Daffadil - Wooden Printing Block
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="mb-0">Rs. 380</p>
                    </td>
                    <td>
                      <Link to="/Category" className="btn btn-primary btn-bg">
                        Add
                      </Link>
                    </td>
                    <td>
                      <div className="icon">
                        <RxCross2 />
                      </div>
                    </td>
                  </tr> */}

                    {/* <tr>
                    <td>
                      <div className="cart-page-product-title">
                        <img
                          src={img.card3}
                          className="img-fluid"
                          alt="Daffadil - Wooden Printing Block"
                        />
                        <p className=" mb-0 flex-1">
                          Daffadil - Wooden Printing Block
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="mb-0">Rs. 380</p>
                    </td>
                    <td>
               
                      <Link to="/Category" className="btn btn-primary btn-bg">
                        Add
                      </Link>
                    </td>
                    <td>
                      <div className="icon">
                        <RxCross2 />
                      </div>
                    </td>
                  </tr> */}

                    {/* <tr>
                    <td>
                      <div className="cart-page-product-title">
                        <img
                          src={img.card1}
                          className="img-fluid"
                          alt="Daffadil - Wooden Printing Block"
                        />
                        <p className=" mb-0 flex-1">
                          Daffadil - Wooden Printing Block
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="mb-0">Rs. 380</p>
                    </td>
                    <td>
                     
                      <Link to="/Category" className="btn btn-primary btn-bg">
                        Add
                      </Link>
                    </td>
                    <td>
                      <div className="icon">
                        <RxCross2 />
                      </div>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Wishlist;
