import { Link, useNavigate, useParams } from "react-router-dom";
import { generateFilePath } from "../utils/filepath";
import { toastError } from "../utils/toastUtils";

import ReactPaginate from "react-paginate";
import React, { useContext, useEffect, useState } from "react";
import ShopFilter from "./ShopFilter";
import Banners from "./Banners";
import {
  getCategoryAndProductBySlug,
  getCategory,
} from "../services/category1.service";
import { currencyContext } from "../App";
import { addOrRemoveWishlist } from "../redux/wishlist.slice";
import { getArrivalActiveProductsArr } from "../redux/Product.slice";
import { useDispatch, useSelector } from "react-redux";
import { addItem, addProductToCart } from "../redux/Cart.slice";



const Category = () => {
  
  const navigate = useNavigate();
  const params = useParams();
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  const userAuthorise = useSelector((state) => state.auth);
  const isAuth = useSelector((state) => state?.auth?.isAuthorised);

  const dispatch = useDispatch();

  const [productArr, setProductArr] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [filter, setFilter] = useState("");
  const [sortby, setSortby] = useState(1);

  const [categoryArr, setCategoryArr] = useState([]);
  const [subCategroyArr, setSubCategroyArr] = useState([]);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [filterBreakPoint, setFilterBreakPoint] = useState(992);

  const dataPerPage = 9;
  const startIndex = page * dataPerPage;
  const endIndex = startIndex + dataPerPage;
  const [perPage, setPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);

  const handleGetCategory2 = async () => {
    try {
      let get = await getCategory(`level=1`);
      // console.log(get?.data?.data, "getcatrgoy");
      setCategoryArr(get?.data?.data);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    let sortBy = "";
    if (filter == "Top Seller") {
      setSortBy("Top Seller");
    } else if (filter == "New Arrival") {
      setSortBy("New Arrival");
    } else if (filter == "Alphabetically, A-Z") {
      setSortBy("A-Z");
    } else if (filter == "Alphabetically, Z-A") {
      setSortBy("Z-A");
    }

    // handlegetProducts();
    handleGetCategory2();
  }, []);

  const handlePageChange = (page, e) => {
    // console.log(page,"==page,e")
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // const handleAddProductToCart = async (e, productObject) => {
  // try{
  //   e.preventDefault();
  //   let obj = {
  //     id: productObject._id,
  //     price: productObject?.sellingPrice,
  //     image: productObject?.imageArr && productObject?.imageArr.length > 0 && productObject?.imageArr[0]?.image,
  //     productId: productObject._id,
  //     ...productObject,
  //     quantity: 1,
  //     currencyObj: currencyDetails,
  //   };

  //   if (isAuth) {
  //     dispatch(addProductToCart(obj));
  //   }
  //   else {
  //     dispatch(addItem(obj));
  //   }

  //     // e.preventDefault();
  //     // let obj = {
  //     //   id,
  //     //   quantity: 1,
  //     //   currencyObj: currencyDetails,
  //     // };
  //     // dispatch(addProductToCart(obj));

  //   }catch(err){
  //     toastError(err)
  //   }
  //   // dispatch(
  //   //   addItem({
  //   //     productId: productObject._id,
  //   //     quantity: numberOfItem,
  //   //     productObj: productObject,
  //   //   })
  //   // );
  // };



  const getPageButtonStyle = (pageNumber) => {
    // console.log(pageNumber,"pageNumber23")
    const baseStyle = {
      borderTopWidth: "0px",
      backgroundColor: "transparent",
      border: "solid 1px transparent",
    };
    if (page == pageNumber) {
      return {
        ...baseStyle,
        backgroundColor: "#ff9b44",
        color: "#fff",
      };
    } else {
      return baseStyle;
    }
  };


  useEffect(() => {
    // handleInit();
    window.scrollTo(0,0)
  }, [page]);
 
  const handlePageClick = (event) => {
    setPage(parseInt(event.selected) + 1);
  };

  const handleAddProductToCart = async (e, productObject) => {
    try {
      // console.log(productObject, "==-=productObject");
      e.preventDefault();
      let obj = {
        ...productObject,
        // price: productObject?.sellingPrice,
        image:
          productObject?.imageArr &&
          productObject?.imageArr.length > 0 &&
          productObject?.imageArr[0]?.image,
        id: productObject?._id,
        productId: productObject?._id,
        productVariantId: productObject?.attributesArr[0]?._id,
        variantObj: {
          variantId: productObject?.attributesArr[0]?._id,
          currentStock: productObject?.attributesArr[0]?.currentStock,
          name: productObject?.attributesArr[0]?.name,
          price: productObject?.attributesArr[0]?.price,
        },
        userId: userAuthorise?.user?._id,
        quantity: 1,
        currencyObj: currencyDetails,
         };

      if (isAuth) {
        dispatch(addProductToCart(obj));
      } else {
        dispatch(addItem(obj));
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleAddProductToWishlist = (e, el) => {
    try {
      // console.log(el, "====ekkekekekekekeklekl");
      e.preventDefault();
      let obj = {
        productId: el?._id,
        productVariantId: el?.attributesArr[0]?._id,
        productVariantName: el?.attributesArr[0]?.name,
        productVariantSellingPrice: el?.attributesArr[0]?.price,
        userId: userAuthorise?.user?._id,
        productVariantStock: el?.attributesArr[0]?.currentStock,
      };
      // console.log(obj, "boject234");
      dispatch(addOrRemoveWishlist(obj));
      dispatch(
        getArrivalActiveProductsArr(
          `productDisplay=arrival&userId=${userAuthorise?.user?._id}`
        )
      );
      // console.log(el, "eleleleel");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <main>
      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>Categories</h4>
      </div>

      <section
        className="shop-page ptb-50 containerpadding category_pages pt-50 "
        style={{
          position: "relative",
          paddingTop: "50px !important",
          paddingBottom: "50px !important",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-3">
              <ShopFilter
                categoryArrData={categoryArr}
                // selectedCategoryIdData={searchParams.get("categoryId")}
                setPage={setPage}
                page={page}
                setTotalPages={setTotalPages}
                totalPages={totalPages}
                setPerPage={setPerPage}
                perPage={perPage}
                setTotalCount={setTotalCount}
                totalCount={totalCount}
                selectedCategoryIdData={params.slug}
                sortByData={sortBy}
                subcategoryArrData={subCategroyArr}
                setProductArr={setProductArr}
                filterBreakPoint={filterBreakPoint}
              />
            </div>
            <div className="col-12 col-lg-8 col-xl-9">
              <div className="right">
                <div className="top">
                  <div className="row d-flex">
                    <div className="col-5">
                      <h1 className="title" style={{ fontSize: "32px" }}>
                        Categories
                      </h1>
                    </div>
                    <form
                      className="form"
                      style={{
                        marginLeft: "auto",
                        width: "25%",
                        padding: "10px 1pc",
                        minwidth: " 240px",
                        backgroundColor: "transparent",
                        border: "1px solid #000 !important",
                        color: "#000 !important",
                        borderRadius: "0",
                        "margin-bottom": "3px",
                      }}
                    >
                      <select
                        onChange={(e) => setSortBy(e?.target?.value)}
                        value={sortBy}
                        className="form-select"
                        aria-label="Sort By"
                      >
                        <option value={""}>Sort By</option>
                        {/* <option value="Top seller">Top seller</option>
                      <option value="New Arrival">New Arrival</option> */}
                        <option value="Alphabetically, A-Z">
                          Alphabetically, A-Z
                        </option>
                        <option value="Alphabetically, Z-A">
                          Alphabetically, Z-A
                        </option>
                        <option value="Price, low to high">
                          Price, low to high
                        </option>
                        <option value="Price, high to low">
                          Price, high to low
                        </option>
                        <option value="Date, New to old">
                          Date, New to old{" "}
                        </option>
                        <option value="Date, Old to new">
                          Date, Old to new{" "}
                        </option>
                      </select>
                    </form>
                  </div>
                </div>
                {/* {console.log(productArr," ==getproductArr24")} */}
                <div className="row gy-4">
                  {productArr && productArr?.length > 0 ? (
                    productArr?.map((el, index) => {
                      return (
                        <div
                          key={index}
                          className="col-12 col-sm-6 col-xl-3 rounded"
                          style={{ borderRadius: 10 }}
                        >
                          <div className="product_category_box">
                            <div className="procutcate_img">
                              <Link to={`/Product/${el?.slug}`}>
                                <img
                                  src={
                                    el?.imageArr &&
                                    el?.imageArr?.length > 0 &&
                                    el?.imageArr[0]?.image
                                      ? generateFilePath(el?.imageArr[0]?.image)
                                      : ""
                                  }
                                  className="img-fluid"
                                />
                              </Link>
                            </div>

                            <div className="offerview">
                              {el?.discountType == "FlatOff"
                                ? currencyDetails?.symbol +
                                  " " +
                                  parseFloat(
                                    el?.discountValue *
                                      currencyDetails?.convertRate
                                  ).toFixed(2)
                                : el?.discountType == "Percentage"
                                ? `${el?.discountValue}% off`
                                : ""}
                            </div>

                            <div className="info_caption">
                              <ul className="socal_icon">
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToWishlist(e, el)
                                    }
                                  >
                                    <i className="fa fa-heart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToCart(e, el)
                                    }
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </a>
                                </li>
                              </ul>
                              <h5>
                                <Link href={`/ShopDetail/${el?.slug}`}></Link>{" "}
                                {el?.name?.length > 30 ? el?.name?.substring(0, 30) + "..." : el?.name}</h5>
                             
                          
                              <h7> {el?.attributeName?.length>30?el?.attributeName?.substring(0,30)+"...":el?.attributeName}</h7>
                             

                              {/* <p className="price_product">
                                <span className="cutprice">
                                  {currencyDetails?.symbol}
                                  {parseFloat(
                                    el?.mrp * currencyDetails?.convertRate
                                  ).toFixed(2)}{" "}
                                </span>{" "}

                                {currencyDetails?.symbol}
                                {parseFloat(
                                  el?.sellingPrice *
                                  currencyDetails?.convertRate
                                ).toFixed(2)}{" "}
                              </p> */}
                         
                              <p className="price_product">
                                <>
                                  {currencyDetails?.symbol} &nbsp;
                                  {parseFloat(
                                    el?.price * currencyDetails?.convertRate
                                  ).toFixed(2)}
                                  <br />
                                </>
                             
                              </p>

                              {el?.rating && (
                                <p className="star_iocn">
                                  {[...Array(parseInt(el?.rating))].map(
                                    (_, i) => (
                                      <i
                                        key={i}
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                  &nbsp;{el?.rating} Reviews
                                </p>
                              )}
                              {/* <p>
                                {" "}
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>{" "}
                                <i className="fa fa-star" aria-hidden="true"></i>{" "}
                                <i className="fa fa-star" aria-hidden="true"></i>{" "}
                                <i
                                  className="fa fa-star graycolor"
                                  aria-hidden="true"
                                ></i>
                              </p> */}
                            </div>
                          </div>

                          {/* <div
                            className="product-box product_category_box"
                            style={{ borderRadius: 10 }}
                          >
                            <div className="image">
                              <Link to={`/Product/${el?.slug}`}>
                                <img
                                  style={{
                                    height: "306px",
                                    verticalAlign: "middle",
                                    objectFit: "cover",
                                    width: "98%",
                                  }}
                                  src={
                                    el.imageArr &&
                                    el.imageArr.length > 0 &&
                                    el.imageArr[0].image
                                      ? generateFilePath(el.imageArr[0].image)
                                      : ""
                                  }
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div
                              className="content box-bg px-3"
                              style={{
                                backgroundColor: "antiquewhite",
                                height: "75px",
                                width: "98%",
                              }}
                            >
                              <p className="text-white" style={{}}>
                                <Link to={`/ShopDetail/${el?.slug}`}>
                                  {el.name}
                                </Link>
                              </p>

                              <p
                                className="small text-secondary"
                                onClick={() =>
                                  navigate(`/ShopDetail/${el?.slug}`)
                                }
                              >
                                at ₹ {el?.sellingPrice}
                              </p>
                            </div>
                          </div> */}
                        </div>
                      );
                    })
                  ) : (
                    <div>No products found</div>
                  )}
                  <section className="product-pagination mb-50">
                    <div className="container">
                      {/* <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={totalCount}
            previousLabel="< previous"
            forcePage={isNaN(page) === true ? 1 : page - 1}
            renderOnZeroPageCount={null}
          /> */}

                      {/* <ReactPaginate
                     pageCount={5}
                   pageRangeDisplayed={5}
                   marginPagesDisplayed={2}
                   previousLabel={'Prev'}
                     nextLabel={'Next'}
                   breakLabel={'...'}
                   onPageChange={handlePageClick}
                   containerClassName={'pagination'}
                 activeClassName={'active'}
                /> */}
  {productArr && productArr?.length > 0 ? (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={(e) => {
                          setPage(e.selected + 1);
                        }}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                      />):""}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Category;
