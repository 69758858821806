import banner from '../assets/images/banner.png'
import card from '../assets/images/card.png'
import card1 from '../assets/images/card1.png'
import card2 from '../assets/images/card2.png'
import card3 from '../assets/images/card3.png'
import gal from '../assets/images/gal1.png'
import gal1 from '../assets/images/gal1.png'
import gal2 from '../assets/images/gal2.png'
import cat from '../assets/images/cat.png'
import mata from '../assets/images/mata.png'
import thali from '../assets/images/thali.png'
import gall from '../assets/images/gall.png'
import gall1 from '../assets/images/gall1.png'
import gall2 from '../assets/images/gall2.png'
import gall3 from '../assets/images/gall3.png'
import about_banner from '../assets/images/about_banner.png'
import about_puja from '../assets/images/puja_item.png'
import about_story from '../assets/images/about_story.png'
import logo from '../assets/images/logo.png'
import logo1 from '../assets/images/logo1.png'
import mission_pic from '../assets/images/mission_pic.png'
import mission_gal from '../assets/images/mission_gal.png'
import Group from '../assets/images/Group .png'
import author1 from '../assets/images/author1.png'
import author from '../assets/images/author.png'
import a from '../assets/images/a.png'
import blogimg from '../assets/images/blogimg.png'
import main_logo from '../assets/images/main_logo.png'
import blog_2 from '../assets/images/blog_2.png'
import blog_ab from '../assets/images/blog_ab.png'
import  blo_gal from '../assets/images/blo_gal.png'
import blog_icon from '../assets/images/blog_icon.png'
import category_img from '../assets/images/category_img.png'
import Rectangle103 from '../assets/images/Rectangle 103.png'
import Rectangle105 from '../assets/images/Rectangle105.png'
import Rectangle106 from '../assets/images/Rectangle106.png'
import Rectangle107  from '../assets/images/Rectangle107 .png'
import Rectangle108  from '../assets/images/Rectangle108 .png'
import Mask from '../assets/images/Mask .png'
import Frame4 from '../assets/images/Frame4.png'
import Frame2 from '../assets/images/Frame2.png'
import Frame3 from '../assets/images/Frame3.png'
import kanha from '../assets/images/kanha.png'
import diya from '../assets/images/diya.jpg'
import musical from '../assets/images/musical.jpg'
import puja from '../assets/images/puja.jpg'
import holy from '../assets/images/holy.jpg'
import borderimg  from '../assets/images/borderimg.png'
import borderwhite from '../assets/images/borderimgwhite.png'
import crileimg from '../assets/images/download-1.png'
import weblogo from '../assets/images/weblogo.png'
import loginbanner from '../assets/images/loginbanner.png'
import astrology_bg from '../assets/images/astrology-bg.png'
import about_bg from '../assets/images/about_bg.png'
import omshanti from '../assets/images/omshanti.jpg'
import Pooja from '../assets/images/Pooja.png'



import left_banner_img from '../assets/images/left_banner_img.jpg'
import right_banner_img from '../assets/images/right_banner_img.jpg'

import right_image from '../assets/images/right_image.jpg'

import left_image from '../assets/images/left_image.jpg'


import image_chair from '../assets/images/image_chair.png'

import product1 from '../assets/images/product1.jpg'

import pooja_product from '../assets/images/pooja_product.jpg'
import pooja_product2 from '../assets/images/pooja_product2.jpg'

import pooja_2 from '../assets/images/pooja_2.jpg'

import pooja_3 from '../assets/images/pooja_3.jpg'

import slider1 from '../assets/images/slider1.png'
import slider2 from '../assets/images/slider2.png'
import slider3 from '../assets/images/slider3.png'
import productimg1 from '../assets/images/bell_info.jpg'
import productcategroy from '../assets/images/adiyogi_img.png'
import hawanimg from "../assets/images/hawan_img.png"
import thaliimg from "../assets/images/thali_img.png"
import paymenticon1 from "../assets/images/freeshiping.svg"
import paymenticon2 from "../assets/images/money.svg"
import paymenticon3 from "../assets/images/online-support.svg"
import paymenticon4 from "../assets/images/payment.svg"
import oneimg from "../assets/images/oneimg.png"
import one2 from "../assets/images/one2.png"
import one3 from "../assets/images/one3.png"
import one4 from "../assets/images/one4.png"
import thali2 from "../assets/images/thali2.png"
import poojathalibottom from "../assets/images/poojathalibottom.png"
import dealsimg1 from "../assets/images/deals_img1.png"
import dealsimg2 from "../assets/images/deals_img2.png"
import googleicon from "../assets/images/app-store.png.png"
import appicon from "../assets/images/google-play.png101.png"
import banner_category from "../assets/images/banner_category.jpg"
import flag1 from "../assets/images/flag1.png"
import flag2 from "../assets/images/flag2.png"
import flag3 from "../assets/images/flag3.png"
import flag4 from "../assets/images/flag4.png"
import loginimg from "../assets/images/login-img1.jpg" 
import productimgcarddd  from "../assets/images/img-product-detil.png"

export const img ={
    productimgcarddd,
    loginimg,
    flag1,
    flag2,
    flag3,
    flag4,
    // flag5,
    googleicon,
    banner_category,
    appicon,
    dealsimg1,
    dealsimg2,
    poojathalibottom,
    thali2,
    one2,
    one3,
    one4,
    oneimg,
    paymenticon1,
    paymenticon2,
    paymenticon3,
    paymenticon4,

    thaliimg,
    hawanimg,
    productcategroy,
    productimg1,
    slider1,
    slider2,
    slider3,

    product1,
    pooja_3,
    right_image,
    pooja_2,
    pooja_product,
    pooja_product2,

    image_chair,
    left_image:left_image,
    right_image,right_image,
    Pooja:Pooja,
    right_banner_img,
    left_banner_img,

    omshanti:omshanti,
    weblogo:weblogo,
    loginbanner:loginbanner, about_bg,
    crileimg:crileimg,
    borderwhite: borderwhite,
    borderimg:borderimg,
    holy:holy,astrology_bg,
    musical:musical,
    puja:puja,
    diya:diya,
    kanha:kanha,
    Mask:Mask,
    Frame4:Frame4,
    Frame2:Frame2,
    Frame3:Frame3,
    Rectangle108 :Rectangle108,
    Rectangle103:Rectangle103,
    Rectangle105:Rectangle105,
    Rectangle106:Rectangle106,
    Rectangle107:Rectangle107,
    category_img:category_img,
    blog_icon:blog_icon,
    blo_gal:blo_gal,
    blog_ab:blog_ab,
    blog_2:blog_2,
    blogimg:blogimg,
    main_logo:main_logo,
    author1:author1,
    author:author,
    a:a,
    mission_pic:mission_pic,
    mission_gal:mission_gal ,
    about_story:about_story,
    about_banner:about_banner,
    about_puja:about_puja,
    logo :logo ,
    logo1:logo1,
    Group:Group,

banner:banner,
card :card,
card1 :card1,
card2 :card2,
card3: card3,
gal:gal,
gal1:gal,
gal2:gal2,
cat:cat,
mata:mata,
thali:thali,
gall:gall,
gall1:gall1,
gall2:gall2,
gall3:gall3,


}
