import { AiFillDelete } from "react-icons/ai";

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { generateFilePath } from "../utils/filepath";

import { useDispatch, useSelector } from "react-redux";
import { currencyContext } from "../App";
import { removeItem, removeItems } from "../redux/Cart.slice";
import { addLocalCart } from "../services/userCart.service";
import { toastError } from "../utils/toastUtils";

import {
  userAddressAdd,
  getUserAddress,
  DeleteUserAddress,
  setUserData,
  updateUserAddress,
} from "../redux/userAddress.slice";

const Shopingcart = () => {
  const navigate = useNavigate();
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  const dispatch = useDispatch();
  const cartArr = useSelector((state) => state.cart.items);
  const [shipping, setShipping] = useState(0);
  const [shippingTemp, setShippingTemp] = useState(0);

  const [taxes, setTaxes] = useState(0);
  const [taxesTemp, setTaxesTemp] = useState(0);

  const userAddressArr = useSelector((state) => state.userAddress.items);
  const userObj = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthorised);

  const [userAddressArray, setUserAddressArray] = useState([]);
  const [cartArray, setCartArray] = useState([]);

  const userIsLoggedIn = useSelector((state) => state.auth.isAuthorised);

  useEffect(() => {
    if (userIsLoggedIn) {
      if (userObj && userObj._id) {
        dispatch(getUserAddress(userObj._id));
      }
    }
  }, [userIsLoggedIn]);

  const handleRemoveProduct = (i) => {
    // console.log(i,"iiiii23423")
    if (isAuth) {
      dispatch(removeItems(i));
    }
    dispatch(removeItem(i));
  };

  // const handleCalculateSubTotal = () => {//old
  //   // if (userIsLoggedIn) {
  //     console.log(cartArr," get -=cartArr")
  //   return cartArr && cartArr.length > 0
  //     ? parseFloat(
  //         cartArr?.reduce(
  //           // (acc, el) => acc + el.productObj?.sellingPrice * el.quantity,
  //           (acc, el) =>
  //             acc + currencyDetails?.convertRate * el.price * el.quantity,
  //           0
  //         )
  //       ).toFixed(2)
  //     : 0;
  //   // } else {
  //   // return 0;
  //   // }
  // };

  //when we attributeArr then we display maximum amount
  const handleCalculateSubTotal = () => {
    // if (userIsLoggedIn) {
    // console.log(cartArr, " get -=cartArr");
    // console.log( cartArr?.reduce(
    //   (acc, el) =>
    //     acc + currencyDetails?.convertRate *
    //     ((el?.attributesArr&&el?.attributesArr.length>0)
    //     ? Math.max(...el.attributesArr.map((obj) => obj.price)) :
    //     el.price )* el.quantity ,
    //     0
    //     // console.log(acc,"get accccccc");
    // )," get  09=cartArr")

    return cartArr && cartArr.length > 0
      ? parseFloat(
          cartArr?.reduce(
            (acc, el) =>
              acc +
              currencyDetails?.convertRate *
                el?.variantObj?.price *
                el?.quantity,
            0
          )
        ).toFixed(2)
      : 0;
    // } else {
    // return 0;
    // }
  };

  //when we attributeArr then we display maximum amount
  const handleCalculateGrandTotal = () => {
    // if (userIsLoggedIn) {
    return cartArr && cartArr.length > 0
      ? parseFloat(
          cartArr?.reduce(
            (acc, el) =>
              acc +
              currencyDetails?.convertRate *
                el?.variantObj?.price *
                el.quantity,
            0
          ) + shipping
        ).toFixed(2)
      : // +  taxes
        0;
    // } else {
    //   return shipping;
    //   // + taxes;
    // }
  };

  useEffect(() => {
    if (userIsLoggedIn) {
      if (userAddressArr && userAddressArr.length >= 0) {
        setUserAddressArray(userAddressArr);
      }
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    // console.log(cartArr, "-=-=-= get cartArr4576567");
    if (cartArr && cartArr.length >= 0) {
      setCartArray(cartArr);
      let totalTax = cartArr.reduce(
        (axx, el) =>
          axx + el?.hsnObj?.cgst + el?.hsnObj?.sgst + el?.hsnObj?.igst,
        0
      );

      let taxCost = 0;
      if (currencyDetails?.convertRate) {
        taxCost = totalTax * parseInt(currencyDetails?.convertRate);
      }
      setTaxes(taxCost);
      setTaxesTemp(taxCost);
    }
  }, [cartArr, userIsLoggedIn, currencyDetails]);





  const handleSetCheckOutData = (e) => {
    try {
      if (!isAuth) {
        toastError("Please login to move forward !");
        return;
      }
      let subTotal = handleCalculateSubTotal();
      // console.log(subTotal,"==subTotal")
      // console.log(cartArray,"==cartArray")
      let cartArr = cartArray.map((el) => {
        return {
          productId: el?.productId,
          // price: el?.price,
          price: el?.variantObj?.price,
          quantity: el?.quantity,
          hsnObj: el?.hsnObj,
        };
      });
      let obj = {
        subTotal,
        shipping,
        cartArr,
        // taxes,
        symbol: currencyDetails?.symbol,
      };
      dispatch(setUserData(obj));////////////////////////////////////////////

      // let subTotal = handleCalculateSubTotal();
      // let cartArr = cartArray.map((el) => {
      //   return { price: el?.price, quantity: el?.quantity, hsnObj: el?.hsnObj };
      // });
      // let obj = {
      //   // subTotal,
      //   shipping,
      //   cartArr,
      //   // taxes,
      //   currencyId:currencyDetails?._id,
      //   symbol: currencyDetails?.symbol,
      // };
      // // console.log(obj,"obj-shoppingcart")
      // dispatch(setUserData(obj));
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <>
      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>My Shopping Cart</h4>
      </div>

      <div className="cartt-stylee">
        {/* <section>
          <div className="myshopping_cart">
            <div className="container">
              <div className="row py-5 text-center">
                <h3>My Shopping Cart</h3>
              </div>
            </div>
          </div>
        </section> */}

        <section className="bgyellowlight pt-50 pb-50">
          <div className="add_newsectiondcart">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="bar_cart active">
                    <span class="num active">1</span> Shopping Cart{" "}
                    <i className="fa fa-long-arrow-right"> </i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="bar_cart">
                    <span class="num">2</span> Payment & Delivery Options{" "}
                    <i className="fa fa-long-arrow-right"> </i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="bar_cart">
                    <span class="num">3</span> Order Received
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="shoping_gal">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <table className="cart_table">
                    <thead>
                      <tr>
                        <th>Images</th>
                        <th>Product</th>
                        <th>Product Variant Name </th>
                        <th>Price</th>
                        <th>Quantity</th>

                        <th colSpan={2}>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {console.log(cartArray, "-=-=cart get")} */}
                      {cartArray &&
                        cartArray.length > 0 &&
                        cartArray.map((el, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  src={generateFilePath(
                                    el?.image ? el?.image : ""
                                  )}
                                  className="img-fluid"
                                  alt="cart item"
                                />
                              </td>
                              <td>
                                <div className="title">{el?.name}</div>
                              </td>
                              <td>
                                <div className="title">{el?.variantObj?.name}</div>
                              </td>
                              <td>
                                {/* {console.log(currencyDetails,"currencyDetails?.symbol2314")} */}
                                {currencyDetails?.symbol}

                                {currencyDetails?.convertRate *
                                  el?.variantObj?.price}
                              </td>
                              <td className="quantity"> {el?.quantity} </td>
                              <td className="quantity">
                                {currencyDetails?.symbol
                                  ? currencyDetails?.symbol
                                  : " " + " "}

                                {currencyDetails?.convertRate *
                                  (el?.variantObj?.price * el.quantity)}
                              </td>

                              <td className="icon">
                                <AiFillDelete
                                  onClick={(e) =>
                                    handleRemoveProduct(el.productId)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-4">
                  <div className="row pb-4">
                    {/* <div className="col-lg-12 from_style">
                      {userAddressArray &&
                        userAddressArray.length > 0 &&
                        userAddressArray.map((el, index) => {
                          return (
                            <div className="edit_section_show  boxcard_ing" key={index}>
                              <div className="flex_heading_row">
                                <div className="Name_heading">
                                  <p className="infouseraduser"> {el?.address}</p>
                                </div>

                                <div className="readdress_info">
                                  <p className="inaddusernamei">
                                    {el?.state +
                                      " " +
                                      el?.city +
                                      " " +
                                      el?.country +
                                      " " +
                                      el?.pincode}
                                  </p>
                                  <p className="address-mobile">
                                   <span>Mobile  : </span> {el?.userPhone}
                                  </p>
                                  <p className="address-email">
                                   <span>Email : </span> {el?.userEmail}
                                  </p>
                                </div>

                                <div
                                  className="edit-section"
                                  style={{ display: "block" }}
                                >
                                  <div className="row">
                                    <div className="col-lg-6 text-center">
                                      <div className="edit_add">
                                        <Link
                                          to={`/MyProfile`}
                                          className="edit_address"
                                          onClick={(e) => {
                                            handleUserAddresUpdate(e, el);
                                          }}
                                        >
                                          EDIT <i class="fa fa-pencil-square-o" aria-hidden="true"></i>

                                        </Link>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                      <div
                                        className="remove_add delete_address_btn"
                                        onClick={(e) => {
                                          handleDeleteAddress(e, el);
                                        }}
                                      >
                                        REMOVE <i class="fa fa-trash-o" aria-hidden="true"></i>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    </div> */}
                    <div className="col-lg-12">
                      <div className="order">
                        <h6 className="order-txt">Order Summary</h6>
                        <div className="cart_sum">
                          Subtotal
                          <span>
                            {currencyDetails.symbol} {handleCalculateSubTotal()}
                          </span>
                        </div>
                        <div className="cart_sum">
                          Shipping
                          <span>
                            {currencyDetails.symbol} {shipping}
                          </span>
                        </div>
                        {/* <hr /> */}
                        {/* <div className="cart_sum">
                          Taxes
                          <span>
                            {currencyDetails.symbol} {taxes}
                          </span>
                        </div> */}
                        <hr />
                        <div className="cart_sum">
                          Order Total
                          <b>
                            {currencyDetails.symbol}
                            {handleCalculateGrandTotal()}
                          </b>
                        </div>
                        <div className="row">
                          <Link
                            // to={`/Shiping`}
                            to={`${isAuth ? "/Shiping" : ""} `}
                            className="col-12 text-center btn-style"
                            onClick={(e) => {
                              handleSetCheckOutData(e);
                            }}
                          >
                            Proceed to Checkout
                          </Link>
                        </div>
                      </div>
                    </div>

                    {/* <div className="container">
                      <div className="row d-flex justify-content-center">
                        <Link
                          className="btn-style col-10 mx-3 my-3 text-center"
                          to="/Shiping2"
                        >
                          Exiting Address
                        </Link>
                        <span style={{ textAlign: "center" }} className="mb-3">
                          OR
                        </span>
                        <Link
                          className="btn-style col-10 mx-3 text-center"
                          to="/Shiping"
                        >
                          Add New Address
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="shopig_part">
        <div className="container ">
          <div className="row">
            <div className="col-lg-4 shoping_item">
              <h3>Free Delivery</h3>
              <p>For all oders over $50, consectetur adipim scing elit.</p>
            </div>
            <div className="col-lg-4 shoping_item">
              <h3>90 Days Return</h3>
              <p>If goods have problems, consectetur adipim scing elit.</p>
            </div>
            <div className="col-lg-4  shoping_item">
              <h3>Secure Payment</h3>
              <p>100% secure payment, consectetur adipim scing elit.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Shopingcart;
