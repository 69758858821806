import React, { useState, useContext, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { img } from "../Components/Image";
import { toastError } from "../utils/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { loginUserContext } from "../App";

import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  fetchUserData,
  updateUserBasicInfoData,
  updateUserPassword,
} from "../redux/user.slice";

import {
  userAddressAdd,
  getUserAddress,
  DeleteUserAddress,
  updateUserAddress,
  setUserData,
} from "../redux/userAddress.slice";

import moment from "moment";

const MyProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
 
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const userObj = useSelector((state) => state.auth.user);
  const userCredentialObj = useSelector((state) => state.user.itemsObj);
  const userAddressArr = useSelector((state) => state.userAddress.items);

  const [loginDetails, setLoginDetails] = useContext(loginUserContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [userAddress, setUserAddress] = useState("");
  const [postelCode, setPostelCode] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [userAddressArray, setUserAddressArray] = useState([]);

  const [updateAddressObj, setUpdateAddressObj] = useState({});
  const [isUpdateDoc, setIsUpdateDoc] = useState(false);
  // const[firstName,setFirstName]=useState("")
  const addressObject = useSelector((state) => state.userAddress.itemsObj);

  const [tabs, setTabs] = useState([
    { name: "Basic Info", active: true, tab: 0 },
    { name: "Change Password", active: false, tab: 1 },
    { name: "Address", active: false, tab: 2 },
  ]);

  const handleInit = () => {
    try {
      dispatch(fetchUserData(userObj._id));
      dispatch(getUserAddress(userObj._id));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (userCredentialObj && userCredentialObj._id) {
      setFirstName(userCredentialObj?.firstName);
      setLastName(userCredentialObj?.lastName);
      setPhoneNumber(userCredentialObj?.phone);
      setEmail(userCredentialObj?.email)
      setDob(moment(userCredentialObj?.dob).format("MM-DD-YYYY"));
    }
  }, [userCredentialObj]);

  useEffect(() => {
    // console.log(userAddressArr, "userAddressArr234");
    if (userAddressArr && userAddressArr.length >= 0) {
      setUserAddressArray(userAddressArr);
    }
  }, [userAddressArr]);

  // useEffect(()=>{
  // // console.log(location,"locationlocation")
  // if(location.pathname!="/MyProfile"){
  //   dispatch()
  // }
  // },[location])

  useEffect(() => {
    handleInit();
  }, []);

  const handleChangePassword = (e) => {
    try {
      e.preventDefault();
      if (currentPassword == "") {
        toastError("Current Password is mandatory");
        return;
      } else if (newPassword == "") {
        toastError("New Password is mandatory");
        return;
      } else if (confirmNewPassword == "") {
        toastError("New Password is mandatory");
        return;
      } else if (newPassword != confirmNewPassword) {
        toastError("New Password and confirm password is not same");
        return;
      }
      let obj = {
        userId: userCredentialObj?._id,
        role: userCredentialObj?.role,
        currentPassword,
        password: newPassword,
        confirmNewPassword,
      };
      dispatch(updateUserPassword(obj));
    } catch (err) {
      toastError(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (firstName == "") {
        toastError("First Name is mandatory");
        return;
      } else if (lastName == "") {
        toastError("Last Name is mandatory");
        return;
      } else if (phoneNumber == "") {
        toastError("Phone number is mandatory");
        return;
      } else if (dob == "") {
        toastError("Date of Birth is mandatory");
        return;
      }
      let obj = {
        userId: userCredentialObj?._id,
        firstName,
        lastName,
        phone: phoneNumber,
        dob,
        role: userCredentialObj?.role,
      };
      // console.log(obj,"object from ")
      // console.log(obj,"object from ")
      dispatch(updateUserBasicInfoData(obj));
    } catch (err) {
      toastError(err);
    }
  };

  const clearFunc = () => {
    setUserAddress("");
    setCountry("");
    setState("");
    setCity("");
    setPostelCode("");
    setPopup(false);
    setIsUpdateDoc(false);
    setUpdateAddressObj(null);
    dispatch(setUserData(null));
  };

  const handleUserAddress = (e) => {
    try {
      e.preventDefault();
      if (userAddress == "") {
        toastError(" Address is mandatory");
        return;
      } else if (country == "") {
        toastError("country is mandatory");
        return;
      } else if (state == "") {
        toastError("State is mandatory");
        return;
      } else if (city == "") {
        toastError("city is mandatory");
        return;
      } else if (postelCode == "") {
        toastError("Postel Code is mandatory");
        return;
      }

      let obj = {
        userId: userCredentialObj?._id,
        pincode: postelCode,
        address: userAddress,
        country,
        state,
        city,
      };
      if (isUpdateDoc && updateAddressObj._id) {
        obj._id = updateAddressObj._id;
        // console.log(updateAddressObj, "updateAddressObj");
        dispatch(updateUserAddress(obj));
      } else {
        dispatch(userAddressAdd(obj));
      }
      // dispatch(getUserAddress(userObj._id));
      // console.log(obj, "bojectwerwer");
      clearFunc();
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteAddress = (e, el) => {
    try {
      // console.log(e, "eeeee", el, "elelelelel");
      // e.preventDefault()
      let obj = {
        userId: el?.userId,
        _id: el?._id,
      };
      dispatch(DeleteUserAddress(obj));
    } catch (err) {
      toastError(err);
    }
  };

  const activeQuoteTabs = (i) => {
    try {
      // console.log(i,"iiiiiii")
      let temp = tabs.map((el, index) => {
        // console.log(el,"elel")
        // console.log(index,"index")
        i == index ? (el.active = true) : (el.active = false);
        return el;
      });
      // console.log(temp,"tempo")
      setTabs(temp);
    } catch (err) {
      toastError(err);
    }
  };


  useEffect(() => {
    // console.log(addressObject,"addressObject234")
    if (addressObject && addressObject._id) {
      let temp = tabs.map((el, index) => {
        if (el.Address) {
          el.active = true;
        }
        return el;
      });

      setTabs(temp);
      setUpdateAddressObj(addressObject);
      // setUpdateAddressObj(el);
      // e.preventDefault();
      setUserAddress(addressObject?.address);
      setPostelCode(addressObject?.pincode);
      setCountry(addressObject?.country);
      setState(addressObject?.state);
      setCity(addressObject?.city);
      setPopup(true);
      // setEmail(addressObject?.email)
      setIsUpdateDoc(true);
    }
  }, [addressObject]);

  const handleUserAddresUpdate = (e, el) => {
    try {
      // console.log(e, "Ee231e", el, "elelele123");
      setUpdateAddressObj(el);
      e.preventDefault();

      setUserAddress(el?.address);
      setPostelCode(el?.pincode);
      setCountry(el?.country);
      setState(el?.state);
      setCity(el?.city);
      setPopup(true);
      setIsUpdateDoc(true);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <main
      style={{ backgroundImage: `url(${img.astrology_bg})` }}
      className="main-bg border-top"
    >
      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>My Profile</h4>
      </div>

      <section className="profile-style bgyellowlight ptb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3">
              <div className="list_itemmenu">
                <ul>
                  {tabs.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={`navbtn ${item.active ? "activebas" : ""}`}
                        onClick={() => activeQuoteTabs(index)}
                      >
                        {item?.name}  <i class="fa fa-chevron-right" aria-hidden="true"></i>

                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-9 myprofilebarea">
              <Tabs
                defaultActiveKey="first"
                className="product_row2 m-0 p-0"
              >
                <Tab eventKey="first" >
                  <div className="login-page myprofile_loginpages">
                    <div className="container">
                      <div className="row justify-content-center">
                        {tabs.map((el, index) => {
                          if (el.active && el.tab == 0) {
                            return (
                              <div className="col-lg-8" key={index}>
                                <div className="shad">
                                  <form>
                                    <label
                                      className="form-label"
                                      for="form1Example1"
                                    >
                                      First Name
                                    </label>
                                    <div className="form- outline mb-4">
                                      <input
                                        value={firstName}
                                        type="text"
                                        id="form1Example1"
                                        className="form-control"
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="form-outline mb-4">
                                      <label
                                        className="form-label"
                                        for="form1Example2"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        value={lastName}
                                        type="text"
                                        id="form1Example2"
                                        className="form-control"
                                        onChange={(e) =>
                                          setLastName(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="form-outline mb-4">
                                      <label
                                        className="form-label"
                                        for="form1Example2"
                                      >
                                        Email:
                                      </label>
                                      <input
                                        // maxLength={10}
                                        value={email}
                                        type="text"
                                        id="form1Example2"
                                        className="form-control"
                                        
                                      />
                                    </div>
                                    <div className="form-outline mb-4">
                                      <label
                                        className="form-label"
                                        for="form1Example2"
                                      >
                                        Phone Number
                                      </label>
                                      <input
                                        maxLength={10}
                                        value={phoneNumber}
                                        type="text"
                                        id="form1Example2"
                                        className="form-control"
                                        onChange={(e) =>
                                          setPhoneNumber(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="form-outline mb-4">
                                      <label
                                        className="form-label"
                                        for="form1Example2"
                                      >
                                        Date of Birth
                                      </label>
                                      <input
                                        value={dob}
                                        type="date"
                                        id="birthday"
                                        className="form-control"
                                        onChange={(e) =>
                                          setDob(e.target.value)
                                        }
                                      />
                                    </div>

                                    <center>
                                      <div className="form-outline mb-4 r-style">
                                        <button
                                          onClick={(e) => handleSubmit(e)}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </center>
                                  </form>
                                </div>
                              </div>
                            );
                          }

                          if (el.active && el.tab == 1) {
                            return (
                              <div className="login-page" key={index}>
                                <div className="container">
                                  <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                      <div className="shad">
                                        <form>
                                          <label
                                            className="form-label"
                                            for="form1Example1"
                                          >
                                            Current Password
                                          </label>
                                          <div className="form-outline mb-4">
                                            <input
                                              value={currentPassword}
                                              type="password"
                                              id="form1Example1"
                                              className="form-control"
                                              onChange={(e) =>
                                                setCurrentPassword(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-outline mb-4">
                                            <label
                                              className="form-label"
                                              for="form1Example2"
                                            >
                                              New Password
                                            </label>
                                            <input
                                              value={newPassword}
                                              type="password"
                                              id="form1Example2"
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewPassword(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-outline mb-4">
                                            <label
                                              className="form-label"
                                              for="form1Example2"
                                            >
                                              Re enter New Password
                                            </label>
                                            <input
                                              value={confirmNewPassword}
                                              type="password"
                                              id="form1Example2"
                                              className="form-control"
                                              onChange={(e) =>
                                                setConfirmNewPassword(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <center>
                                            <div className="form-outline mb-4 r-style">
                                              <button
                                                onClick={(e) => {
                                                  handleChangePassword(e);
                                                }}
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </center>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (el.active && el.tab == 2) {
                            return (
                              <>
                                <div className="col-lg-8" key={index}>
                                  <div className="col-lg-12 saveaddress saved-style align-items-center">
                                    <h2>Saved Address</h2>
                                  </div>
                                  <div className="row">
                                    {userAddressArray &&
                                      userAddressArray.length > 0 &&
                                      userAddressArray.map((el, index) => {
                                        return (
                                          <div
                                            className="col-12 col-lg-12"
                                            key={index}
                                          >
                                            <div className="addressAccordian-addressAccordian">
                                              <div className=" pdg edit_section_show bg-white">
                                                <div className="flex_heading_row">
                                                  <div className="Name_heading">
                                                    <p>{el?.address}</p>
                                                  </div>

                                                  <div className="readdress_info">
                                                    <p>{el?.state}, {el?.city}, {el?.country} </p>
                                                    {/* <p> {el?.city} </p>
                                                    <p> {el?.country}</p> */}
                                                    <p className="mb-2">
                                                      Pin Code :
                                                      {el?.pincode}
                                                    </p>
                                                    {/* <p className="address-mobile">
                                                      {" "}
                                                      Mobile :{" "}
                                                      {el?.userPhone}
                                                    </p> */}
                                                    {/* <p className="address-email">
                                                      {" "}
                                                      Email :{" "}
                                                      {el?.userEmail}
                                                    </p> */}
                                                  </div>

                                                  <div
                                                    className="edit-section btn-both"
                                                    style={{
                                                      display: "block",
                                                    }}
                                                  >
                                                    <div className="row">
                                                      <div className="col-lg-6 text-center">
                                                        <div className="edit_add">
                                                          <a
                                                            href="#"
                                                            className="edit_address"
                                                            onClick={(
                                                              e
                                                            ) => {
                                                              handleUserAddresUpdate(
                                                                e,
                                                                el
                                                              );
                                                            }}
                                                          >
                                                            EDIT
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-6 text-center">
                                                        <div
                                                          className="remove_add delete_address_btn"
                                                          onClick={(e) => {
                                                            handleDeleteAddress(
                                                              e,
                                                              el
                                                            );
                                                          }}
                                                        >
                                                          REMOVE
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>

                                  <center>
                                    <div className="form-outline mb-4 newadd-style">
                                      <button
                                        onClick={() => setPopup(!popup)}
                                      >
                                        Add New Address
                                      </button>
                                    </div>
                                  </center>
                                </div>
                                <form
                                  className={`address_form addnewaddress ${popup
                                      ? "show_address"
                                      : "hidden_address"
                                    }`}
                                >
                                  <div className="row">
                                    <div className="form_row  ">
                                      <div className="col-lg-6">
                                        <div className="form-group input_div">
                                          <lable for="address_name">
                                            Address
                                          </lable>
                                          <input
                                            value={userAddress}
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder="address"
                                            onChange={(e) =>
                                              setUserAddress(e.target.value)
                                            }
                                          />
                                          <span
                                            className="text-red"
                                            id="error-name"
                                          ></span>
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="form-group input_div">
                                          <label>
                                            Country
                                            <span className="text-red"></span>
                                          </label>
                                          <input
                                            value={country}
                                            type="text"
                                            className="form-control"
                                            name="country"
                                            placeholder="country"
                                            onChange={(e) =>
                                              setCountry(e.target.value)
                                            }
                                          />
                                          {/* <select
                                  className="form-control nc_select"
                                  name="country"
                                  onChange={(e) => setCountry(e.target.value)}
                                >
                                  <option value>India</option>
                                  <option value="India">India</option>
                                  <option value="America">America</option>
                                  <option value="London">London</option>
                                </select> */}
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="form-group input_div">
                                          <label>
                                            State
                                            <span className="text-red"></span>
                                          </label>
                                          <input
                                            value={state}
                                            type="text"
                                            className="form-control"
                                            name="state"
                                            placeholder="state"
                                            onChange={(e) =>
                                              setState(e.target.value)
                                            }
                                          />
                                          {/* <select
                                  className="form-control nc_select"
                                  name="country"
                                  onChange={(e) => setState(e.target.value)}
                                >
                                  <option value>Delhi</option>
                                  <option value="Andhra Pradesh1">
                                    Andhra Pradesh
                                  </option>
                                  <option value="Madhya Pradesh">
                                    Madhya Pradesh
                                  </option>
                                  <option value="Uttar Pradesh">
                                    Uttar Pradesh
                                  </option>
                                </select> */}
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="form-group input_div">
                                          <label>
                                            City
                                            <span className="-red"></span>
                                          </label>
                                          <input
                                            value={city}
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            placeholder="city"
                                            onChange={(e) =>
                                              setCity(e.target.value)
                                            }
                                          />
                                          {/* <select
                                  className="form-control nc_select"
                                  name="country"
                                  onChange={(e) => setCity(e.target.value)}
                                >
                                  <option value>Select from option</option>
                                  <option value="Noida">Noida</option>
                                  <option value="Delhi">Delhi</option>
                                  <option value="Kolkata">Kolkata</option>
                                </select> */}
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="form-group input_div">
                                          <label>
                                            Pin Code
                                            <span className="text-red"></span>
                                          </label>
                                          <input
                                            value={postelCode}
                                            onChange={(e) =>
                                              setPostelCode(e.target.value)
                                            }
                                            type="text"
                                            className="form-control"
                                            name="pastal code"
                                            placeholder="pastal code"
                                          />
                                          <span className="text-red"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <center>
                                    <div className="form-outline newadd-style">
                                      <button
                                        onClick={(e) => {
                                          handleUserAddress(e);
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </center>
                                </form>
                              </>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </Tab>
                {/* {tabs[2]?.active == true && (
                      <>
                        
                      </>
                    )} */}
                {/* </Tab> */}
              </Tabs>
            </div>

            <div className="col-lg-8"></div>
            <div className="col-lg-8 col-xl-9 col-md-7">
              <div className="account-details justify-content-center">
                <div>
                  {/* <Tabs
                    defaultActiveKey="first"
                    className="product_row2 m-0 p-0"
                  >
                    <Tab eventKey="first" title="Basic Info">
                      <div className="login-page pt-5">
                        <div className="container">
                          <div className="row justify-content-center">
                            {tabs.map((el, index) => {
                              if (el.active && el.tab == 0) {
                                return (
                                  <div className="col-lg-8" key={index}>
                                    <div className="shad">
                                      <form>
                                        <label
                                          className="form-label"
                                          for="form1Example1"
                                        >
                                          First Name
                                        </label>
                                        <div className="form- outline mb-4">
                                          <input
                                            value={firstName}
                                            type="text"
                                            id="form1Example1"
                                            className="form-control"
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                          />
                                        </div>

                                        <div className="form-outline mb-4">
                                          <label
                                            className="form-label"
                                            for="form1Example2"
                                          >
                                            Last Name
                                          </label>
                                          <input
                                            value={lastName}
                                            type="text"
                                            id="form1Example2"
                                            className="form-control"
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="form-outline mb-4">
                                          <label
                                            className="form-label"
                                            for="form1Example2"
                                          >
                                            Phone Number
                                          </label>
                                          <input
                                            maxLength={10}
                                            value={phoneNumber}
                                            type="text"
                                            id="form1Example2"
                                            className="form-control"
                                            onChange={(e) =>
                                              setPhoneNumber(e.target.value)
                                            }
                                          />
                                        </div>

                                        <div className="form-outline mb-4">
                                          <label
                                            className="form-label"
                                            for="form1Example2"
                                          >
                                            Date of birth
                                          </label>
                                          <input
                                            value={dob}
                                            type="date"
                                            id="birthday"
                                            className="form-control"
                                            onChange={(e) =>
                                              setDob(e.target.value)
                                            }
                                          />
                                        </div>

                                        <center>
                                          <div className="form-outline mb-4 r-style">
                                            <button
                                              onClick={(e) => handleSubmit(e)}
                                            >
                                              Update
                                            </button>
                                          </div>
                                        </center>
                                      </form>
                                    </div>
                                  </div>
                                );
                              }

                              if (el.active && el.tab == 1) {
                                return (
                                  <div className="login-page py-5" key={index}>
                                    <div className="container">
                                      <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                          <div className="shad">
                                            <form>
                                              <label
                                                className="form-label"
                                                for="form1Example1"
                                              >
                                                Current Password
                                              </label>
                                              <div className="form-outline mb-4">
                                                <input
                                                  value={currentPassword}
                                                  type="password"
                                                  id="form1Example1"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setCurrentPassword(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="form-outline mb-4">
                                                <label
                                                  className="form-label"
                                                  for="form1Example2"
                                                >
                                                  New Password
                                                </label>
                                                <input
                                                  value={newPassword}
                                                  type="password"
                                                  id="form1Example2"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setNewPassword(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="form-outline mb-4">
                                                <label
                                                  className="form-label"
                                                  for="form1Example2"
                                                >
                                                  Re enter New Password
                                                </label>
                                                <input
                                                  value={confirmNewPassword}
                                                  type="password"
                                                  id="form1Example2"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setConfirmNewPassword(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <center>
                                                <div className="form-outline mb-4 r-style">
                                                  <button
                                                    onClick={(e) => {
                                                      handleChangePassword(e);
                                                    }}
                                                  >
                                                    Update
                                                  </button>
                                                </div>
                                              </center>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                              if (el.active && el.tab == 2) {
                                return (
                                  <>
                                    <div className="col-lg-12" key={index}>
                                      <div className="col-lg-6 saved-style align-items-center">
                                        <h2>Saved Address</h2>
                                      </div>
                                      <div className="row">
                                        {userAddressArray &&
                                          userAddressArray.length > 0 &&
                                          userAddressArray.map((el, index) => {
                                            return (
                                              <div
                                                className="col-12 col-lg-6"
                                                key={index}
                                              >
                                                <div className="addressAccordian-addressAccordian">
                                                  <div className=" pdg edit_section_show bg-white">
                                                    <div className="flex_heading_row">
                                                      <div className="Name_heading">
                                                        <p>{el?.address}</p>
                                                      </div>

                                                      <div className="readdress_info">
                                                        <p>{el?.state} </p>
                                                        <p> {el?.city} </p>
                                                        <p> {el?.country}</p>
                                                        <p className="mb-2">
                                                          Pin Code :
                                                          {el?.pincode}
                                                        </p>
                                                        <p className="address-mobile">
                                                          {" "}
                                                          Mobile :{" "}
                                                          {el?.userPhone}
                                                        </p>
                                                        <p className="address-email">
                                                          {" "}
                                                          Email :{" "}
                                                          {el?.userEmail}
                                                        </p>
                                                      </div>

                                                      <div
                                                        className="edit-section"
                                                        style={{
                                                          display: "block",
                                                        }}
                                                      >
                                                        <div className="row">
                                                          <div className="col-lg-6 text-center">
                                                            <div className="edit_add">
                                                              <a
                                                                href="#"
                                                                className="edit_address"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  handleUserAddresUpdate(
                                                                    e,
                                                                    el
                                                                  );
                                                                }}
                                                              >
                                                                Edit
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="col-lg-6 text-center">
                                                            <div
                                                              className="remove_add delete_address_btn"
                                                              onClick={(e) => {
                                                                handleDeleteAddress(
                                                                  e,
                                                                  el
                                                                );
                                                              }}
                                                            >
                                                              REMOVE
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>

                                      <center>
                                        <div className="form-outline mb-4 newadd-style">
                                          <button
                                            onClick={() => setPopup(!popup)}
                                          >
                                            Add New Address
                                          </button>
                                        </div>
                                      </center>
                                    </div>
                                    <form
                                      className={`address_form ${
                                        popup
                                          ? "show_address"
                                          : "hidden_address"
                                      }`}
                                    >
                                      <div className="row">
                                        <div className="form_row  ">
                                          <div className="col-lg-6">
                                            <div className="form-group input_div">
                                              <lable for="address_name">
                                                Address
                                              </lable>
                                              <input
                                                value={userAddress}
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                placeholder="address"
                                                onChange={(e) =>
                                                  setUserAddress(e.target.value)
                                                }
                                              />
                                              <span
                                                className="text-red"
                                                id="error-name"
                                              ></span>
                                            </div>
                                          </div>

                                          <div className="col-lg-3">
                                            <div className="form-group input_div">
                                              <label>
                                                Country
                                                <span className="text-red"></span>
                                              </label>
                                              <input
                                                value={country}
                                                type="text"
                                                className="form-control"
                                                name="country"
                                                placeholder="country"
                                                onChange={(e) =>
                                                  setCountry(e.target.value)
                                                }
                                              />
                                              
                                            </div>
                                          </div>

                                          <div className="col-lg-3">
                                            <div className="form-group input_div">
                                              <label>
                                                State
                                                <span className="text-red"></span>
                                              </label>
                                              <input
                                                value={state}
                                                type="text"
                                                className="form-control"
                                                name="state"
                                                placeholder="state"
                                                onChange={(e) =>
                                                  setState(e.target.value)
                                                }
                                              />
                                             
                                            </div>
                                          </div>

                                          <div className="col-lg-3">
                                            <div className="form-group input_div">
                                              <label>
                                                City
                                                <span className="-red"></span>
                                              </label>
                                              <input
                                                value={city}
                                                type="text"
                                                className="form-control"
                                                name="city"
                                                placeholder="city"
                                                onChange={(e) =>
                                                  setCity(e.target.value)
                                                }
                                              />
                                              
                                            </div>
                                          </div>

                                          <div className="col-lg-3">
                                            <div className="form-group input_div">
                                              <label>
                                                Postal Code
                                                <span className="text-red"></span>
                                              </label>
                                              <input
                                                value={postelCode}
                                                onChange={(e) =>
                                                  setPostelCode(e.target.value)
                                                }
                                                type="text"
                                                className="form-control"
                                                name="pastal code"
                                                placeholder="pastal code"
                                              />
                                              <span className="text-red"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <center>
                                        <div className="form-outline newadd-style">
                                          <button
                                            onClick={(e) => {
                                              handleUserAddress(e);
                                            }}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </center>
                                    </form>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </Tab>
                    {/* {tabs[2]?.active == true && (
                      <>
                        
                      </>
                    )} 
                    
                  </Tabs> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MyProfile;
