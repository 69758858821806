import React, { useEffect, useState, useContext } from "react";
import {
  AiOutlineEyeInvisible,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../node_modules/swiper/swiper-bundle.css";
import { img } from "../Components/Image";
import { generateFilePath } from "../utils/filepath";
import { toastError } from "../utils/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchcart } from "../redux/Cart.slice";
import { addOrRemoveWishlist } from "../redux/wishlist.slice";
import { Autoplay, EffectFade, Pagination } from "swiper";
import Carousel from "react-bootstrap/Carousel";
import {
  getProductObjBySlug,
  getSamagriActiveProductsArr,
  getbestsellerActiveProductsArr,
  getArrivalActiveProductsArr,
} from "../redux/Product.slice";
import { fetchcategory } from "../redux/Category.slice";
import { fetchGallery } from "../redux/Gallery.slice";
import { fetchBanner } from "../redux/Banner.slice";
import { productCategoryObjectContext } from "../App";
// import { BsArrowRight } from 'react-icons/fa';
import { BsArrowRight } from "react-icons/bs";

import Banners from "./Banners";

const Index = () => {

  
  const [tabs, setTabs] = useState([
    {
      name: "View All",
      active: true,
      data: [
        {
          img: img.pooja_2,
          img2: img.pooja_3,
          title: "Bend Goods Farmhouse Chair",
          review: "₹₹₹₹₹₹",
          price: "₹ 300",
        },

        {
          img: img.pooja_2,
          img2: img.pooja_3,
          title: "Bend Goods Farmhouse Chair",
          review: "review",
          price: "₹ 300",
        },
        {
          img: img.pooja_2,
          img2: img.pooja_3,
          title: "Bend Goods Farmhouse Chair",
          review: "review",
          price: "₹ 300",
        },
      ],
    },
    {
      name: "Best Sellers",
      active: false,
      data: [
        {
          img: img.pooja_2,
          img2: img.product1,
          title: "Bend Goods Farmhouse Chair",
          review: "review",
          price: "₹ 300",
        },
        {
          img: img.pooja_2,
          img2: img.pooja_3,
          title: "Bend Goods Farmhouse Chair",
          review: "review",
          price: "₹ 300",
        },
      ],
    },

    {
      name: "Featured Products",
      active: false,
      data: [
        {
          img: img.pooja_2,
          img2: img.pooja_3,
          title: "Bend Goods Farmhouse Chair",
          review: "review",
          price: "₹ 500",
        },
        {
          img: img.pooja_2,
          img2: img.pooja_3,
          title: "Bend Goods Farmhouse Chair",
          review: "review",
          price: "₹ 300",
        },
      ],
    },
  ]);
 

  const [currentTab, setCurrentTab] = useState(tabs[0].data);

  const tabClick = (i) => {
    const temp = tabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setCurrentTab(temp[i].data);
    setTabs([...temp]);
  };

  // -------------above code yatendra---------

  const [productCategoryObj, setproductCategoryObj] = useContext(
    productCategoryObjectContext
  );

  const [bannerObj, setBannerObj] = useState({});
  const [activeProducts, setActiveProducts] = useState([]);
  const [samagriProducts, setSamagriProducts] = useState([]);

  const [testimonialsArr, setTestimonialsArr] = useState([]);
  const [arrivalProducts, setArrivalProducts] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const [gallaryArr, setGallaryArr] = useState([]);

  const isAuth = useSelector((state) => state.auth.isAuthorised);
  const userAuthorise = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.category.items);
  const galleryData = useSelector((state) => state.gallery.items);
  const bannerData = useSelector((state) => state.banner.items);

  const activeBestSellerProductData = useSelector(
    (state) => state.product.itemsBestSeller
  );
  const activeSamagriProductData = useSelector(
    (state) => state.product.itemsSamagri
  );
  const activeItemsArrivalProductData = useSelector(
    (state) => state.product.itemsArrival
  );

  useEffect(() => {
    // console.log(cart, "cartObj.items")
    if (isAuth) {
      dispatch(fetchcart());
    }
    // }
  }, [isAuth]);

  useEffect(() => {
    if (activeItemsArrivalProductData && activeItemsArrivalProductData.length) {
      // console.log(
      //   activeItemsArrivalProductData,
      //   "activeItemsArrivalProductData"
      // );
      setArrivalProducts(activeItemsArrivalProductData);
    }
    if (activeSamagriProductData && activeSamagriProductData.length) {
      setSamagriProducts(activeSamagriProductData);
    }
    if (activeBestSellerProductData) {
      setBestSellerProducts(activeBestSellerProductData);
    }
  }, [
    activeBestSellerProductData,
    activeSamagriProductData,
    activeItemsArrivalProductData,
  ]);

  useEffect(() => {
    if (categoryData && categoryData.length) {
      setCategoryArr(categoryData);
    }
  }, [categoryData]);

  useEffect(() => {
    if (galleryData && galleryData.length) {
      setGallaryArr(galleryData);
    }
  }, [galleryData]);

  useEffect(() => {
    if (bannerData && bannerData._id) {
      setBannerObj(bannerData);
    }
  }, [bannerData]);

  const handleInit = () => {
    dispatch(
      getArrivalActiveProductsArr(
        `productDisplay=arrival&userId=${userAuthorise?.user?._id}`
      )
    );
    dispatch(getSamagriActiveProductsArr("productDisplay=Samagri"));
    dispatch(getbestsellerActiveProductsArr("productDisplay=bestseller"));
    dispatch(fetchBanner("status=true"));
    dispatch(fetchcategory("level=1"));
    dispatch(fetchGallery());
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleAddProductToWishlist = (e, el) => {
    try {
      e.preventDefault();

      let obj = {
        productId: el._id,
        userId: userAuthorise?.user?._id,
      };
      // console.log(obj,"boject234")

      dispatch(addOrRemoveWishlist(obj));
      dispatch(
        getArrivalActiveProductsArr(
          `productDisplay=arrival&userId=${userAuthorise?.user?._id}`
        )
      );
      // console.log(el, "eleleleel");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      {/*  */}

      <div className="homeslider">
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          loop
          speed={2000}
          modules={[Autoplay, Navigation]}
          navigation
          autoplay
        >
          <SwiperSlide>
            <img src={img?.slider2} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img?.slider1} alt="" className="img-fluid" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img?.slider3} alt="" className="img-fluid" />
          </SwiperSlide>
        </Swiper>
      </div>

      <section>
        <div className="category_section pt-50 pb-50">
          <div className="container-fluid">
            <div className="heading_withphot">
              <div className="heading">
                <h1>Categories </h1>
                <img src={img?.borderimg} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={5}
                  loop
                  speed={2000}
                  modules={[Autoplay, Navigation]}
                  // navigation
                  autoplay
                >
                  <SwiperSlide>
                    <div className="product_category_box">
                      <div className="procutcate_img">
                        <img src={img?.pooja_product2} className='img-fluid' />
                      </div>
                      <div className="info_caption">
                        <ul className="socal_icon">
                          <li><a href="#"><i className="fa fa-heart"></i></a></li>
                          <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li>
                          <li><a href="#"><i className="fa fa-random"></i></a></li>
                        </ul>
                        <h5>Meditation Rug</h5>
                        <p className="price_product"><span className="cutprice">$99.00</span> $89.00</p>
                        <p> <i className="fa fa-star" aria-hidden="true"></i> <i className="fa fa-star" aria-hidden="true"></i>  <i className="fa fa-star" aria-hidden="true"></i>  <i className="fa fa-star graycolor" aria-hidden="true"></i></p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>



















            <div className="heading">
              <h1>Categories </h1>
              <img src={img?.borderimg} alt="" className="img-fluid" />
            </div>
            <Swiper
              spaceBetween={30}
              slidesPerView={4}
              loop
              speed={2000}
              modules={[Autoplay, Navigation]}
              navigation
              autoplay
            >
              <SwiperSlide>
                <div className="category-services text-center">
                  <div className="img">
                    <img src={img.productimg1} alt="image" />
                  </div>
                  <div className="category_title">
                    <Link to="" className="cat">
                      Name
                    </Link>
                  </div>
                  <div className="category_icon">
                    <Link to="" className="cat">
                      <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-services text-center">
                  <div className="img">
                    <img src={img.productimg1} alt="image" />
                  </div>
                  <div className="category_title">
                    <Link to="" className="cat">
                      Name
                    </Link>
                  </div>

                  <div className="category_icon">
                    <Link to="" className="cat">
                      <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-services text-center">
                  <div className="img">
                    <img src={img.productimg1} alt="image" />
                  </div>
                  <div className="category_title">
                    <Link to="" className="cat">
                      Name
                    </Link>
                  </div>
                  <div className="category_icon">
                    <Link to="" className="">
                      <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-services text-center">
                  <div className="img">
                    <img src={img.productimg1} alt="image" />
                  </div>
                  <div className="category_title">
                    <Link to="" className="cat">
                      Name
                    </Link>
                  </div>
                  <div className="category_icon">
                    <Link to="" className="cat">
                      <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-services text-center">
                  <div className="img">
                    <img src={img.productimg1} alt="image" />
                  </div>
                  <div className="category_title">
                    <Link to="" className="cat">
                      Name
                    </Link>
                  </div>
                  <div className="category_icon">
                    <Link to="" className="">
                      <BsArrowRight />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <main
        style={{ backgroundImage: `url(${img.astrology_bg})` }}
        className="main-bg"
      >
        {/* <Banners /> */}

        {/* ---------------yatendra--------------------------------- */}

        {/* ---------------------yatendra------------ */}

        <section>
          <div className="category_section">
            <div className="container">
              <div className="heading">
                <h1>Categories </h1>
                <img src={img?.borderimg} alt="" className="img-fluid" />
              </div>
              <Swiper
                spaceBetween={30}
                slidesPerView={4}
                loop
                speed={2000}
                modules={[Autoplay, Navigation]}
                navigation
                autoplay
              >
                <SwiperSlide>
                  <div className="category-services text-center">
                    <div className="img">
                      <img src={img.Pooja} alt="image" />
                    </div>
                    <div className="category_title">
                      <Link to="" className="cat">
                        Name
                      </Link>
                    </div>
                    <div className="category_icon">
                      <Link to="" className="cat">
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="category-services text-center">
                    <div className="img">
                      <img src={img.Pooja} alt="image" />
                    </div>
                    <div className="category_title">
                      <Link to="" className="cat">
                        Name
                      </Link>
                    </div>

                    <div className="category_icon">
                      <Link to="" className="cat">
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="category-services text-center">
                    <div className="img">
                      <img src={img.Pooja} alt="image" />
                    </div>
                    <div className="category_title">
                      <Link to="" className="cat">
                        Name
                      </Link>
                    </div>
                    <div className="category_icon">
                      <Link to="" className="">
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="category-services text-center">
                    <div className="img">
                      <img src={img.Pooja} alt="image" />
                    </div>
                    <div className="category_title">
                      <Link to="" className="cat">
                        Name
                      </Link>
                    </div>
                    <div className="category_icon">
                      <Link to="" className="cat">
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="category-services text-center">
                    <div className="img">
                      <img src={img.Pooja} alt="image" />
                    </div>
                    <div className="category_title">
                      <Link to="" className="cat">
                        Name
                      </Link>
                    </div>
                    <div className="category_icon">
                      <Link to="" className="">
                        <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>

        <section className="home_page_banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <div className="left">
                  <div className="left_side_banner">
                    <div className="left_side">
                      <h5 className="head_1"> FROM LOVESEATS TO SECTIONALS.</h5>
                      <h5 className="head_2">Comfy Lounging</h5>
                      <h5 className="head_3">
                        <Link to="" className="collection">
                          Explore Collection
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="right">
                  <div className="right_side_banner">
                    <div className="right_side">
                      <h5 className="head_1">SALE UP TO 20% OFF ALL ITEMS</h5>
                      <h5 className="head_2">Scandinavian Style</h5>
                      <h5 className="head_3">
                        <Link to="" className="collection">
                          Explore Collection
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog mb-100 px-4pc">
          <div className="container">
            <ul className="blog-filters">
              {tabs.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={`${item.active ? "active" : ""}`}
                    onClick={() => tabClick(i)}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
            <div className="row gx-lg-5 gy-4 gy-lg-5">
              {currentTab?.map((item, i) => {
                return (
                  <>
                    <div className="col-12 col-md-4">
                      <div className="new-arival_section">
                        <div className="blog-box product-grid">
                          <div className="product-image">
                            <Link to="/BlogDetails" className="overflow-hidden">
                              <div className="figure">
                                <img
                                  src={item.img}
                                  alt=""
                                  className="image-main img-fluid"
                                />
                                <img
                                  src={item.img2}
                                  alt=""
                                  className="image-hover img-fluid"
                                />
                              </div>
                            </Link>
                          </div>
                          <ul className="social">
                            <li>
                              <a
                                href=""
                                className="add_to_wishlist"
                                id="wishlistbtn_3"
                                data-product_id="3"
                                data-seller_id="1"
                                data-tip="Wishlist"
                              >
                                <i className="fa fa-heart"></i>
                              </a>
                              <span className="tooltiptext">Wishlist</span>
                            </li>
                            <li>
                              <a
                                href=""
                                data-tip="add to cart"
                                className="addToCartFromThumnail"
                                data-producttype="1"
                                data-seller="1"
                                data-product-sku="3"
                                data-base-price="60"
                                data-shipping-method="0"
                                data-product-id="3"
                                data-stock_manage="1"
                                data-stock="99"
                                data-min_qty="1"
                              >
                                <i className="fa fa-shopping-cart"></i>
                              </a>
                              <span className="tooltiptext">Cart</span>
                            </li>
                            <li>
                              <a
                                href=""
                                data-tip="Compare"
                                className="addToCompareFromThumnail"
                                data-producttype="1"
                                data-seller="1"
                                data-product-sku="3"
                                data-product-id="3"
                              >
                                <i className="fa fa-random"></i>
                              </a>
                              <span className="tooltiptext">Compare</span>
                            </li>
                          </ul>
                          <div className="blog_page">
                            <ul className="tags">
                              <li>
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </li>
                              <li>
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </li>

                              <li>
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </li>
                              <li>
                                <i className="fa fa-star" aria-hidden="true"></i>
                              </li>
                            </ul>
                            <div className="blog_content">
                              <ul className="content">
                                <li>
                                  {" "}
                                  <Link to="" className="">
                                    <span className="brown"></span>
                                    {item?.title}{" "}
                                  </Link>
                                </li>
                                <li>
                                  <span className="brown"></span>
                                  {item?.price}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>

        <section className="pic-demo ptb-80">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="im-cat">
                  <img src={img?.cat} className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="text_iamges text-center">
                  <h2>Heading Simply dummy text</h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like).
                  </p>
                  <Link to="" className="btn btn-primary btn-bg">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="newarrial_section ptb-80 bg-white">
          {/* <div className='rightimgaboslute'>
                    <img src={img.crileimg} alt="" className='' />
                </div> */}
          <div className="container">
            <div className="position-relative">
              <div className="img-title text-center">
                <h2 className="heading">New Arrivals</h2>
                <img src={img?.borderimg} alt="" className="img-fluid" />
              </div>
              <div className="h-100 position-absolute top-0 end-0 d-flex align-items-center">
                <Link to="/Category" className="btn btn-primary btn-bg">
                  View All
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 slider-sect">
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                  spaceBetween={20}
                  // loop={true}
                  speed={1000}
                  slidesPerView={4}
                >
                  {arrivalProducts &&
                    arrivalProducts.length > 0 &&
                    arrivalProducts.map((el, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="categor_slide" key={index}>
                            <Link to={`/Product/${el?.slug}`}>
                              <div className="arrival-image">
                                <img
                                  src={generateFilePath(el?.imageArr[0]?.image)}
                                  className="img-fluid image-st"
                                />
                              </div>
                            </Link>
                            {/* style={{backgroundColor:"red"}} */}
                            <div className="icon_heart">
                              <BsHeart
                                className={`${el?.isWishlisted ? "colorOfWishlist" : ""
                                  }  `}
                                onClick={(e) =>
                                  handleAddProductToWishlist(e, el)
                                }
                              />
                            </div>
                            <div className="info_slid">
                              <h6>{el?.name}</h6>
                              <p className="mb-0">₹ {el?.sellingPrice} </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="Seller_section ptb-80">
          <div className="container">
            <div className="position-relative">
              <div className="img-title text-center">
                <h2 className="heading">Top Seller</h2>
                <img src={img?.borderimg} alt="" className="img-fluid" />
              </div>
              <div className="h-100 position-absolute top-0 end-0 d-flex align-items-center">
                <Link to="/Product" className="btn btn-primary btn-bg">
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                  spaceBetween={20}
                  // loop={true}
                  speed={4000}
                  slidesPerView={4}
                >
                  {bestSellerProducts &&
                    bestSellerProducts.length > 0 &&
                    bestSellerProducts?.map((el, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="seller_slider">
                            <div className="bg-white">
                              <Link to={`/Product/${el?.slug}`}>
                                <div className="seller-image">
                                  <img
                                    src={generateFilePath(
                                      el?.imageArr[0]?.image
                                    )}
                                    className="img-fluid"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="seller_slid">
                              <h6>{el?.name}</h6>
                              <p className="mb-0">₹ {el?.sellingPrice} </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="gallery_section ptb-80 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 gall_sec_grid">
                <div className="gall_section mb-4">
                  <div className="img-title-style mb-4">
                    <h2 className="heading text-left">Gallery</h2>
                    <img src={img.borderimg} alt="" className="img-fluid" />
                  </div>

                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text.
                  </p>
                  <Link to="/" className="btn btn-primary btn-bg">
                    View All
                  </Link>
                </div>

                <div className="image-effect">
                  <div className="gallery-page-inner gallery-img">
                    <div className="mm-image">
                      <div className="image">
                        <Link to={`${gallaryArr[0]?.slug}`}></Link>
                        <img
                          src={
                            gallaryArr[0]?.imageUrl
                              ? generateFilePath(gallaryArr[0]?.imageUrl)
                              : img.thali
                          }
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row gall_sec_grid g-3">
                  {gallaryArr &&
                    gallaryArr.length > 0 &&
                    gallaryArr
                      ?.filter((x, i) => i > 0 && i < 5)
                      ?.map((el, index) => {
                        return (
                          <div
                            className="col-12 col-lg-6 image-effect "
                            key={index}
                          >
                            <div className="gallery-page-inner gallery-img">
                              <div className="image">
                                <img
                                  src={generateFilePath(el?.imageUrl)}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* -------------hover div product--------------- */}

        <div className="new-arival_section">
          <div className="product-grid">
            <div className="product-image">
              <a href="https://glamfamcosmetics.com/product/mascara-super-black">
                <img
                  data-src="https://glamfamcosmetics.com/public/uploads/images/12-10-2022/6346abda0219d.jpeg"
                  alt="MASCARA SUPER BLACK"
                  src="https://glamfamcosmetics.com/public/uploads/images/12-10-2022/6346abda0219d.jpeg"
                  className="img-fluid lazyload pic-1"
                />
              </a>
            </div>

            <div className="product-content">
              <h3 className="title">
                <a href="https://glamfamcosmetics.com/product/mascara-super-black">
                  {" "}
                  MASCARA SUPER BLACK{" "}
                </a>
              </h3>

              <div className="price">₹ 60.00</div>
            </div>

            <ul className="social">
              <li>
                <a
                  href=""
                  className="add_to_wishlist "
                  id="wishlistbtn_3"
                  data-product_id="3"
                  data-seller_id="1"
                  data-tip=" Wishlist"
                >
                  <i className="fa fa-heart"></i>
                </a>
              </li>
              <li>
                <a
                  href=""
                  data-tip="add to cart"
                  className="addToCartFromThumnail"
                  data-producttype="1"
                  data-seller="1"
                  data-product-sku="3"
                  data-base-price="60"
                  data-shipping-method="0"
                  data-product-id="3"
                  data-stock_manage="1"
                  data-stock="99"
                  data-min_qty="1"
                >
                  <i className="fa fa-shopping-cart"></i>
                </a>
              </li>

              <li>
                <a
                  href=""
                  data-tip="Compare"
                  className="addToCompareFromThumnail"
                  data-producttype="1"
                  data-seller="1"
                  data-product-sku="3"
                  data-product-id="3"
                >
                  <i className="fa fa-random"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* -------------------- */}
      </main>
    </>
  );
};
export default Index;
