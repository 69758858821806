import React from 'react'
import { img } from '../Components/Image'
const Shiping = () => {
  return (
    <>
      <section>
        <div className='shipping_details'>
          <div className='container'>
            <div className='row py-4'>
              <div className='col-lg-8 from_style '>
                <div className='col-lg-6 shipi-style'>
                  <h3>Shiping  Details</h3>
                </div>
                <div className=' pdg edit_section_show mt-4'>
                  <div className='flex_heading_row'>
                    <div className='Name_heading'>
                      <p>Uttam nagar east</p>

                    </div>

                    <div className='readdress_info'>
                      <p>New Delhi</p>
                      <p>Delhi</p>
                      <p>India</p>
                      <p className='mb-2'> Pin Code :: 110077</p>
                      <p className='address-mobile'> Mobile : 6756746547</p>
                      <p className='address-email'> Email : a@gmail.com</p>
                    </div>

                    <div className='edit-section' style={{ display: 'block' }}>
                      <div className='row'>
                        <div className='col-lg-6 text-center'>
                          <div className='edit_add'>
                            <a href="#" className='edit_address' > Edit </a>
                          </div>
                        </div>
                        <div className='col-lg-6 text-center'>
                          <div className='remove_add delete_address_btn'>
                            REMOVE
                          </div>
                        </div>

                      </div>


                    </div>

                  </div>

                </div>

                <div className=' pdg edit_section_show mt-4'>
                  <div className='flex_heading_row'>
                    <div className='Name_heading'>
                      <p>Uttam nagar east</p>

                    </div>

                    <div className='readdress_info'>
                      <p>New Delhi</p>
                      <p>Delhi</p>
                      <p>India</p>
                      <p className='mb-2'> Pin Code :: 110077</p>
                      <p className='address-mobile'> Mobile : 6756746547</p>
                      <p className='address-email'> Email : a@gmail.com</p>
                    </div>

                    <div className='edit-section' style={{ display: 'block' }}>
                      <div className='row'>
                        <div className='col-lg-6 text-center'>
                          <div className='edit_add'>
                            <a href="#" className='edit_address' > Edit </a>
                          </div>
                        </div>
                        <div className='col-lg-6 text-center'>
                          <div className='remove_add delete_address_btn'>
                            REMOVE
                          </div>
                        </div>

                      </div>


                    </div>

                  </div>

                </div>
                <div className=' pdg edit_section_show mt-4'>
                  <div className='flex_heading_row'>
                    <div className='Name_heading'>
                      <p>Uttam nagar east</p>

                    </div>

                    <div className='readdress_info'>
                      <p>New Delhi</p>
                      <p>Delhi</p>
                      <p>India</p>
                      <p className='mb-2'> Pin Code :: 110077</p>
                      <p className='address-mobile'> Mobile : 6756746547</p>
                      <p className='address-email'> Email : a@gmail.com</p>
                    </div>

                    <div className='edit-section' style={{ display: 'block' }}>
                      <div className='row'>
                        <div className='col-lg-6 text-center'>
                          <div className='edit_add'>
                            <a href="#" className='edit_address' > Edit </a>
                          </div>
                        </div>
                        <div className='col-lg-6 text-center'>
                          <div className='remove_add delete_address_btn'>
                            REMOVE
                          </div>
                        </div>

                      </div>


                    </div>

                  </div>

                </div>

              </div>
              <div className='col-lg-4 summary_style'>
                <div className="order-style">
                  <h6 className='order-t'>Order Summary</h6>
                  <div className='cart_su'>Subtotal<span>₹ 4,947.00</span></div>
                  <div className='cart_su'>Shipping <span>₹ 140.00</span></div>
                  <hr />
                  <div className='cart_su'>Order Total<b>₹ 4,947.00</b></div>

                </div>


                <div className="form-check py-3 link-style">
                  <h6>Direct Bank Transfer</h6>


                  <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                  <p><input type="radio" id="contactChoice1" name="" value="bank" checked />
                    <label for="contactChoice1">Direct Bank Transfer</label></p>

                  <p><input type="radio" id="contactChoice1" name="" value="cash" checked />
                    <label for="contactChoice1">Cash On Delivery</label></p>


                  <p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.</p>

                  <button>Proceed to checkout</button>
                </div>
              </div>
            </div>




          </div>
        </div>

      </section>



    </>
  )
}
export default Shiping;