import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/wishlist";

export const addWishlist = (formData) => {

    return axiosApiInstance.post(serverUrl + "/AddToWishlist", formData);
};

export const getWishlist = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getWishlist?${query}`);
};

export const deleteWishlist = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const removePRoductWishlist = (id) => {
    // console.log(id,"iidiiiidididid")
    return axiosApiInstance.patch(`${serverUrl}/RemoveItemFromWishlist/${id}`);
};

