import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/companyAddress";


export const getCompanyAddress = (query) => {
    return axiosApiInstance.get(`${serverUrl}/get?${query}`);
};

// export const deleteCity = (id) => {
//     return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
// };

// export const updateCity = (formData, id) => {
//     return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
// };

