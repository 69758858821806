import React from "react";
import { img } from "../Components/Image";
const Orderdetails = () => {
  return (
    <>

      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>My Order  Tracking

</h4>
      </div>
      <div className="ptb-80 bgyellowlight ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="orderdetrails_boxe bg-white">
                <p className="orderheading">My Order / Tracking</p>
                <h5 className="orderid">Order ID: ODA7895692465</h5>
                <div className="delevely_states">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="carddstimated">
                        <h4 className="headingse">Estimated Delivery time</h4>
                        <p className="date">30 Mar 2023</p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="carddstimated">
                        <h4 className="headingse">shipping by</h4>
                        <p className="date">BLUEDART | +91-8596321457</p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="carddstimated">
                        <h4 className="headingse">status</h4>
                        <p className="date">Picked by the courier</p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="carddstimated">
                        <h4 className="headingse">tracking id</h4>
                        <p className="date">ODA7895692465</p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="timeline position-relative mt-0">
                  <li className="half">
                    <div className="dot"></div>
                    <div className="empty-line"></div>
                    <div className="half-filled">
                      <img src="" />
                      <div className="half-line"></div>
                    </div>
                    <p className="mb-0">Order Confirmed</p>
                  </li >
                  <li className="half">
                    <div className="dot"></div>
                    <div className="empty-line"></div>
                    <p className="mb-0">Order Ship </p>
                  </li>
                  <li className="half">
                    <div className="dot"></div>
                    <div className="empty-line"></div>
                    <p className="mb-0">Out for Delivery </p>
                  </li>
                  <li className="empty">
                    <div className="dot"></div>
                    <div className="empty-line"></div>
                    <p className="mb-0">Out for Delivery </p>
                  </li>
                  <li className="empty">
                    <div className="dot"></div>
                    <div className="empty-line"></div>
                    <p className="mb-0">Order Delivered </p>
                  </li>
                  
                 
                </ul>
                <div className="row gx-0 mt-5 delevely_states justify-content-between align-items-center" >
                    <div className="col-lg-4">
                        <div className="order_detilcardflexee">
                            <img src={img?.productimgcarddd} alt=""  className="img-fluid img-thumbnail"/>
                            <div className="card_contentnproducname">
                                <h2>Cellecor CLB21 20 W Bluetooth Soundbar (Black, 2.2 Channel)</h2>
                                <p className="mt-2"><span> Color  </span>: Black</p>
                                <p><span> Size  </span>: Stander </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="pricerate text-center">
                            <h3>₹2,184.00</h3>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="ordership_card ps-5">
                            <h5>Estimated Delivery Time</h5>
                            <p>Your Order has been ship</p>
                        </div>
                    </div>
                </div>
                <div className="marginleftright15">
                <div className="row mt-5 ">
                    <div className="col-lg-12 ">
                        <table className="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>Payment Date</th>
                                    <th>Description</th>
                                    <th>Amount (₹)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>06/02/2023</td>
                                    <td>Payment Link - 218</td>
                                    <td>35,000.00</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Total</td>
                                    <td>35,000.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orderdetails;
