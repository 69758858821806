import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getBlogCategory } from "../services/blogCategory.service";

// import {  getTestimonials,} from "../services/testimonial.service";

///////////async functions starts here
export const fetchBlogsCategory = createAsyncThunk(
  "blogCategory/fetchBlogsCategory",
  async (query) => {

    const { data: response } = await getBlogCategory(query);
    return response.data;
  }
);



///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const blogCategorySlice = createSlice({
  name: "BlogsCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlogsCategory.fulfilled, (state, action) => {
      state.items = action.payload;
    });

  },
});

export default blogCategorySlice.reducer;
