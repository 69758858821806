import { img } from "../Components/Image";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../node_modules/swiper/swiper-bundle.css";
import { currencyContext, productCategoryObjectContext } from "../App";
import { fetchBanner } from "../redux/Banner.slice";
import {
  getArrivalActiveProductsArr,
  getbestsellerActiveProductsArr,
} from "../redux/Product.slice";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { getSubscriber, addSubscriber } from "../services/subscriber.service";

const Footer = () => {
  const [subscriber, setSubscriber] = useState("");

  const handleSendSubscriber = async (e) => {
    e.preventDefault();
    try {
      if (subscriber == "") {
        toastError("Email is mandatory");
        return;
      }
      let obj = {
        email: subscriber,
      };
      let { data: getResponse } = await addSubscriber(obj);
      // console.log(getResponse, "getResponse2354234");
      if (getResponse?.message) {
        toastSuccess(getResponse?.message);
        setSubscriber("");
      }
    } catch (err) {
      toastError(err);
    }
  };




  let get1 = localStorage.getItem("currencyObj");
  useEffect(() => {
    // console.log(get1, "cate====");
    get1 = JSON.parse(get1);
    // console.log(get1, "cate==object***==");
  }, [get1]);


  
  const handleSetPageOffSet = () => {
    let setData = {
      x: window.pageXOffset,
      y: window.pageYOffset,
    };
    //  localStorage.setItem("page", page[1]);
    localStorage.setItem("currencyObj", JSON.stringify(setData));
  };
  return (
    <footer className="containerpadding">
      <div className="footer_section">
        <div className="left_imgcategory">
          <img src={img.crileimg} alt="" />
        </div>
        <div className="container-fluid">
          <div className="footer">
            <div className="row ">
              <div className="col-lg-3 col-md-4 col-sm-12 foot_info">
                <div className="logo_cen">
                  <Link to="/" className="main_logo">
                    <img src={img.weblogo} alt="" className="img-fluid" />
                  </Link>
                </div>
                <p className="footer_content">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  werwerwerwre werw werf qer since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 foot_link ps-5">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to="/" onClick={() => handleSetPageOffSet()}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/About">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/Blog">Blogs</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12  foot_link">
                <h4>Customer Care</h4>
                <ul>
                  <li>
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Faqs">Help & FAQs</Link>
                  </li>

                  <li>
                    {/* <Link to="/TremsCon">Terms Condition</Link> */}
                    <Link to="/TermsAndCondition">Terms Condition</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 foot_news">
                <h4>News Letter</h4>
                <p>Sign up now to get first update about our new collection.</p>
                <div className="in">
                  <input
                    value={subscriber}
                    onChange={(e) => setSubscriber(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                  />
                  <button onClick={(e) => handleSendSubscriber(e)}>
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr className="m-0" />
            <p className="foot_copy py-3">
              © 2022 Samagri. All right reserved , Designed & Developer by
              Ebslon Infotech
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
