import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";
const serverUrl = url + "/product";

export const addProduct = async (formData) => {
  // console.log(axiosApiInstance)
  return await axiosApiInstance.post(serverUrl + "/addProduct", formData);
};

export const getProductById = (id, query) => {
  return axiosApiInstance.get(`${serverUrl}/getProductById/${id}?${query}`);
};
export const getSimilarProductsByProductById = (id, query) => {
  return axiosApiInstance.get(
    `${serverUrl}/getSimilarProductsByProductById/${id}?${query}`
  );
};

export const getProducts = (query) => {
  // console.log(query,"queryquery")
  return axiosApiInstance.get(`${serverUrl}/getProducts?${query}`);
};

export const deleteProductById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateProductById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const getActiveProducts = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getActiveProducts?${query}`);
};

export const approveProductsInBulk = (formData) => {
  return axiosApiInstance.patch(
    `${serverUrl}/approveProductsInBulk/`,
    formData
  );
};
export const unapproveProductsInBulk = (formData) => {
  return axiosApiInstance.patch(
    `${serverUrl}/unapproveProductsInBulk/`,
    formData
  );
};
export const deleteProductsInBulk = (formData) => {
  return axiosApiInstance.patch(`${serverUrl}/deleteProductsInBulk/`, formData);
};

export const handleRelatedProductObjAdd = (formData) => {
  return axiosApiInstance.post(`${serverUrl}/relatedProductsAdd`, formData);
};

export const handleRelatedProductObjRemove = (formData) => {
  return axiosApiInstance.post(`${serverUrl}/relatedProductsRemove`, formData);
};

export const getAllRelatedProducts = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getRelatedProducts?${query}`);
};

export const productCloneApi = (formData) => {
  return axiosApiInstance.post(`${serverUrl}/productCloning`, formData);
};

export const getAllRelatedProductsBySlug = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getProductsBySlug?slug=${query}`);
};

export const getProductBySlug = (query) => {
  let config = {
    headers: {
      verifyAuth: "false",
    },
  };
  return axiosApiInstance.get(
    `${serverUrl}/getAllProductsBySlug?slug=${query}`,
    config
  );
};

export const getSearchProduct =(query)=>{
  return axiosApiInstance.get(`${serverUrl}/productSearchByName?name=${query}`)
}
// let obj = {
//   attributesArr: [
//     { name: "a1", price: 2, _id: "iiiiii" },
//     { name: "a12", price: 21, _id: "34543iiiiii" },
//     { name: "a13", price: 22, _id: "34543iiiiii" },
//   ],
// };

// let mongoobj = {
//   productArr: [
//     { name: "a1", price: 2, productId: "iiiiii" },
//     { name: "a12", price: 21, productId: "34543iiiiii" },
//     { name: "a13", price: 22, productId: "34543iiiiii" },
//   ],
// };
