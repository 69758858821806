import Header from "../Components/Header";
import { img } from "../Components/Image";

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsHeart } from "react-icons/bs";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Link, useParams } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ReactPaginate from "react-paginate";

import { toastError } from "../utils/toastUtils";
import { generateFilePath } from "../utils/filepath";

import { useDispatch, useSelector } from "react-redux";

import { fetchBlogs } from "../redux/Blog.slice";
import { fetchBlogsCategory } from "../redux/BlogCategory.slice";
import { BsArrowRight } from "react-icons/bs";

import { getBlog } from "../../src/services/blog.service";

const Blog = () => {
  const dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);
  const [popularBlog, setPopularBlog] = useState([]);
  const [blogCategoryArr, setBlogCategoryArr] = useState([]);
  const [selectedBlogCategory, setSelectedBlogCategory] = useState("");
  const blogData = useSelector((state) => state.blog.items);
  const blogcategoryData = useSelector((state) => state.blogCategory.items);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPages, setPerPages] = useState(9);

  const handleInit = async (value) => {
    try {
      let query = `page=${page}&perPage=${perPages}&blogCategoryId=${value}`;
      // let query = `blogCategoryId=${value}`;
      let { data: getData } = await getBlog(query);
      // console.log(getData, "get  DatagetData===============");
      if (getData) {
        setBlogCategoryArr(getData?.data);
        setBlogArr(getData?.data);
        setTotalPages(getData?.totalPages);
      }
      // dispatch(fetchBlogs(query));
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    // console.log(page, "page get ===00");
    handleInit("All");
  }, [page]);

  useEffect(() => {
    if (blogData) {
      // console.log(blogData,"get blog ");
      let popularBlog = blogData.filter((x, i) => x.popular == true);
      setPopularBlog(popularBlog);
      let blog = blogData;
      setBlogArr(blog);
    }
  }, [blogData]);

  useEffect(() => {
    if (blogcategoryData) {
      setBlogCategoryArr(blogcategoryData);
    }
  }, [blogcategoryData]);

  const handleGetBlogCategory = async () => {
    try {
      dispatch(fetchBlogsCategory());
      // let { data: res } = await getBlogCategory();
      // if (res.data) {
      //   setBlogCategoryArr(res.data);
      // }
    } catch (err) {
      toastError(err);
    }
  };

  // const handleGetFilteredBlogs = (value) => {
  //   setSelectedBlogCategory(value);
  //   handleInit(value);
  // };

  useEffect(() => {
    handleInit("All");
    handleGetBlogCategory();
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <div className="banner_category">
        <div className="overlay1"></div>
        <h4>Blog</h4>
      </div>
      {/* <section>
        <div className="blog_banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 text-center">
                <h2>Blog</h2>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="blog_about ptb-80">
        <div className="container">
          <div className="row">
            {blogArr && blogArr.length > 0 ? (
              blogArr.map((el, index) => {
                return (
                  <div className="col-lg-4">
                    <div className="blog_deatil mb-4">
                      <img
                        src={generateFilePath(el?.image)}
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="blog_infocontet">
                        <p className="blog_name">
                          {" "}
                          <span>
                            {" "}
                            {new Date(el.createdAt).toDateString()}{" "}
                          </span>{" "}
                          {el?.categoryName}{" "}
                        </p>
                        <h6> {el?.title}</h6>
                        {/* <p
                    dangerouslySetInnerHTML={{
                      __html: blogArr[0]?.description.slice(0, 350),
                    }}
                  /> */}
                        <Link to={`/blog/${el?.slug}`}>
                          <button className="blog_btn">
                            {" "}
                            Read More <BsArrowRight />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-lg-9">
                <h3>No blogs found for the selected Category</h3>
              </div>
            )}

            <section className="product-pagination mb-50">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={(e) => {
                  setPage(e.selected + 1);
                }}
                pageRangeDisplayed={5}
                pageCount={totalPages}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </section>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Blog;
