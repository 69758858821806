import { img } from "../Components/Image";
import React, { useState, useEffect } from "react";

const TremsCon = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <main
      style={{ backgroundImage: `url(${img.astrology_bg})` }}
      className="main-bg border-top pt-5"
    >
      <section className="privacy_page mb-80">
        <div className="container">
          <div className="row bg-white">
            <div className="col-lg-12">
              <h4 className="mb-4">Terms & Conditions</h4>
              <p>
                This document is an electronic record in terms of Information
                Technology Act, 2000 and rules there under as applicable and the
                amended provisions pertaining to electronic records in various
                statutes as amended by the Information Technology Act, 2000.
                This electronic record is generated by a computer system and
                does not require any physical or digital signatures.
              </p>
              <p>
                This document is a legally binding agreement between a Seller
                (as defined below) or a Buyer (as defined below), as the case
                may be (acting by itself or through its representatives)
                (collectively referred to as “you”, “your”, “User” hereinafter)
                who access or use or transact on the Platform and avail Service
                for a commercial purpose only and the Overleap Networks Private
                Limited (referred to as “we”, “our” or “SAMAGRI” hereinafter).
                you acknowledge and agree that the Platform is a business to
                business (B2B) platform and provides services to business
                entities only.
              </p>
              <p>
                This document and such other rules and policies of the Platform
                are collectively referred to below as the “Terms”. We reserve
                the right, at our sole discretion, to change, modify, add or
                remove portions of these Terms, at any time without any prior
                written notice to you. By accessing, browsing, or otherwise
                using the Platform or using the Services, including following
                the posting of changes, User agrees to accept and be bound by
                the Terms (as may be amended from time to time). It is your
                responsibility to review these Terms periodically for any
                updates / changes. Please do not use the Services or access the
                Platform if you do not accept the Terms or are unable to be
                bound by the Terms.
              </p>
              <p>
                PLEASE READ THE TERMS CAREFULLY BEFORE PROCEEDING IF YOU DO NOT
                AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE THE PLATFORM
                OR THE SERVICES PROVIDED BY THE PLATFORM OR SAMAGRI. By
                impliedly or expressly accepting these Terms, you also accept
                and agree to be bound by any amendments, updates and
                modifications to the Terms and the other policies (including but
                not limited to, Privacy Policy), as may be amended, updated and
                modified from time to time.
              </p>
              <p>
                Additional terms and conditions may apply to you in respect of
                availing specific services and/or to specific portions or
                features of the Platform, including but not limited to,
                Services, any other additional services as may be offered by us
                from time to time, contests, offers, schemes, promotions or
                other similar features, all of which terms are to be read as
                part of these Terms. you agree to abide by such other terms and
                conditions, including, where applicable, representing that you
                have the legal capacity to use or participate in such service or
                feature. If there is a conflict between these Terms and the
                terms posted for or applicable to a specific portion of the
                Platform or for any specific service offered on or through the
                Platform, the latter terms shall control with respect to your
                use of that portion of the Platform or the specific service.
              </p>
              <h6>Interpretations:</h6>
              <p>
                The following rules will apply to these Terms and Conditions
                unless the context requires otherwise or unless it is expressly
                stated otherwise:
              </p>
              <p>
                Any references to “clauses” in this document refer to clauses
                stated within these Terms and Conditions; The singular includes
                the plural and conversely; “Buyer” means a User who places an
                order for purchase of Products and/or services on the Platform.
                “GST” means the goods and service tax as levied under the
                Central Goods and Services Tax Act and Rules (CGST), Integrated
                Goods and Services Tax Act and Rules (IGST), State Goods and
                Services Tax Acts and Rules (SGST) levied by the States, Union
                Territory Goods and Services Tax Act and Rules (UTGST) levied by
                the Union Territories, and all allied cesses, duties and taxes.
              </p>
              <p>
                “Personal Information” means any information that relates to a
                natural person which, either directly or indirectly, in
                combination with other information available or likely to be
                available with a body corporate, is capable of identifying such
                a person. “Platform” means the technological applications,
                through which the Products are made available for sale,
                including but not limited to the website/mobile site
                (www.SAMAGRI.com), any mobile applications where the Products
                are made available for sale to provide access to the Platform.
                “Privacy Policy” shall mean the privacy policy applicable on the
                Platform “Products” shall mean the products made available for
                sale on the Platform. “Contract” means the specific terms of
                trade including but not limited to the commercial terms agreed
                between the Seller and SAMAGRI. “Seller” means a person/entity
                who lists its Products for sale on the Platform. “Seller ID”
                shall have the meaning ascribed as defined below. “Terms of
                Sale” shall mean the terms and conditions of sale executed
                between the Buyer or the Seller (as applicable) and SAMAGRI;
                “Third-Party Content” shall have the meaning ascribed to it as
                defined below “Transaction” means the process of placing an
                order for the Products or availing any services on the Platform;
                and “User Submitted Data” means content including user’s data or
                information including Product rating, comments, Product reviews,
                comments, questions or other information and includes any
                material that is generated, as a whole or in part, on the
                Platform.
              </p>
              <h6>General Terms:</h6>
              <p>
                If you transact on the Platform, you shall be subject to the
                Agreements / that are applicable to the Platform for such
                Transaction. By using the Platform, you shall be contracting
                with SAMAGRI, and these Terms of Use including the Agreements
                constitute your binding obligations, with SAMAGRI. SAMAGRI owns
                and manages the Platform, which is an electronic medium to
                enable the display and sale of various Products, and incidental
                services thereto. The Platform provides a medium for the Sellers
                to advertise, exhibit, make available and offer to sell various
                Products to the Buyers and to enable payments by the Buyers to
                the Sellers, and ancillary services including packaging, quality
                control and management of logistics.
              </p>
              <h6>Usage:</h6>
              <p>
                Subject to these Terms of Use, SAMAGRI provides the services
                solely for your own use, and not for the use or benefit of any
                third party. Services shall include, but not be limited to, any
                services SAMAGRI performs for you, as well as the offering of
                any products on the Platform.
              </p>
              <h6>Registration:</h6>
              <p>
                To effectively place an order with SAMAGRI, you must be at least
                18 years of age. In order to use the Platform, you are required
                to register with SAMAGRI and represent that you provide SAMAGRI
                with accurate, and complete registration information (including,
                but not limited to your name, location, and contact details). On
                successful registration on the Platform, SAMAGRI will generate a
                unique identification (“Buyer ID” or “Seller ID” as applicable)
                which will be used to uniquely identify each Buyer and Seller on
                the Platform. SAMAGRI reserves the right to refuse registration
                of, or cancel a Username at its sole discretion. You are solely
                responsible and liable for activity that occurs on your account
                and shall be responsible for maintaining the confidentiality of
                your account password. You are solely responsible for ensuring
                that these Terms of Use are in compliance with all laws, rules
                and regulations applicable to you and the right to access the
                Platform is revoked where these Terms of Use or use of the
                Platform is prohibited and, in such circumstances, you agree not
                to use or access the Platform in any way.
              </p>
              <h6>Samagri Platform Content</h6>
              <p>
                SAMAGRI is providing content on the platform either created
                itself or sourced from its partners and such content is
                protected by trademarks, copyrights, patents, trade secrets or
                other proprietary laws as applicable. User agrees to abide by
                and maintain all copyright notices, information, and
                restrictions contained in all content accessed through the
                Platform. Each User of the Platform has been granted by SAMAGRI
                a worldwide, non-exclusive and non-transferable license to use
                and reproduce the content provided. Use, reproduction or
                distribution of any content provided by SAMAGRI other than for
                personal, non-commercial use is expressly prohibited without
                prior written permission. Content is made available on an as-is
                basis and without any representations or warranties whatsoever.
                SAMAGRI hereby disclaims any implied warranties, including
                accuracy, relevance, and non-infringement.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default TremsCon;
