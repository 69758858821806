// import Footer from '../Components/Footer'
// import Header from '../Components/Header'
import { img } from "../Components/Image";
import { Swiper, SwiperSlide } from "swiper/react";

import React, { useState, useEffect } from "react";
import { BsHeart } from "react-icons/bs";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from "swiper";

import { toastError } from "../utils/toastUtils";
import { generateFilePath } from "../utils/filepath";

import { fetchteams, getTestimonialsData } from "../redux/AboutUs.slice";

// import { getTeams } from "../services/team.service";

// import { getTestimonials } from "../services/testimonial.service";
import { useDispatch, useSelector } from "react-redux";

const About = () => {


  const [windowWidth, setwindowWidth] = useState(window.innerWidth);



  const dispatch = useDispatch();
  const [teamsArr, setTeamsArr] = useState([]);
  const [testimonialArr, setTestimonialArr] = useState([]);

  const TeamsData = useSelector((state) => state.aboutUs.items);
  const TestimonialData = useSelector(
    (state) => state.aboutUs.itemsTestimonials
  );

  const handleInit = async () => {
    dispatch(fetchteams());
    dispatch(getTestimonialsData(`status=APPROVED`));
  };

  useEffect(() => {
    handleInit();
    window.scrollTo(0,0)
  }, []);

  useEffect(() => {
    if(TeamsData){
      let filterActivePerson=TeamsData.filter((x)=>x.status=="APPROVED")
      setTeamsArr(filterActivePerson);
    }
  }, [TeamsData]);


  useEffect(() => {
    if(TestimonialData&&TestimonialData.length>0){
      let filterActivePerson=TestimonialData.filter((x)=>x.status=="APPROVED")

      setTestimonialArr(filterActivePerson);
    }
  }, [TestimonialData]);
  // useEffect(() => {
    
  // },[])
  return (
    <main   
    >
      <section
      // className={`${windowWidth > 992 ? "container-fluid" : "container"}`}
        className="blog_banner mb-80"
        style={{ backgroundImage: `url(${img.about_bg})` }}
       
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 text-center">
              <h2>About Us</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="about_story mb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <img src={img.about_story} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-7">
              <div className="heading_tital">
                <h2>Our Story</h2>
              </div>
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
              <h5>--Person name</h5>
            </div>
          </div>
        </div>
      </section>

      <section className="about_me ptb-80">
        <div className="container">
          <div className="row">
            <div className="about_he">
              <h2 className="pt-0">Who We Are</h2>
            </div>
            <div className="col-12">
              <Swiper
                slidesPerView={2}
                spaceBetween={20}
                loop
                speed={2000}
                className="mySwiper"
              >
                {teamsArr?.length > 0 &&
                  teamsArr.map((el, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="var_card text-center">
                          <img
                            src={generateFilePath(el?.image)}
                            className="img-fluid"
                            alt=""
                          />
                          <p> {el?.description} </p>
                          <h5> {el?.name} </h5>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="about_mission ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ab_mission">
                <h2>Our Mission</h2>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </p>
                <ul>
                  <li>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </li>
                  <li>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ab_pic">
                <img src={img.mission_pic} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_vission mb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ab_pic">
                <img src={img.Group} className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="ab_vission">
                <h2>Our Vision</h2>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s with the release of Letraset sheets
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </p>
                <ul>
                  <li>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </li>
                  <li>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className='about_author'>
          <div className='container'>
            <div className='about_v text-center'>
              <div className='row'>
                <h2>Who We Are</h2>

                <div className="col-12">
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    spaceBetween={20}
                    loop={true}
                    speed={4000}
                    slidesPerView={3}>

                    <SwiperSlide>
                      <div className=' abb-image'>
                        <img src={img.author} className="img-fluid" alt='' />
                        <h5>Jeffrey Brown</h5>
                        <p>Creative leader</p>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      < div className=' mt-5 abb-image'>
                        <img src={img.author1} className="img-fluid" alt='' />
                        <h5>Jeffrey Brown</h5>
                        <p>Creative leader</p>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='abb-image'>
                        <img src={img.a} className="img-fluid" alt='' />
                        <h5>Jeffrey Brown</h5>
                        <p>Creative leader</p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      < div className=' mt-5 abb-image'>
                        <img src={img.author1} className="img-fluid" alt='' />
                        <h5>Jeffrey Brown</h5>
                        <p>Creative leader</p>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='abb-image'>
                        <img src={img.a} className="img-fluid" alt='' />
                        <h5>Jeffrey Brown</h5>
                        <p>Creative leader</p>
                      </div>
                    </SwiperSlide>



                  </Swiper>

                </div>




              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="mb-80 about_card">
        <div className="container">
          <div className="row">
            <div className="about_text text-center mb-4">
              <h2>What our customers say?</h2>
            </div>
          </div>
          <div className="row about_card">
            <div className="col-lg-12">
              <Swiper
                className="mySwiper p-2"
                spaceBetween={20}
                loop={true}
                speed={2000}
                slidesPerView={3}
              >
                {testimonialArr.length > 0 &&
                  testimonialArr.map((el, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="card_info">
                          <div>
                            <h5> {el?.name} </h5>
                            {el?.designation && (
                              <h6 className="m-0">{el?.designation}</h6>
                            )}
                          </div>
                          <div>
                            <p>{el?.comment.slice(0, 200)}</p>

                            <h4>{new Date(el?.createdAt).toDateString()}</h4>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
