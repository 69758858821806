import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getActiveBanner } from "../services/banner1.service";

///////////async functions starts here
export const fetchBanner = createAsyncThunk(
  "banner/getBanner",
  async (query) => {
    // getActiveBanner("status=true");
    const { data: response } = await getActiveBanner(query);
    // console.log(response, "get banner-redux")
    return response.data;
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const bannerSlice = createSlice({
  name: "Banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBanner.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default bannerSlice.reducer;
