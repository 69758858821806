import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProducts,
  getProductBySlug,
  getActiveProducts,
} from "../services/Product1.service";

///////////async functions starts here
export const fetchProduct = createAsyncThunk(
  "product/getProduct",
  async (query) => {
    const { data: response } = await getProducts(query);

    return response;
  }
);

export const getProductObjBySlug = createAsyncThunk(
  "product/getProductBySlug",
  async (query) => {
    const { data: response } = await getProductBySlug(query);

    return response.data;
  }
);

export const getSamagriActiveProductsArr = createAsyncThunk(
  "product/getSamagriActiveProductsArr",
  async (query) => {
    const { data: response } = await getActiveProducts(query);

    return response.data;
  }
);

export const getbestsellerActiveProductsArr = createAsyncThunk(
  "product/getbestsellerActiveProductsArr",
  async (query) => {
    const { data: response } = await getActiveProducts(query);

    return response.data;
  }
);

export const getArrivalActiveProductsArr = createAsyncThunk(
  "product/getArrivalActiveProductsArr",
  async (query) => {
    const { data: response } = await getActiveProducts(query);
    return response.data;
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsBestSeller: [],
  itemsArrival: [],
  itemsSamagri: [],
  itemsObj: {},
};

export const productSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(getProductObjBySlug.fulfilled, (state, action) => {
      state.itemsObj = action.payload;
    });

    builder.addCase(
      getbestsellerActiveProductsArr.fulfilled,
      (state, action) => {
        state.itemsBestSeller = action.payload;
      }
    );

    builder.addCase(getArrivalActiveProductsArr.fulfilled, (state, action) => {
      state.itemsArrival = action.payload;
    });
    
    builder.addCase(getSamagriActiveProductsArr.fulfilled, (state, action) => {
      state.itemsSamagri = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default productSlice.reducer;
