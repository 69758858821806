import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/subscription";

export const getSubscriber = (query) => {
    return axiosApiInstance.get(`${serverUrl}?${query}`);
};


// export const addSubscriber = (formData) => {
//     return axiosApiInstance.post(`${serverUrl}/${formData}`);
// };


export const addSubscriber = (formData) => {
    return axiosApiInstance.post(serverUrl + "/add", formData);
  };
  