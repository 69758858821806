import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addWishlist,
  getWishlist,
  removePRoductWishlist,
} from "../services/wishlist.service";
import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "../utils/toastUtils";
///////////async functions starts here
export const fetchWishlist = createAsyncThunk(
  "wishlist/getWishlist",
  async (query) => {
    try {
      const { data: response } = await getWishlist(query);
      // console.log(response, "getWishlist-redux");
      return response.data;
    }
    catch (err) {
      toastError(err)
    }
  }
);
export const addOrRemoveWishlist = createAsyncThunk(
  "wishlist/addOrRemoveWishlist",
  async (obj) => {
    try {

      const { data: response } = await addWishlist(obj);
      // console.log(response, "add wishlist-redux")
      if (response.message) {
        toastSuccess(response.message)
      }
    }
    catch (err) {
      toastError(err)
    }
  }
);
export const removePRoductFromWishlist = createAsyncThunk(
  "wishlist/removeProductFromWishlist",
  async (id, { dispatch }) => {
    try {
      const { data: response } = await removePRoductWishlist(id);
      if (response.success) {
        dispatch(fetchWishlist())
      }
      return response.message
    }
    catch (err) {
      toastError(err)
    }
  }
);
///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const wishlistSlice = createSlice({
  name: "Wishlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWishlist.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default wishlistSlice.reducer;
