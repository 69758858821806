// Faq
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/faqCategory";

export const addFaq = (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getFaqCategory = (query) => {
  return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteFaq = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateFaq = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
