import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { Link } from 'react-router-dom';
import { img } from '../Components/Image';
import { GrView } from "react-icons/gr"
import { AiOutlineHeart } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai"

const Addcart = () => {
    return (
        <>
            <main>
                <div className='container'>
                    <div className='row py-5'>
                        {/* <div className='col-lg-4 addcart-style'>
                            <div className='addcart-image'>

                                <img src={img.card} className='img-fluid' />
                                <h6>Sandalwood </h6>
                                <p>₹ 599/-</p>
                                <div className='addcart-icon'>
                                    <ul>
                                        <li><AiOutlineHeart title='wishlist' /></li>
                                        <li><GrView  title='quick view'/></li>
                                    </ul>
                                </div>
                                <div className='cart-button'>
                                    <ul>
                                        <li><AiOutlineShoppingCart /><p>Add to cart</p></li>
                                    </ul>
                                </div>

                            </div>

                        </div> */}
                        <div className='col-lg-3'>
                            <div className='prode_detaisnewcard'>
                                <div className='cart_position'>
                                    <img src={img.card} className='img-fluid' />
                                    <div className='addcart-icon'>
                                        <ul>
                                            <li><AiOutlineHeart title='wishlist' /></li>
                                            <li><GrView title='quick view' /></li>
                                        </ul>
                                    </div>
                                    <div className='adtocartbtn'>
                                        <p><AiOutlineShoppingCart /> Add to Cart</p>
                                    
                                    </div>
                                    
                                </div>
                                <div className='para-info'>
                                    <h6>Sandalwood</h6>
                                        <p>₹ 599/-</p>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </>
    )
}

export default Addcart





