import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/category";

export const addCategory = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addCategory", formData);
};

export const getCategory = (query) => {
  // console.log(query,'query23---service 4')
  return axiosApiInstance.get(`${serverUrl}/getCategory?${query}`);
};


export const getNameById = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getNameById/${query}`);
};



export const deleteCategory = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};



export const updateCategory = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const getNestedCategories = () => {
  return axiosApiInstance.get(`${serverUrl}/getNestedCategories`);
};

export const saveCategoryposition = (id, formData) => {
  return axiosApiInstance.post(`${serverUrl}/saveCategoryposition`, formData);
};

export const getNameBySlug = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getNameBySlug/${query}`);
};

export const getCategoryAndProductBySlug=(query)=>{
  return  axiosApiInstance.get(`${serverUrl}/getCategoryAndProductBySlug?slug=${query}`)
}