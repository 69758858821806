import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getBlog, getBySlug } from "../services/blog.service";

// import {  getTestimonials,} from "../services/testimonial.service";

///////////async functions starts here
export const fetchBlogs = createAsyncThunk("blog/fetchBlogs", async (query) => {
  const { data: response } = await getBlog(query);
  return response.data;
});

export const getBySlugBlog = createAsyncThunk(
  "blog/fetchBySlug",
  async (query) => {
    const { data: response } = await getBySlug(query);
    return response.data;
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const blogSlice = createSlice({
  name: "Blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlogs.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(getBySlugBlog.fulfilled, (state, action) => {
      state.itemsObj = action.payload;
    });
  },
});

export default blogSlice.reducer;
