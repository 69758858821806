import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";
const serverUrl = url + "/productReview";


export const getProductReview = () => {
    return axiosApiInstance.get(`${serverUrl}/getForUser`);
};

export const upadteReviewStatus = (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/upadteReviewStatus/${id}`, obj);
};

export const AddReviewApi = async (obj) => {
    return axiosApiInstance.post(`${serverUrl}/`, obj)
}