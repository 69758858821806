import { img } from "../Components/Image";
import { FaUserAlt } from "react-icons/fa";
import { AiFillCalendar } from "react-icons/ai";

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsHeart } from "react-icons/bs";
// import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Link, useParams } from "react-router-dom";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { toastError } from "../utils/toastUtils";
import { generateFilePath } from "../utils/filepath";

import { getBySlug, getBlog } from "../services/blog.service";

import { fetchBlogs, getBySlugBlog } from "../redux/Blog.slice";
import { useDispatch, useSelector } from "react-redux";


const SingleBlog = () => {
  const dispatch = useDispatch()
  const params = useParams();
  const [blogObject, setBlogObject] = useState({});
  const [popularBlogArr, setPopularBlogArr] = useState([]);
  const blogData = useSelector((state) => state.blog.items);
  const blogBySlugData = useSelector((state) => state.blog.itemsObj);

  const handleInit = async () => {
    dispatch(fetchBlogs());

    // let { data: res } = await getBlog();itemsObj
    // if (res.success) {
    //   let blog = res.data.filter((x, i) => x.popular == true);
    //   setPopularBlogArr(blog);
    //   // console.log(blog,"blog12")
    // }
  };

  const handleGetBlog = async () => {
    try {
      dispatch(getBySlugBlog(params.slug));
      // let { data: res } = await getBySlug(params.slug);
      // console.log(res, "blogbu slug");
      // if (res.success) {
      //   setBlogObject(res.data);
      // }
    } catch (err) {
      toastError(err);
    }
  };
  // console.log(params.slug,"aoramsn12")


  useEffect(() => {
    if (blogBySlugData) {
      setBlogObject(blogBySlugData);
    }
  }, [blogBySlugData]);




  useEffect(() => {
    if (blogData) {
      let blog = blogData.filter((x, i) => x.popular == true);
      setPopularBlogArr(blog);
      // console.log(blog,"blog12")
    }
  }, [blogData]);

  useEffect(() => {
    handleGetBlog();
    handleInit();
  }, [params]);

  return (
    <main>
      {/* <section className="bolg-single">
        <div className="container-fluid">
          <div className="row heading-style py-3">
            <h3>  </h3>
          </div>
        </div>
      </section> */}
      <div className="banner_category">
          <div className="overlay1"></div> 
          <h4>{blogObject?.title}</h4>
     </div>

      <section className="ptb-80 bgyellowlight">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10 singleblogimg p-style">
              <img
                src={generateFilePath(blogObject?.image)}
                className="img-fluid"
              />
              {/* <h4 className="mt-4">{blogObject?.title}</h4> */}
              <p className="pt-4" dangerouslySetInnerHTML={{
                __html: blogObject?.description,
              }}>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default SingleBlog;
