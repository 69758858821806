import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/new.css";
import "./assets/css/style.css";

import Footer from "./Components/Footer";
import Header from "./Components/Header";

import { useDispatch, useSelector } from "react-redux";

import { createContext, useEffect, useState } from "react";
import { loginRedux } from "./redux/Auth.slice";
import { routes } from "./Routes/Routes";
import { getLoggedInUser, getToken } from "./services/users.service";

export const cartContext = createContext();
export const currencyContext = createContext();
export const loginUserContext = createContext();
export const isAuthorisedContext = createContext();
export const productCategoryObjectContext = createContext();

function App() {
  const dispatch = useDispatch();
  const userIsLoggedIn = useSelector((state) => state.auth.isAuthorised);
  const [cartNumberOfProduct, setcartNumberOfProduct] = useState(0);
  const [currencyDetails, setCurrencyDetails] = useState({});
  const [AuthRoutes, setAuthRoutes] = useState([]);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [loginDetails, setLoginDetails] = useState("");

  const [productCategoryObj, setproductCategoryObj] = useState({});

  const checkAuthorisedAndSetDefaults = () => {
    let token = getToken();
    let user = getLoggedInUser();
    // console.log(user, token, "user");
    if (user && user != "" && token && token != "") {
      // console.log("user1");
      user = JSON.parse(user);
      // console.log("user2");
      dispatch(loginRedux({ token, user }));
    }
    // let cart = getCartFromLocalStorage()
    // cart = JSON.parse(cart)
    // dispatch(replaceCart(cart))
  };

  useEffect(() => {
    checkAuthorisedAndSetDefaults();
  }, []);

  useEffect(() => {
    handleReturnRoutes();
  }, [userIsLoggedIn]);

  const handleReturnRoutes = () => {
    let tempArr = routes;
    tempArr = tempArr.filter((el, index) => {
      if (userIsLoggedIn) {
        if (el.authIndependent === true || el.isAuthorised === true) {
          return el;
        }
      } else {
        if (el.authIndependent || el.isAuthorised === false) {
          return el;
        }
      }
    });
    setAuthRoutes(tempArr);
  };

  return (
    <BrowserRouter>
      <productCategoryObjectContext.Provider
        value={[productCategoryObj, setproductCategoryObj]}
      >
        <isAuthorisedContext.Provider value={[isAuthorised, setIsAuthorised]}>
          <loginUserContext.Provider value={[loginDetails, setLoginDetails]}>
            <cartContext.Provider
              value={[cartNumberOfProduct, setcartNumberOfProduct]}
            >
              <currencyContext.Provider
                value={[currencyDetails, setCurrencyDetails]}
              >
                <Toaster />
                <Header />
                
                <Routes>
                  {AuthRoutes &&
                    AuthRoutes.length > 0 &&
                    AuthRoutes.map((el, index) => {
                      return (
                        <Route
                          key={index}
                          path={el.name}
                          element={el.component}
                        />
                      );
                    })}

                  {/* <Route
                  path="About" element={<About />} />
                <Route
                  path="Blog" element={<Blog />} />
                <Route
                  path="/Blog/:slug" element={<SingleBlog />} />
                <Route
                  path="/Category/:slug" element={<Category />} />
                <Route
                  path="/Product/:slug" element={<Product />} />
                <Route
                  path="/Shopingcart" element={<Shopingcart />} />
                <Route
                  path="Shiping" element={<Shiping />} />
                <Route
                  path="Shiping2" element={<Shiping2 />} />
                <Route
                  path="Contact" element={<Contact />} />
                <Route
                  path="Faqs" element={<Faqs />} />
                <Route
                  path="TremsCon" element={<TremsCon />} />
                <Route
                  path="Privacy" element={<Privacy />} />
                <Route
                  path="Addcart" element={<Addcart />} />
                <Route
                  path="MyProfile" element={<MyProfile />} />
                <Route
                  path="OrderDetail" element={<OrderDetail />} />
                <Route
                  path="Wallet" element={<Wallet />} />
                <Route
                  path="checkout" element={<Checkout />} />
                <Route
                  path="Wishlist" element={<Wishlist />} />
                <Route
                  path="Register" element={<Register />} />
                <Route
                  path="Login" element={<Login />} />
                {
                  isAuthorised ?
                    <>
                    </>
                    :
                    <>

                    </>
                } */}
                </Routes>
              </currencyContext.Provider>
            </cartContext.Provider>
          </loginUserContext.Provider>
        </isAuthorisedContext.Provider>
      </productCategoryObjectContext.Provider>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
