import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";

import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { img } from "./Image";

import { useDispatch, useSelector } from "react-redux";
import { currencyContext } from "../App";
import { logoutRedux } from "../redux/Auth.slice";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { fetchCurrency } from "../redux/currency.slice";
import { emptyCart, fetchcart } from "../redux/Cart.slice";
import { isAuthorisedContext } from "../App";
import { generateFilePath } from "../utils/filepath";
import { getSearchProduct } from "../services/Product1.service";
// import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const userIsLoggedIn = useSelector((state) => state.auth.isAuthorised);
  const currencyArray = useSelector((state) => state.currency.items);
  const cartItems = useSelector((state) => state.cart.items);
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  // const [isAuthorised, setIsAuthorised] = useContext(isAuthorisedContext);

  const dispatch = useDispatch();
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const [currencyArr, setCurrencyArr] = useState([]);
  const [cartTotalItems, setCartTotalItems] = useState("");
  const [searchBarShow, setSearchBarShow] = useState(false);
  const [query, setQuery] = useState("");
  const [searchPRoduct, setSearchPRoduct] = useState([]);

  const handleGetCurrency = async () => {
    dispatch(fetchCurrency());
  };

  useEffect(() => {
    if (currencyArr && currencyArr.length > 0) {
      setCurrencyDetails(currencyArr[0]);
    }
  }, [currencyArr]);

  const handleSelectCurrency = (e) => {
    try {
      // e.preventDefault(e);
      let obj = { ...e, label: e.code, value: e._id };
      setCurrencyDetails(obj);
      setSelectedOption(obj);
      obj = JSON.stringify(obj);
      localStorage.setItem("currencyObj", obj);
      setSelectIsOpen(!selectIsOpen);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(cartItems, "cartItems234");
    if (userIsLoggedIn) {
      if (cartItems && cartItems.length >= 0) {
        setCartItemsCount(cartItems.reduce((acc, el) => acc + el?.quantity, 0));
        setCartTotalItems(cartItems?.length);
      } else {
        setCartItemsCount(0);
        setCartTotalItems(0);
      }
    } else {
      setCartItemsCount(cartItems?.length);
      setCartTotalItems(cartItems?.length);
    }
  }, [cartItems, userIsLoggedIn]);

  useEffect(() => {
    handleGetCurrency();
  }, []);

  useEffect(() => {
    dispatch(fetchCurrency());
    if (userIsLoggedIn) {
      dispatch(fetchcart());
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    // console.log("123123", currencyArray)
    if (currencyArray && currencyArray.length) {
      setCurrencyArr(currencyArray);
    }
  }, [currencyArray]);

  const handleLogout = () => {
    dispatch(logoutRedux());
    dispatch(emptyCart());
    // setIsAuthorised(false)
    toastSuccess("Logged out");
    navigate("/");
  };

  const [showSearch, setshowSearch] = useState(true);
  const [selectedOption, setSelectedOption] = useState(["IND", img.flag4]);
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const [placeholderText, setplaceholderText] = useState("placeholderText");
  const [Currency, setCurrency] = useState([
    {
      label: "USD",
      value: 1,
      active: false,
      img: img.flag1,
    },
    {
      label: "INR",
      value: 2,
      active: false,
      img: img.flag2,
    },
    {
      label: "EUR",
      value: 3,
      active: false,
      img: img.flag2,
    },
    {
      label: "AUD",
      value: 4,
      active: false,
      img: img.flag3,
    },
    {
      label: "AED",
      value: 5,
      active: false,
      img: img.flag4,
    },
    // {
    //   label: "GBP",
    //   value: 6,
    //   active: false,
    //   img: img.flag1,
    // },
  ]);

  const handleSetSelectedOption = (obj) => {
    // console.log(obj, "obj");
    // setSelectedOption([labell:obj.label, obj.img]);
    setSelectIsOpen(!selectIsOpen);
  };

  const handleDropDownIsOpen = () => {
    return setSelectIsOpen(!selectIsOpen);
  };

  const handleSearchBar = (e) => {
    try {
      setSearchBarShow(!searchBarShow);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    if (searchBarShow == false) {
      setQuery("");
      setSearchPRoduct([]);
    }
  }, [searchBarShow]);

  const handleGEtSearchProduct = async () => {
    try {
      let { data: get } = await getSearchProduct(query);
      // console.log(get, "search get product");
      setSearchPRoduct(get?.data);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (query?.trim()?.length != 0) {
      handleGEtSearchProduct();
    }
  }, [query]);

  const handleSelectProduct = (e) => {
    try {
      setQuery("");
      setSearchBarShow(false);
      setSearchPRoduct([]);
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <header>
      <Navbar expand="lg" className="navbar_top bg-white navbartop px-4pc">
        <Container fluid className="p-0">
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex justify-content-between align-items-center">
              <div className="logo_cen">
                <Link to="/" className="main_logo">
                  <img src={img.weblogo} alt="" className="img-fluid" />
                </Link>
              </div>
              <Nav>
                <Link className="nav-link h " to="/">
                  Home
                </Link>
                <Link className="nav-link h " to="/Category/All">
                  Category
                </Link>
                <Link className="nav-link h " to="/About">
                  About Us
                </Link>
                <Link className="nav-link h " to="/Blog">
                  Blog
                </Link>
              </Nav>

              <div className="socal_link">
                <ul className="right-icons inlinedropdwonmeni m-0 p-0">
                  {searchBarShow && (
                    <input
                      className=""
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        e.preventDefault();
                        setQuery(e.target.value);
                      }}
                      value={query}
                    />
                  )}
                  <span className="nav-logo">
                    <AiOutlineSearch onClick={(e) => handleSearchBar(e)} />
                    <div className="searchproduct ">
                      {searchPRoduct && searchPRoduct.length ?(
                        <ul className="searcfiltershow">
                          {searchPRoduct?.map((el, index) => {
                            return (
                              <Link
                                key={index}
                                to={`/Product/${el?.slug}`}
                                onClick={(e) => handleSelectProduct(e)}
                              >
                                <li>{el?.name?el?.name:""}</li>
                              </Link>
                            );
                          })}
                        </ul>
                      ):""}
                    </div>
                  </span>


                  <span className="nav-logo profile_btn">
                    <div className="myProfle">
                      <ul>
                        {!userIsLoggedIn && (
                          <li>
                            <Link to="/Login">Login</Link>
                          </li>
                        )}

                        {/* {
                          !userIsLoggedIn &&
                          <li>
                            {" "}
                            <Link to="/Register">Register</Link>
                          </li>
                        } */}
                        {userIsLoggedIn && (
                          <div
                            className="cart_dropdownhover"
                            style={{ zIndex: "9999999999 !important" }}
                          >
                            <li>
                              {" "}
                              <Link to="/MyProfile">MyProfile </Link>
                            </li>
                            <li>
                              <Link to="/OrderDetail">Orders </Link>
                            </li>
                            <li>
                              <Link to="/Wallet">Wallet </Link>
                            </li>

                            <li onClick={() => handleLogout()}>
                              <Link>Logout</Link>
                            </li>
                          </div>
                        )}
                      </ul>
                    </div>

                    <Link className="dropdown-hover">
                      <FaUserCircle />
                    </Link>
                  </span>
                  {userIsLoggedIn && (
                    <span className="nav-logo" value={"1231"}>
                    <Link to="/Wishlist">
                      <AiOutlineHeart />
                    </Link>
                  </span>
                    )}

                  <span className="nav-logo">
                    <Link to="/Shopingcart">
                      <AiOutlineShoppingCart />
                      &nbsp;({cartTotalItems})
                    </Link>
                  </span>
                  {/* <select
                    className="nav-select"
                    onChange={(e) => handleSelectCurrency(e)}
                  >
                    {currencyArr?.length > 0 &&
                      currencyArr?.map((x, i) => {
                        const optionValiue = JSON.stringify({
                          convertRate: x?.convertRate,
                          symbol: x?.symbol,
                          code: x?.code,
                          _id: x?._id, name: x?.name

                        });
                        return (
                          <option key={i} value={optionValiue}>
                            {x?.code}
                          </option>
                        );
                      })}
                  </select> */}

                  <span>
                    <div className="d-flex align-items-center gap-2">
                      <div className="custum-select">
                        <div className="header-select-style btn btn-custom btn-sm">
                          <div
                            onClick={() => handleDropDownIsOpen()}
                            className="d-flex align-items-center"
                          >
                            <div className="custum-select-text">
                              <p className="mb-0">
                                {selectedOption?.label
                                  ? selectedOption.label
                                  : "Please Select an Option"}
                              </p>
                              <img
                                src={generateFilePath(selectedOption?.image)}
                                alt=""
                                className="flag"
                              />
                            </div>
                            {/* <img src={images.down_arrow} className="down-arrow" /> */}
                          </div>
                        </div>

                        {selectIsOpen && (
                          <ul className="custum-select-menu">
                            {currencyArr && currencyArr.length > 0 ? (
                              currencyArr.map((el, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => handleSelectCurrency(el)}
                                    className={el.active ? "active" : ""}
                                  >
                                    {el.code}
                                    <img
                                      src={generateFilePath(el?.image)}
                                      alt=""
                                      className="flag"
                                    />
                                  </li>
                                );
                              })
                            ) : (
                              <div>No options found</div>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </span>
                </ul>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
