import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../services/users.service";
import {
  getAddress,
  addAddress,
  deleteAddress,
  updateAddress,
} from "../services/UserAddress.service";
///////////async functions starts here
import { getUser, updateUser, changePassword } from "../services/users.service";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { useDispatch } from "react-redux";

export const getUserAddress = createAsyncThunk(
  "userAddress/getUserAddress",
  async (id) => {
    try {
      const { data: response } = await getAddress(id);
      if (response?.success) {
        toastSuccess(response?.message);
      }
      return response?.data;
    } catch (err) {
      toastError(err);
      throw err;
    }
  }
);
export const setUserData =  createAsyncThunk("userAddress/setUserAddress",
 async (obj) => {
    // console.log(obj,"boject213")
   return obj
  });
export const DeleteUserAddress = createAsyncThunk(
  "userAddress/DeleteUserAddress",
  async (obj, { dispatch }) => {
    try {
      // console.log(obj,"boject2131")
      const { data: response } = await deleteAddress(obj?._id);
      if (response?.success) {
        toastSuccess(response?.message);
        dispatch(getUserAddress(obj?.userId));
      }
      return response?.data;
    } catch (err) {
      toastError(err);
      throw err;
    }
  }
);

export const userAddressAdd = createAsyncThunk(
  "userAddress/AddUserAddress",
  async (obj, { dispatch }) => {
    try {
      const { data: response } = await addAddress(obj, obj?.userId);
      if (response?.success) {
        dispatch(getUserAddress(obj?.userId));
        toastSuccess(response?.message);
      }
      return response?.data;
    } catch (err) {
      toastError(err);
      throw err;
    }
  }
);
export const updateUserAddress = createAsyncThunk(
  "userAddress/updateUserAddress",
  async (obj, { dispatch }) => {
    try {
      //  console.log(obj,"12312313132123")
      const { data: response } = await updateAddress(obj, obj._id);
      if (response?.success) {
        toastSuccess(response?.message);
        dispatch(getUserAddress(obj?.userId));
      }
      return response?.data;
    } catch (err) {
      toastError(err);
      throw err;
    }
  }
);

///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const userAddressSlice = createSlice({
  name: "UserAddress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAddress.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(setUserData.fulfilled, (state, action) => {
      // console.log(action.payload,"Axtion21")
      state.itemsObj = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { } = productSlice.actions

export default userAddressSlice.reducer;
