import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import authReducer from "./Auth.slice";
import cartReducer from "./Cart.slice";
import categoryReducer from "./Category.slice";
import productReducer from "./Product.slice";
import galleryReducer from "./Gallery.slice";
import BannerReducer from "./Banner.slice";
import AboutUsReducer from "./AboutUs.slice";
import BlogReducer from "./Blog.slice";
import BlogCategoryReducer from "./BlogCategory.slice";
import faqCategoryReducer from "./faqCategory.slice";
import currencySlice from "./currency.slice";
import faqReducer from "./faq.slice";
import userReducer from "./user.slice";
import userAddressReducer from "./userAddress.slice";
import wishlistReducer from "./wishlist.slice";
import companyAddressReducer from "./CompanyAddress.slice";
import OrderSliceReducer from "./Order.slice"
import userWalletSliceReducer from "./userWallet.slice"
import attributeSliceReducer from "./Attribute.slice"



const reducers = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  product: productReducer,
  category: categoryReducer,
  gallery: galleryReducer,
  banner: BannerReducer,
  aboutUs: AboutUsReducer,
  blog: BlogReducer,
  blogCategory: BlogCategoryReducer,
  faqCategory: faqCategoryReducer,
  faq: faqReducer,
  currency: currencySlice,
  user: userReducer,
  userAddress: userAddressReducer,
  wishlist: wishlistReducer,
  companyAddress:companyAddressReducer,
  Order:OrderSliceReducer,
  userWallet:userWalletSliceReducer,
  attribute:attributeSliceReducer,
});




const persistConfig = {
  key: "samagri:persisted",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});
