import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCategory } from '../services/category1.service'



///////////async functions starts here

export const fetchcategory = createAsyncThunk(
    'cart/getCategory',
    async (query) => {
        // console.log(query,"querfrom - redux")
        // getCategory( "level=1" );
        const { data: response } = await getCategory(query)

        // console.log(response, "response-categtoy-redux")
        return response.data
    }
)



///////////async functions ends here

const initialState = {
    items: [],
}

export const cartSlice = createSlice({
    name: 'Cart',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchcategory.fulfilled, (state, action) => {
            state.items = action.payload
        })
    }
})

// Action creators are generated for each case reducer function
export const { } = cartSlice.actions

export default cartSlice.reducer