import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../node_modules/swiper/swiper-bundle.css";
import { currencyContext, productCategoryObjectContext } from "../App";
import { img } from "../Components/Image";
import { fetchBanner } from "../redux/Banner.slice";
import { addProductToCart, fetchcart, addItem } from "../redux/Cart.slice";
import { fetchcategory } from "../redux/Category.slice";
import { fetchGallery } from "../redux/Gallery.slice";

import {
  getArrivalActiveProductsArr,
  getbestsellerActiveProductsArr,
} from "../redux/Product.slice";

import { addOrRemoveWishlist } from "../redux/wishlist.slice";
import { generateFilePath } from "../utils/filepath";
import { toastError, toastSuccess } from "../utils/toastUtils";

import Countdown from "react-countdown";
import { getHomepageData } from "../services/Homepage.service";
import { getTodaysDeals } from "../services/todaysDeals.service";
import { getProductReview } from "../services/ProductReview.service";

import { getSubscriber, addSubscriber } from "../services/subscriber.service";

const Index1 = () => {
  const [currencyDetails, setCurrencyDetails] = useContext(currencyContext);
  const [homepageObj, setHomepageObj] = useState({});
  const [todaysDeals, setTodaysDeals] = useState([]);
  const [subscriber, setSubscriber] = useState("");

  // -------------above code yatendra---------

  const [productCategoryObj, setproductCategoryObj] = useContext(
    productCategoryObjectContext
  );

  const [bannerArr, setBannerArr] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [samagriProducts, setSamagriProducts] = useState([]);

  const [testimonialsArr, setTestimonialsArr] = useState([]);
  const [arrivalProducts, setArrivalProducts] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const [gallaryArr, setGallaryArr] = useState([]);

  const [productReview, setProductReview] = useState([]);
  const [discountedValue, setDiscountedValue] = useState("");

  const isAuth = useSelector((state) => state.auth.isAuthorised);
  const userAuthorise = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.category.items);
  const galleryData = useSelector((state) => state.gallery.items);
  const bannerData = useSelector((state) => state.banner.items);

  const activeBestSellerProductData = useSelector(
    (state) => state.product.itemsBestSeller
  );
  const activeSamagriProductData = useSelector(
    (state) => state.product.itemsSamagri
  );
  const activeItemsArrivalProductData = useSelector(
    (state) => state.product.itemsArrival
  );

  useEffect(() => {
    // console.log(cart, "cartObj.items")
    if (isAuth) {
      dispatch(fetchcart());
    }
    // }
  }, [isAuth]);

  // Shuffle the objects in the `data` array
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Shuffle the array multiple times to ensure all elements are shuffled
  function shuffleArray(array, numShuffles = 3) {
    let shuffledArray = [...array];
    for (let i = 0; i < numShuffles; i++) {
      shuffledArray = shuffle(shuffledArray);
    }
    return shuffledArray;
  }

  useEffect(() => {
    if (activeItemsArrivalProductData && activeItemsArrivalProductData.length) {
      let data = [...activeItemsArrivalProductData];
      let temp = shuffleArray(data);
      setArrivalProducts(temp);
    }

    if (activeSamagriProductData && activeSamagriProductData.length) {
      let data = [...activeSamagriProductData];
      let temp = shuffleArray(data);
      setSamagriProducts(temp);
    }

    if (activeBestSellerProductData) {
      let data = [...activeBestSellerProductData];
      let temp = shuffleArray(data);
      setBestSellerProducts(temp);
    }
  }, [
    activeBestSellerProductData,
    activeSamagriProductData,
    activeItemsArrivalProductData,
  ]);

  useEffect(() => {
    if (categoryData && categoryData.length) {
      setCategoryArr(categoryData);
    }
  }, [categoryData]);

  useEffect(() => {
    if (galleryData && galleryData.length) {
      setGallaryArr(galleryData);
    }
  }, [galleryData]);

  useEffect(() => {
    if (bannerData && bannerData.length) {
      setBannerArr(bannerData);
    }
  }, [bannerData]);

  const handleGetHomepageData = async () => {
    try {
      let { data: res } = await getHomepageData();
      if (res.data) {
        setHomepageObj(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetTodaysDeals = async () => {
    try {
      let today = new Date();
      let { data: res } = await getTodaysDeals(`today=${today}`);
      // console.log(res.data, "=***=---data33 get ");
      if (res.data) {
        // console.log(res.data, "data33 get ");
        let filterData = res.data?.filter((el) => el.productObj?.name);
        // console.log(filterData, " get filterData123");
        setTodaysDeals(filterData);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleSendSubscriber = async (e) => {
    e.preventDefault();
    try {
      if (subscriber == "") {
        toastError("Email is mandatory");
        return;
      }
      let obj = {
        email: subscriber,
      };
      let { data: getResponse } = await addSubscriber(obj);
      // console.log(getResponse, "getResponse2354234");
      if (getResponse?.message) {
        toastSuccess(getResponse?.message);
        setSubscriber("");
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handlegetBuyReview = async () => {
    try {
      let { data: res } = await getProductReview();
      // console.log(res?.data,"Res123")
      setProductReview(res?.data);
    } catch (err) {
      toastError(err);
    }
  };

  const handleInit = () => {
    dispatch(getArrivalActiveProductsArr(`productDisplay=arrival`));
    dispatch(getbestsellerActiveProductsArr("productDisplay=bestseller"));
    dispatch(fetchBanner("status=true"));
    dispatch(fetchcategory("level=1&showOnHomePage=true"));
    dispatch(fetchGallery());
    handleGetHomepageData();
    handleGetTodaysDeals();
    handlegetBuyReview();
  };

  useEffect(() => {
    handleInit();
    window.scrollTo(0, 0);
  }, []);

  const handleAddProductToWishlist = (e, el) => {
    try {
      // console.log(el, "====ekkekekekekekeklekl");
      e.preventDefault();
      let obj = {
        productId: el?._id,
        productVariantId: el?.attributesArr[0]?._id,
        productVariantName: el?.attributesArr[0]?.name,
        productVariantSellingPrice: el?.attributesArr[0]?.price,
        userId: userAuthorise?.user?._id,
        productVariantStock: el?.attributesArr[0]?.currentStock,
      };
      // console.log(obj, "boject234");

      dispatch(addOrRemoveWishlist(obj));
      dispatch(
        getArrivalActiveProductsArr(
          `productDisplay=arrival&userId=${userAuthorise?.user?._id}`
        )
      );
      // console.log(el, "eleleleel");
    } catch (err) {
      toastError(err);
    }
  };

  const handleAddProductToCart = async (e, productObject) => {
    try {
      // console.log(productObject, "==-=productObject");
      e.preventDefault();
      let obj = {
        ...productObject,
        // price: productObject?.sellingPrice,
        image:
          productObject?.imageArr &&
          productObject?.imageArr.length > 0 &&
          productObject?.imageArr[0]?.image,
        id: productObject._id,
        productId: productObject._id,
        productVariantId: productObject?.attributesArr[0]?._id,
        variantObj: {
          variantId: productObject?.attributesArr[0]?._id,
          currentStock: productObject?.attributesArr[0]?.currentStock,
          name: productObject?.attributesArr[0]?.name,
          price: productObject?.attributesArr[0]?.price,
        },
        userId: userAuthorise?.user?._id,
        quantity: 1,
        currencyObj: currencyDetails,
      };

      // console.log(obj, "==--=0 object");

      if (isAuth) {
        dispatch(addProductToCart(obj));
      } else {
        dispatch(addItem(obj));
      }
    } catch (err) {
      toastError(err);
    }
  };

  const percentageCalculation = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };

  const handleGetDealsFinalPrice = (obj) => {
    try {
      let result = 0;

      // if ((obj.discountType == "PERCENTAGE_OFF")) {//old one
      //   result =
      // obj?.productObj?.sellingPrice -
      // percentageCalculation(
      //   parseInt(obj?.discountValue),
      //       obj?.productObj?.sellingPrice
      //       );
      // } else {
      //   result = obj?.productObj?.sellingPrice - parseInt(obj?.discountValue);
      // }
      // console.log(obj,"object===== get ")

      if (obj?.sellingPrice) {
        if (obj.discountType == "PERCENTAGE_OFF") {
          result =
            obj?.sellingPrice -
            percentageCalculation(
              parseInt(obj?.discountValue),
              obj?.sellingPrice
            );
        } else {
          result = obj?.sellingPrice - parseInt(obj?.discountValue);
        }
      }
      // setDiscountedValue(parseFloat(result * currencyDetails?.convertRate).toFixed(2))
      // console.log( parseFloat( result*currencyDetails?.convertRate).toFixed(2),"convertRate get")
      return parseFloat(result * currencyDetails?.convertRate).toFixed(2);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <div className="hometopslider">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          loop
          speed={2000}
          modules={[Autoplay, Navigation]}
          navigation
          autoplay
        >
          {/* {console.log(bannerArr, "banner  get ")} */}

          {bannerArr &&
            bannerArr.length > 0 &&
            bannerArr.map((el, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link to={el?.url}>
                    <img
                      src={
                        el?.image ? generateFilePath(el?.image) : img?.banner
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>

      <div className="homeslider"></div>

      <section className="category_section fontjost containerpadding pt-50 pb-50">
        <div className="container-fluid">
          <div className="row headingbootm align-items-center">
            <div className="col-lg-6">
              <div className="heading_sedtion">
                <h3>Categories</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="viewall">
                {/* <Link to="#">View All</Link> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="product_card effect-2">
                <img
                  src={
                    homepageObj?.obj?.category1Obj?.categoryImage
                      ? generateFilePath(
                          homepageObj?.obj?.category1Obj?.categoryImage
                        )
                      : img?.productcategroy
                  }
                  alt=""
                  className="img-fluid"
                />
                <div className="prduct_card2caption">
                  <h4>{homepageObj?.obj?.category1Obj?.name}</h4>
                  <h5>{homepageObj?.obj?.category1Obj?.heading}</h5>
                  <p>{homepageObj?.obj?.category1Obj?.description}</p>
                  <Link
                    onClick={() =>
                      setproductCategoryObj(homepageObj?.obj?.category1Obj)
                    }
                    to={`/Category/${homepageObj?.obj?.category1Obj?.slug}`}
                  >
                    Shop Now <i className="fa fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="position-relative effect-2 prduct_card2">
                    <img
                      src={
                        homepageObj?.obj?.category2Obj?.categoryImage
                          ? generateFilePath(
                              homepageObj?.obj?.category2Obj?.categoryImage
                            )
                          : img?.productcategroy
                      }
                      alt=""
                      className="img-fluid"
                    />
                    <div className="prduct_card2caption">
                      <h4>{homepageObj?.obj?.category2Obj?.name}</h4>
                      <h5>{homepageObj?.obj?.category2Obj?.heading}</h5>
                      <p>{homepageObj?.obj?.category2Obj?.description}</p>
                      <Link
                        onClick={() =>
                          setproductCategoryObj(homepageObj?.obj?.category2Obj)
                        }
                        to={`/Category/${homepageObj?.obj?.category2Obj?.slug}`}
                      >
                        Shop Now <i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* {console.log(homepageObj, "homepageObj2343")} */}
                <div className="col-lg-12">
                  <div className="position-relative effect-2 mt-4 prduct_card2 ">
                    <img
                      src={
                        homepageObj?.obj?.category3Obj?.categoryImage
                          ? generateFilePath(
                              homepageObj?.obj?.category3Obj?.categoryImage
                            )
                          : img?.productcategroy
                      }
                      className="img-fluid"
                    />
                    <div className="prduct_card2caption">
                      <h4>{homepageObj?.obj?.category3Obj?.name}</h4>
                      <h5>{homepageObj?.obj?.category3Obj?.heading}</h5>
                      <p>{homepageObj?.obj?.category3Obj?.description}</p>
                      <Link
                        onClick={() =>
                          setproductCategoryObj(homepageObj?.obj?.category3Obj)
                        }
                        to={`/Category/${homepageObj?.obj?.category3Obj?.slug}`}
                      >
                        Shop Now <i className="fa fa-long-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="containerpadding ptb-80">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-lg-3 col-sm-6 col-md-6 col-6">
              <div className="card_payment">
                <div>
                  <img src={img?.paymenticon1} alt="" className="img-fluid" />
                </div>
                <div className="contentpayment">
                  <h3>Free Shipping</h3>
                  <p>Free Shipping for orders over </p>
                  <p>
                    {currencyDetails?.symbol}{" "}
                    {parseFloat(1000 * currencyDetails?.convertRate).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-sm-6 col-md-6 col-6">
              <div className="card_payment">
                <div>
                  <img src={img?.paymenticon2} alt="" className="img-fluid" />
                </div>
                <div className="contentpayment">
                  <h3>Money Guarantee</h3>
                  <p>Within 30 days for an exchange.</p>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-sm-6 col-md-6 col-6">
              <div className="card_payment">
                <div>
                  <img src={img?.paymenticon3} alt="" className="img-fluid" />
                </div>
                <div className="contentpayment">
                  <h3>Online Support</h3>
                  <p>Within 30 days for an exchange.</p>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-sm-6 col-md-6 col-6">
              <div className="card_payment">
                <div>
                  <img src={img?.paymenticon4} alt="" className="img-fluid" />
                </div>
                <div className="contentpayment">
                  <h3>Flexible Payment</h3>
                  <p>Pay with Multiple Credit Cards.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="containerpadding fontjost">
        <div className="category_section pt-50 pb-50">
          <div className="container-fluid">
            <div className="row headingbootm align-items-center">
              <div className="col-lg-6">
                <div className="heading_sedtion">
                  <h3>Best Seller Products</h3>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="viewall">
                  <Link to="/Category/All">View All</Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={5}
                  loop
                  speed={2000}
                  modules={[Autoplay, Navigation]}
                  // navigation
                  autoplay
                >
                  {/* {console.log(
                    bestSellerProducts,
                    "bestSellerProducts get 2345"
                  )} */}
                  {bestSellerProducts &&
                    bestSellerProducts.length > 0 &&
                    bestSellerProducts.map((el, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="product_category_box">
                            <div className="procutcate_img">
                              <Link to={`/Product/${el?.slug}`}>
                                <img
                                  src={generateFilePath(el?.imageArr[0]?.image)}
                                  className="img-fluid"
                                />
                              </Link>
                            </div>
                            {/* <div className="offerview">20% off</div> */}
                            <div className="info_caption">
                              <ul className="socal_icon">
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToWishlist(e, el)
                                    }
                                  >
                                    <i className="fa fa-heart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToCart(e, el)
                                    }
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </a>
                                </li>
                              </ul>
                             
                              <h5>{el?.productName.length > 30 ? el?.productName.substring(0, 30) + "..." : el?.productName}</h5>
                              <h7>Variant : {el?.name?.length>30?el?.name?.substring(0,30)+"...":el?.name}</h7>

                              <p className="price_product">
                                <>
                                  {currencyDetails?.symbol} &nbsp;
                                  {parseFloat(
                                    el.price * currencyDetails?.convertRate
                                  ).toFixed(2)}
                                  <br />
                                </>
                              </p>

                              {el?.rating && (
                                <p className="star_iocn">
                                  {[...Array(parseInt(el.rating))].map(
                                    (_, i) => (
                                      <i
                                        key={i}
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  )}
                                  &nbsp; {el?.rating} Reviews
                                </p>
                              )}
                              {/* <p>
                                
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                                            <i
                                  className="fa fa-star graycolor"
                                  aria-hidden="true"
                                ></i>
                              </p> */}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {console.log(homepageObj?.section2?.link1,"homepageObj?.section2?.link1")} */}
      <div className="seciont_img">
        <Link to={homepageObj?.section2?.link1}>
          <img
            src={
              homepageObj?.section2?.image
                ? generateFilePath(homepageObj?.section2?.image)
                : img?.slider2
            }
            alt=""
            className="img-fluid"
          />
        </Link>
        {/* <button>clickh er</button> */}
      </div>
      <section className="containerpadding bg-white fontjost">
        <div className="category_section pt-50 pb-50">
          <div className="container-fluid">
            <div className="row headingbootm align-items-center">
              <div className="col-lg-6">
                <div className="heading_sedtion">
                  <h3>New Arrivals Products</h3>
                  <p>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, */}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="viewall">
                  <Link to="/Category/All">View All</Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={5}
                  loop
                  speed={2000}
                  modules={[Autoplay, Navigation]}
                  // navigation
                  autoplay
                >
                  {/* {console.log(arrivalProducts, "234arrivalProducts")} */}
                  {arrivalProducts &&
                    arrivalProducts.length > 0 &&
                    arrivalProducts.map((el, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="product_category_box">
                            <div className="procutcate_img">
                              <Link to={`/Product/${el?.slug}`}>
                                <img
                                  src={generateFilePath(el?.imageArr[0]?.image)}
                                  className="img-fluid"
                                />
                              </Link>
                            </div>
                            {/* <div className="offerview">20% off</div> */}
                            <div className="info_caption">
                              <ul className="socal_icon">
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToWishlist(e, el)
                                    }
                                  >
                                    <i className="fa fa-heart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/"
                                    onClick={(e) =>
                                      handleAddProductToCart(e, el)
                                    }
                                  >
                                    <i className="fa fa-shopping-cart"></i>
                                  </a>
                                </li>
                                {/* <li>
                                  <a href="#">
                                    <i className="fa fa-random"></i>
                                  </a>
                                </li> */}
                              </ul>
                              {/* {console.log(el?.productName.substring(0, 10),"5555555555555555555")} */}
                             
                              
                              <h5>{el?.productName.length > 30 ? el?.productName.substring(0, 30) + "..." : el?.productName}</h5>
                           
                           <h7>Variant : {el?.name?.length>30?el?.name?.substring(0,30)+"...":el?.name}</h7>

                              <p className="price_product">
                                <>
                                  {currencyDetails?.symbol} &nbsp;
                                  {parseFloat(
                                    el.price * currencyDetails?.convertRate
                                  ).toFixed(2)}
                                  <br />
                                </>
                              </p>

                              {el?.rating && (
                                <p className="star_iocn">
                                  {[...Array(parseInt(el.rating))].map(
                                    (_, i) => (
                                      <i
                                        key={i}
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      >
                                        {" "}
                                      </i>
                                    )
                                  )}
                                  &nbsp; {el.rating} Reviews
                                </p>
                              )}
                              {/* <p>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  className="fa fa-star graycolor"
                                  aria-hidden="true"
                                ></i>
                              </p> */}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="box2category containerpadding fontjost pt-50 pb-50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <Link to={homepageObj?.section3?.link3}>
                <div className="position-relative effect-2 mt-4 prduct_card2 ">
                  <img
                    src={
                      homepageObj?.section3?.image2
                        ? generateFilePath(homepageObj?.section3?.image2)
                        : img?.thali2
                    }
                    className="img-fluid"
                  />
                  <div className="prduct_card2caption">
                    {/* <h4>Thali</h4>
                  <h5>
                  Pooja samagri <br /> accessories
                  </h5>
                <p>Don't miss the opportunity.</p> */}
                  </div>
                  {/* <Link to="#" className="bottom_absolute">
                  Shop Now <i className="fa fa-long-arrow-right"></i>
                </Link> */}
                </div>
              </Link>
            </div>
            <div className="col-lg-6">
              <Link to={homepageObj?.section3?.link4}>
                <div className="position-relative effect-2 mt-4 prduct_card2 ">
                  <img
                    src={
                      homepageObj?.section3?.image3
                        ? generateFilePath(homepageObj?.section3?.image3)
                        : img?.poojathalibottom
                    }
                    className="img-fluid"
                  />
                  <div className="prduct_card2caption1">
                    {/* <h3>
                    Essential indian <br /> <span> Pooja samagri </span> online
                  </h3> */}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="box2category deals_section containerpadding fontjost pt-50 pb-50">
        <div className="container-fluid">
          <div className="row headingbootm align-items-center">
            {todaysDeals && todaysDeals.length > 0 && (
              <div className="col-lg-6">
                <div className="heading_sedtion">
                  <h3>Today’s Deals</h3>
                  <p></p>
                </div>
              </div>
            )}
          </div>
          {console.log(todaysDeals,"todaysDeals ,get ")}
          <div className="row">
            {todaysDeals &&
              todaysDeals.length > 0 &&
              todaysDeals.map((el, index) => {
                return (
                  <div key={index} className="col-lg-6">
                    <div className="deals_card">
                      <div className="dealscared_left">
                        {/* <Link to={`/Product/${el?.productObj?.slug}_${el?.sellingPrice}`}> */}
                        <Link to={`/Product/${el?.productObj?.slug}`}>
                          <div className="img_deals">
                            <img
                              src={
                                el?.productObj &&
                                el?.productObj?.imageArr &&
                                el.productObj?.imageArr.length > 0
                                  ? generateFilePath(
                                      el.productObj.imageArr[0].image
                                    )
                                  : img?.dealsimg1
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="dealscared_right">
                        <h4> {el?.productObj?.name} </h4>
                        <h7> {el?.name} </h7>

                        <h5>
                          <s>
                            {currencyDetails?.symbol + " "}
                            {currencyDetails?.convertRate * el?.sellingPrice +
                              " "}
                          </s>
                          {/* <br /> */}
                          <span>
                            {currencyDetails?.symbol}
                            {handleGetDealsFinalPrice(el)}
                          </span>
                        </h5>
                        {/* {el?.rating && (
                                <p className="star_iocn">
                                  {[...Array(parseInt(el.rating))].map((_, i) => (
                                    <i
                                      key={i}
                                      className="fa fa-star"
                                      aria-hidden="true"
                                    ></i>
                                  ))}
                                 
                                </p>
                              )} */}

                        {el.productObj?.rating && (
                          <p className="star_iocn">
                            {[...Array(parseInt(el.productObj?.rating))].map(
                              (_, i) => (
                                <i
                                  key={i}
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              )
                            )}
                            <span> &nbsp;{el.productObj?.rating} Reviews</span>
                          </p>
                        )}

                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              `${
                                el?.productObj?.description
                                  ? el?.productObj?.description
                                  : ""
                              }`.slice(0, 150) + "...",
                          }}
                        ></p>
                        <h6>Offer end in:</h6>
                        <Countdown
                          date={Date.now() + 100000000}
                          intervalDelay={0}
                          precision={3}
                          className="timerdate"
                        />
                        <p className="avilabel"></p>
                      </div>
                      <div className="bottom_img">
                        <img src={img?.crileimg} alt="" />
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="col-lg-6">
              <div className="deals_card">
                <div className="dealscared_left">
                  <div className="img_deals">
                    <img src={img?.dealsimg2} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="dealscared_right">
                  <h4> Lord Shiva Statue Figurine Blessing Id </h4>
                  <h5>
                    $29.90 – <span> $39.90 </span>
                  </h5>
                  <p className="star_iocn">
                    
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <span> 2 Reviews</span>
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipis cing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore ma
                    ipsum d olor sit amet, consectetur adipiscing elit, sed d o
                    eiusmod tempor incididunt ut labore et dolore maggna aliqua.
                  </p>
                  <h6>Offer end in:</h6>
                  <Countdown
                    date={Date.now() + 10000000}
                    intervalDelay={0}
                    precision={3}
                    className="timerdate"
                  />
                  <p className="avilabel">
                    Available: 8 - Sold: <span> 50 </span>
                  </p>
                </div>
                <div className="bottom_img">
                  <img src={img.crileimg} alt="" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>


      <section className="discount_border containerpadding fontjost pt-50 pb-50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="purchaseorder">
                <p>
                  Super discount for your
                  <span className="boldfirst">first purchase.</span>
                  <span className="bordersolid">FREE15FIRST</span>
                  <span className="usedicount">
                    Use discount code in checkout!
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="review_section containerpadding fontjost pt-50 pb-50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1>Latest Buyers Reviews</h1>
              <p>Our Satisficed customer reviews</p>
            </div>
          </div>
          <div className="row mt-5 ">
            <Swiper
              spaceBetween={50}
              slidesPerView={4}
              loop
              speed={2000}
              modules={[Autoplay, Navigation]}
              navigation
              autoplay
              className="px-5"
            >
              {/* {console.log(productReview, "Res123 get 23")} */}

              {productReview &&
                productReview.length > 0 &&
                productReview.map((el, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="review_slider">
                        <div className="start">
                          {el?.rating && (
                            <p className="star_iocn">
                              {[...Array(el.rating)].map((_, i) => (
                                <i
                                  key={i}
                                  className="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              ))}
                              <span> Reviews</span>
                            </p>
                          )}

                          {/* {el.rating&&} */}

                          {/* <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i> */}
                          {/* <i className="fa fa-star graycolor" aria-hidden="true"></i> */}
                        </div>
                        <p className="review_content"> {el?.message}</p>
                        <p className="review_name">{el?.name} </p>
                      </div>
                    </SwiperSlide>
                  );
                })}

              {/* 
              <SwiperSlide>
                <div className="review_slider">
                  <div className="start">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star graycolor" aria-hidden="true"></i>
                  </div>
                  <p className="review_content"> Quis ipsum suspendisse ultrices gravida. Risus commodo viveRisus commodo vivrra maecenas accumsan facilisis.</p>
                  <p className="review_name">Teresa Holland</p>
                </div>
              </SwiperSlide> */}
              {/* <SwiperSlide>
                <div className="review_slider">
                  <div className="start">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star graycolor" aria-hidden="true"></i>
                  </div>
                  <p className="review_content"> Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                  <p className="review_name">Teresa Holland</p>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="subscribe_section containerpadding fontjost pt-50 pb-50">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <h3>
                Get our emails for info on new <br /> items, sales and more.
              </h3>
              {/* <p className="voucher_section">
                We'll email you a voucher worth £10 off your first order over
                £50.
              </p> */}
              <p className="voucher_section">
                We'll email you a voucher worth {currencyDetails.symbol + " "}
                {parseInt(currencyDetails.convertRate) * parseInt(10)} off your
                first order over
                {" " + currencyDetails.symbol + " "}
                {parseInt(currencyDetails.convertRate) * parseInt(50)} .
              </p>
              <form className="search-bar">
                <div className="position-relative newsletterinput d-flex">
                  <div>
                    <input
                      value={subscriber}
                      onChange={(e) => setSubscriber(e.target.value)}
                      type="text"
                      placeholder="Enter your email address"
                      aria-label="Search"
                      className="form-control"
                    />
                  </div>
                  <button
                    onClick={(e) => handleSendSubscriber(e)}
                    type="button"
                    className="btn btn-sm btn-custom btn-brown rounded-0 px-4"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              <p className="cookies">
                By subscribing you agree to our Terms & Conditions and Privacy &
                Cookies Policy.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="call_footer_section">
                <h3>Need help?</h3>
                <p className="mb-0 nubmerfoter">
                  <a href="#">(+800) 1234 5678 90</a>
                </p>
                <p className="timeall mb-0">We are available 8:00am – 7:00pm</p>
                <ul className="d-flex">
                  <li>
                    <a href="#">
                      <img src={img?.googleicon} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={img?.appicon} alt="" />
                    </a>
                  </li>
                </ul>
                <p className="mt-2 iocncontent">
                  <strong> Shopping App: </strong> Try our View in Your Room
                  feature, manage registries and <br /> save payment info.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index1;
