import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addProductToCarts, getCart, removeProductFromCart
} from "../services/userCart.service";
import { toastError, toastSuccess } from "../utils/toastUtils";

///////////async functions starts here

export const fetchcart = createAsyncThunk("cart/getCart", async () => {
  try {
    const { data: response } = await getCart();

    return response.data;
  }
  catch (err) {
    // toastError(err)
  }
});


export const addProductToCart = createAsyncThunk(
  "cart/addProductToCart",
  async (obj, { dispatch }) => {
    try {
      const { data: response } = await addProductToCarts(obj.id, obj);
      if (response.message) {
        toastSuccess(response.message);
        dispatch(fetchcart());
      }
      return response.data;
    }
    catch (err) {
      toastError(err)
    }
  }
);


export const removeItems = createAsyncThunk("cart/removeItem", async (id, { dispatch }) => {
  try {
    const { data: response } = await removeProductFromCart(id);
    if (response.message) {
      toastSuccess(response.message);
      dispatch(fetchcart());
    }
    return response.message;
  }
  catch (err) {
    toastError(err)
  }
});
///////////async functions ends here

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      let tempArr = state.items ? state.items : [];
      if (
        tempArr &&
        tempArr.length > 0 &&
        tempArr.some((el) => el.productId == action.payload.productId)
      ) {
        tempArr.map((el) => {
          if (el.productId == action.payload.productId) {
            el.quantity = el.quantity + action.payload.quantity;
          }
          return el
        });
      } else {
        tempArr.push({
          ...action.payload,
          productId: action.payload.productId,
          quantity: action.payload.quantity,
          productObj: action.payload.productObj,
        });
      }
      state.items = tempArr;

      toastSuccess("Added to cart")
    },

    addLocalCartToServerCart: (state, action) => {
      if (action?.payload?.cartObj?.items?.length > 0) {
        let tempArr = state.items ? state.items : [];
        // console.log(tempArr, action.payload.cartObj.items, "action.payload.cartObj")
        if (tempArr && tempArr.length > 0) {
          for (const el of action.payload.cartObj.items) {
            if (tempArr.some((ele) => ele.productId == el.productId)) {
              let index = tempArr.findIndex(
                (ele) => ele.productId == el.productId
              );
              tempArr[index].quantity = tempArr[index].quantity + el.quantity;
            } else {
              tempArr.push({ el });
            }
          }
        } else {
          tempArr = action.payload.cartObj.items;
        }
        // addCartToLocalStorage(tempArr)
        state.items = tempArr;
      }
    },
    replaceCart: (state, action) => {
      state.items = action.payload;
    },
    emptyCart: (state, action) => {
      state.items = [];
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(
        (el, i) => action.payload != el.productId
      );
    },
    updateItem: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchcart.fulfilled, (state, action) => {
      state.items = action.payload;
    });

  },
});

// Action creators are generated for each case reducer function
export const {
  addItem,
  removeItem,
  updateItem,
  replaceCart,
  emptyCart,
  addLocalCartToServerCart,
} = cartSlice.actions;

export default cartSlice.reducer;
