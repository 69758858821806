// ContactInfo
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/contactUs";



export const  addContact = (formData) => {
  // console.log(formData,"formData`12")
  return axiosApiInstance.post(serverUrl + "/", formData);
};


export const getContact = (query) => {
  return axiosApiInstance.get(`${serverUrl}/`);
};


export const deleteContact = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};


export const updateContact = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
