import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { img } from "../Components/Image";
import { loginRedux } from "../redux/Auth.slice";
import { replaceCart } from "../redux/Cart.slice";
import { loginUser } from "../services/users.service";
import { toastError, toastSuccess } from "../utils/toastUtils";
// import { useHistory } from "react-router-dom"
const Login = () => {

  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuthorised);
  const cartArr = useSelector((state) => state.cart.items);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  // const [cart, setCart] = useState([]);

  const [isAuthorised, setIsAuthorised] = useState(false);

  useEffect(() => {
    // console.log(isAuthorised, "setIsAuthorised");
  }, [isAuthorised]);

  const handleSubmitLoginCredentials = async (e) => {
    try {
      e.preventDefault();
      if (email == "") {
        toastError("Email is mandatory");
        return;
      } else if (password == "") {
        toastError("Password is mandatory");
        return;
      }

      let obj = {
        email,
        password,
        rememberMe,
        cartArr,
      };
// console.log(obj,"===bj---")
      let { data: res } = await loginUser(obj);
      // console.log(res,"===res?.cartObj")

      setIsAuthorised(true);
      if (res.success) {
        toastSuccess(res.message);
        if (res?.cartObj) {
          dispatch(replaceCart([...res?.cartObj?.items]));
        }
        dispatch(loginRedux({ token: res.token, user: res?.userObj }));
        navigate("/");
      }
    } catch (err) {
      // console.log(err,"===err")
      toastError(err);
    }
  };

  return (
    <main
      style={{ backgroundImage: `url(${img.astrology_bg})` }}
      className="main-bg border-top"
    >
      <section className="login-page">


        <div className="loing_fullimages bgyellowlight">
          <div className="left_lgoing">
            <img src={img?.loginimg} alt="" className="img-fluid" />
            <div className="overlay2"></div>
          </div>
          <div className="right_lgoing">
          <div className="shad">
                <h3>Login</h3>
                <form>
                  <label className="form-label" for="form1Example1">
                    Username or Email Address*
                  </label>
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      id="form1Example1"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form1Example2">
                      Password*
                    </label>
                    <input
                      type="password"
                      id="form1Example2"
                      className="form-control"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-outline mb-4  d-flex justify-content-between">
                    <div>
                      <input
                        type="checkbox"
                        onClick={() => setRememberMe(!rememberMe)}
                      />{" "}
                      Remember me
                    </div>
                    {/* <div className="text-end">
                      <Link to="/MyProfile" className="text-end">
                        Forget Password?
                      </Link>
                    </div> */}
                  </div>

                  <center>
                    <div className="form-outline mb-4 r-style">
                      <button onClick={(e) => handleSubmitLoginCredentials(e)}>
                        Login
                      </button>

                      <p>
                        Don’t have an account?
                        <Link to="/Register"> Register</Link>
                      </p>
                    </div>
                  </center>
                </form>
              </div>
          </div>
        </div>







        {/* <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="shad">
                <h3>Login</h3>
                <form>
                  <label className="form-label" for="form1Example1">
                    Username or email sddress*
                  </label>
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      id="form1Example1"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" for="form1Example2">
                      Password*
                    </label>
                    <input
                      type="password"
                      id="form1Example2"
                      className="form-control"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-outline mb-4  d-flex justify-content-between">
                    <div>
                      <input
                        type="checkbox"
                        onClick={() => setRememberMe(!rememberMe)}
                      />{" "}
                      Remember me
                    </div>
                    <div className="text-end">
                      <Link to="/MyProfile" className="text-end">
                        Forget Password?
                      </Link>
                    </div>
                  </div>

                  <center>
                    <div className="form-outline mb-4 r-style">
                      <button onClick={(e) => handleSubmitLoginCredentials(e)}>
                        Login
                      </button>

                      <p>
                        Don’t have an account?
                        <Link to="/Register"> Register</Link>
                      </p>
                    </div>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </main>
  );
};
export default Login;































