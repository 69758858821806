import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFaq } from "../services/Faq.service";

// import {  getTestimonials,} from "../services/testimonial.service";

///////////async functions starts here
export const fetchFaq = createAsyncThunk(
  "faq/fetchFaq",
  async (query) => {

    const { data: response } = await getFaq(query);
    return response.data;
  }
);



///////////async functions ends here

const initialState = {
  items: [],
  itemsObj: {},
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFaq.fulfilled, (state, action) => {
      state.items = action.payload;
    });

  },
});

export default faqSlice.reducer;
