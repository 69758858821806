import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getTeams, } from "../services/team.service";

import { getTestimonials, } from "../services/testimonial.service";

///////////async functions starts here
export const fetchteams = createAsyncThunk(
  "teams/fetchTeams",
  async (query) => {
    const { data: response } = await getTeams(query);
    return response.data;
  }
);
export const getTestimonialsData = createAsyncThunk(
  "testimonials/getTestimonials",
  async (query) => {
    const { data: response } = await getTestimonials(query);
    return response.data;
  }
);


///////////async functions ends here

const initialState = {
  items: [],
  itemsTestimonials: [],
  itemsObj: {},
};

export const aboutusSlice = createSlice({
  name: "AboutUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchteams.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(getTestimonialsData.fulfilled, (state, action) => {
      // console.log(action.payload,"action getbyeproduct slug inreduc")
      state.itemsTestimonials = action.payload;
    });


  },
});

export default aboutusSlice.reducer;
